import { useDispatch, useSelector } from 'react-redux';
import {
  selectTourAlreadyBookmarked,
  selectTourAt,
  tourAbort,
  TourState
} from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import { matchPath } from 'react-router-dom';
import Routes from '../../../definitions/routes';
import { Blinds } from '../../atoms/Blinds';
import { Anchor, Flyout } from '../../layout/Flyout';
import { TourCallout } from './TourCallout';
import { Scroller } from '../../atoms/Scroller';

const BookmarksMessages = Object.freeze({
  alreadyBookmarked:
    'Diesen Vorteil kannst Du auf der Merkliste finden. Klicke hier, um sie zu öffnen.',
  normal: 'Super gemacht! Klicke jetzt auf die Merkliste, um sie zu öffnen.'
});

export function TourBookmarks() {
  const at = useSelector(selectTourAt);
  const alreadyBookmarked = useSelector(selectTourAlreadyBookmarked);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutBookmarks;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => dispatch(tourAbort()), [dispatch]);
  const packed = useBreakpoint('md-down');
  const message = alreadyBookmarked
    ? BookmarksMessages.alreadyBookmarked
    : BookmarksMessages.normal;
  useHistoryListener(
    location => {
      if (active && !matchPath(location.pathname, Routes.Index())) {
        dispatch(tourAbort('navigated'));
      }
    },
    [active, dispatch]
  );
  if (skip) return null;
  return (
    <Fragment>
      <Blinds active={active} radius={0} inset={12} />
      <Flyout
        active={active}
        anchorFrom={packed ? Anchor.BottomLeft : Anchor.BottomCenter}
        anchorAt={packed ? Anchor.TopLeft : Anchor.TopCenter}
        align={packed ? 'start' : 'center'}
        offset={25}
      >
        <TourCallout
          location='below'
          stepAt={9}
          stepTotal={12}
          distance={30}
          onClose={handleAbort}
          head={packed ? 'start' : 'center'}
        >
          <Scroller active={active} scroll='top' />
          {message}
        </TourCallout>
      </Flyout>
    </Fragment>
  );
}
