import React from 'react';
import PropTypes from 'prop-types';
import './OnboardingQuestion.scss';

const OnboardingQuestion = ({ question, children, fullWidth }) => {
  return (
    <div className='OnboardingQuestion'>
      <h3 className='OnboardingQuestion__Question'>{question}</h3>
      <div
        className={`OnboardingQuestion__Content
        ${fullWidth ? 'OnboardingQuestion__Content--FullWidth' : ''}
        `}
      >
        {children}
      </div>
    </div>
  );
};

OnboardingQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  children: PropTypes.any
};

export default OnboardingQuestion;
