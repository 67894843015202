import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourStart, TourState } from '../../../redux/reducers/tour';
import { useTourTargetOffer } from '../../../hooks/useTourTargets';
import { Redirect, useRouteMatch } from 'react-router-dom';
import Routes from '../../../definitions/routes';
import { OnboardingStatus, selectStatus } from '../../../redux/reducers/onboarding';

/**
 * Controls global aspects of the tour: auto starting and redirects.
 * @return {JSX.Element|null}
 * @constructor
 */
export function TourController() {
  // Navigation checks.
  const matchIndex = Boolean(useRouteMatch({ path: Routes.Index(), exact: true }));
  const matchSingleOffer = Boolean(useRouteMatch({ path: Routes.SingleOffer(), exact: true }));
  const matchBookmarks = Boolean(useRouteMatch({ path: Routes.Bookmarks(), exact: true }));

  // Get target navigation for check.
  const tourOffer = useTourTargetOffer();

  const onboarded = useSelector(selectStatus) === OnboardingStatus.Completed;
  const at = useSelector(selectTourAt);
  const dispatch = useDispatch();
  const handleStart = useCallback(() => dispatch(tourStart()), [dispatch]);

  // Start if not viewed.
  useEffect(() => {
    if (onboarded && at === TourState.notViewed) {
      const handle = setTimeout(handleStart, 1000);
      return () => clearTimeout(handle);
    }
  }, [at, onboarded, handleStart]);

  // Navigation conditions.
  if (at === TourState.intro && !matchIndex) {
    return <Redirect to={Routes.Index()} />;
  }
  if (at === TourState.aboutCurrentOffers && !matchIndex) {
    return <Redirect to={Routes.Index()} />;
  }
  if (at === TourState.aboutOneOffer && !matchIndex) {
    return <Redirect to={Routes.Index()} />;
  }
  if (at === TourState.aboutOfferDetails && !matchSingleOffer && tourOffer?.id) {
    return <Redirect to={Routes.SingleOffer(tourOffer.id)} />;
  }
  if (at === TourState.aboutValidity && !matchSingleOffer && tourOffer?.id) {
    return <Redirect to={Routes.SingleOffer(tourOffer.id)} />;
  }
  if (at === TourState.aboutRedeemGeneral && !matchSingleOffer && tourOffer?.id) {
    return <Redirect to={Routes.SingleOffer(tourOffer.id)} />;
  }
  if (at === TourState.aboutRedeemThis && !matchSingleOffer && tourOffer?.id) {
    return <Redirect to={Routes.SingleOffer(tourOffer.id)} />;
  }
  if (at === TourState.aboutQrCode && !matchSingleOffer && tourOffer?.id) {
    return <Redirect to={Routes.SingleOffer(tourOffer.id)} />;
  }
  if (at === TourState.aboutPdf && !matchSingleOffer && tourOffer?.id) {
    return <Redirect to={Routes.SingleOffer(tourOffer.id)} />;
  }
  if (at === TourState.aboutBookmarking && !matchSingleOffer && tourOffer?.id) {
    return <Redirect to={Routes.SingleOffer(tourOffer.id)} />;
  }
  if (at === TourState.aboutBookmarks && !matchSingleOffer && tourOffer?.id) {
    return <Redirect to={Routes.SingleOffer(tourOffer.id)} />;
  }
  if (at === TourState.aboutCurrentBookmarks && !matchBookmarks) {
    return <Redirect to={Routes.Bookmarks()} />;
  }
  if (at === TourState.aboutEvmOffers && !matchIndex) {
    return <Redirect to={Routes.Index()} />;
  }
  if (at === TourState.aboutCompletion && !matchIndex) {
    return <Redirect to={Routes.Index()} />;
  }

  // No navigation needed.
  return null;
}
