import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers/rootReducer';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/es/storage';

// Create middleware by applying the appropriate steps based on environment.
const development = process.env.NODE_ENV !== 'production';
const middleware = development
  ? applyMiddleware(
      thunk,
      createLogger({
        collapsed: true,
        diff: true
      })
    )
  : applyMiddleware(thunk);

// Create reducer wrapper for persistence. Whitelist only needed state parts.
const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['tour']
  },
  rootReducer
);

/**
 * The store with wrapped reducers and applied middleware.
 */
export const store = createStore(persistedReducer, middleware);

/**
 * State persistor.
 * @type {Persistor}
 */
export const persistor = persistStore(store);
