export const TourState = Object.freeze({
  notViewed: 'NotViewed',
  aborted: 'Aborted',
  intro: 'Intro',
  aboutRestarting: 'AboutRestarting',
  aboutCurrentOffers: 'AboutCurrentOffers',
  aboutOneOffer: 'AboutOneOffer',
  aboutOfferDetails: 'AboutOfferDetails',
  aboutValidity: 'AboutValidity',
  aboutRedeemGeneral: 'AboutRedeemGeneral',
  aboutRedeemThis: 'AboutRedeemThis',
  aboutQrCode: 'AboutQrCode',
  aboutPdf: 'AboutPdf',
  aboutBookmarking: 'AboutBookmarking',
  aboutBookmarks: 'AboutBookmarks',
  aboutCurrentBookmarks: 'AboutCurrentBookmarks',
  aboutEvmOffers: 'AboutEvmOffers',
  aboutCompletion: 'AboutCompletion',
  viewed: 'Viewed'
});

const initialState = {
  at: TourState.notViewed
};

export const selectTourAt = state => state.tour.at;
export const selectTourAbortReason = state => state.tour.abortReason;
export const selectTourAlreadyBookmarked = state => state.tour.alreadyBookmarked;

export const TourActions = Object.freeze({
  start: 'tour/START',
  abort: 'tour/ABORT',
  next: 'tour/NEXT'
});

export const tourStart = () => dispatch =>
  dispatch({
    type: TourActions.start
  });

export const tourAbort =
  (reason = 'aborted') =>
  dispatch =>
    dispatch({
      type: TourActions.abort,
      reason
    });

export const tourNext = detail => dispatch =>
  dispatch({
    type: TourActions.next,
    detail
  });

export default function tour(state = initialState, action) {
  switch (action.type) {
    // Decision based on state machine of current position in the tour.
    case TourActions.start: {
      switch (state.at) {
        // Not viewed yet, start at intro and allow abort.
        case TourState.notViewed:
          return { at: TourState.intro };
        // Aborted, explicit start from the menu, go directly to tour.
        case TourState.aborted:
        case TourState.aboutRestarting:
        case TourState.aboutCompletion:
        case TourState.viewed:
          return { at: TourState.aboutCurrentOffers };
        // Unhandled, remain in same state.
        default:
          return state;
      }
    }

    case TourActions.abort: {
      // Decision based on state machine of current position in the tour.
      switch (state.at) {
        // Cancel only when in active state.
        case TourState.notViewed:
          return state;
        case TourState.aborted:
          return state;
        case TourState.viewed:
          return state;

        // Any active state, go to restarting state.
        default:
          return { ...state, abortReason: action.reason, at: TourState.aboutRestarting };
      }
    }

    case TourActions.next: {
      // Decision based on state machine of current position in the tour.
      switch (state.at) {
        // Not viewed or actively cancelled remain.
        case TourState.notViewed:
          return state;
        case TourState.aborted:
          return state;
        // About restarting goes to exit path.
        case TourState.aboutRestarting:
          return { ...state, ...action.detail, at: TourState.aborted };

        // Tour states in main path go to the next state, and finish in the viewed state.
        case TourState.intro:
          return { ...state, ...action.detail, at: TourState.aboutCurrentOffers };
        case TourState.aboutCurrentOffers:
          return { ...state, ...action.detail, at: TourState.aboutOneOffer };
        case TourState.aboutOneOffer:
          return { ...state, ...action.detail, at: TourState.aboutOfferDetails };
        case TourState.aboutOfferDetails:
          return { ...state, ...action.detail, at: TourState.aboutValidity };
        case TourState.aboutValidity:
          return { ...state, ...action.detail, at: TourState.aboutRedeemGeneral };
        case TourState.aboutRedeemGeneral:
          return { ...state, ...action.detail, at: TourState.aboutRedeemThis };
        case TourState.aboutRedeemThis:
          return { ...state, ...action.detail, at: TourState.aboutQrCode };
        case TourState.aboutQrCode:
          return { ...state, ...action.detail, at: TourState.aboutPdf };
        case TourState.aboutPdf:
          return { ...state, ...action.detail, at: TourState.aboutBookmarking };
        case TourState.aboutBookmarking:
          return { ...state, ...action.detail, at: TourState.aboutBookmarks };
        case TourState.aboutBookmarks:
          return { ...state, ...action.detail, at: TourState.aboutCurrentBookmarks };
        case TourState.aboutCurrentBookmarks:
          return { ...state, ...action.detail, at: TourState.aboutEvmOffers };
        case TourState.aboutEvmOffers:
          return { ...state, ...action.detail, at: TourState.aboutCompletion };
        case TourState.aboutCompletion:
          return { ...state, ...action.detail, at: TourState.viewed };

        // Viewed remains.
        case TourState.viewed:
          return state;

        // Unhandled, remain in same state.
        default:
          return state;
      }
    }
    default: {
      return state;
    }
  }
}
