import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourAbort, tourNext, TourState } from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import { matchPath } from 'react-router-dom';
import Routes from '../../../definitions/routes';
import { Blinds } from '../../atoms/Blinds';
import { Anchor, Flyout } from '../../layout/Flyout';
import { TourCallout } from './TourCallout';
import { Scroller } from '../../atoms/Scroller';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import PropTypes from 'prop-types';

export function TourCurrentBookmarks({ empty }) {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutCurrentBookmarks;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => dispatch(tourAbort()), [dispatch]);
  const handleNext = useCallback(() => dispatch(tourNext()), [dispatch]);
  const packed = useBreakpoint('md-down');
  const baseInset =
    (empty && packed && -20) || (empty && !packed && -40) || (!empty && packed && -30) || -65;
  useHistoryListener(
    location => {
      if (active && !matchPath(location.pathname, Routes.Index())) {
        dispatch(tourAbort('navigated'));
      }
    },
    [active, dispatch]
  );
  if (skip) return null;
  return (
    <Fragment>
      <Blinds
        active={active}
        radius={0}
        insetLeft={12}
        insetRight={12}
        insetTop={baseInset}
        insetBottom={baseInset}
      />
      <Flyout
        active={active}
        anchorFrom={Anchor.TopCenter}
        anchorAt={Anchor.BottomCenter}
        align='center'
        offset={25}
      >
        <TourCallout
          location='above'
          button='Weiter'
          stepAt={10}
          stepTotal={12}
          distance={baseInset + 15}
          onNext={handleNext}
          onClose={handleAbort}
          head='center'
        >
          <Scroller active={active} />
          Hier findest Du Deine für später gemerkten Vorteile. Nicht vergessen: Manche Vorteile sind
          nur eine gewisse Zeit lang gültig – also warte nicht zu lange!
        </TourCallout>
      </Flyout>
    </Fragment>
  );
}

TourCurrentBookmarks.propTypes = {
  empty: PropTypes.bool
};
