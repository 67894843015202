import React from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import './CampaignHero.scss';
import Hero from '../atoms/Hero';
import IconButton from '../atoms/IconButton';
import { Left } from '../../images/icons';
import Colors from '../../definitions/colors';
import { useHistory } from 'react-router-dom';
import useResources from '../../hooks/useResources';

const CampaignHero = ({ campaign, onScrollToOffers, slim }) => {
  const history = useHistory();
  const resource = useResources();
  return (
    <Hero
      isDummy={!campaign}
      imageUrl={campaign?.header?.location}
      imageAlt={campaign?.title}
      slim={slim}
    >
      {campaign && (
        <div className='CampaignHero'>
          <div className='CampaignHero__Back'>
            <IconButton
              title={resource('campaign.back')}
              onClick={history.goBack}
              color={Colors.White}
              showLabel
              labelPosition='RIGHT'
              iconCircle={{
                icon: (
                  <Left fill={campaign.header?.location ? Colors.White : Colors.Orange} size={11} />
                ),
                offsetX: -1,
                color: campaign.header?.location ? Colors.Orange : Colors.White
              }}
            />
          </div>
          <h1
            className={`CampaignHero__Title ${
              (campaign.lightOnDark || !campaign?.header?.location) &&
              'CampaignHero__Title--Inverted'
            }`}
          >
            {campaign.title}
          </h1>
          <h4
            className={`CampaignHero__Excerpt ${
              (campaign.lightOnDark || !campaign?.header?.location) &&
              'CampaignHero__Excerpt--Inverted'
            }`}
          >
            {campaign.excerpt}
          </h4>
          {onScrollToOffers && (
            <div className='CampaignHero__Button'>
              <Button
                title={resource('campaign.discoverOffers')}
                onClick={onScrollToOffers}
                variant={campaign.header?.location ? 'ORANGE_FILL' : 'WHITE_OUTLINE'}
              />
            </div>
          )}
        </div>
      )}
    </Hero>
  );
};

CampaignHero.propTypes = {
  campaign: PropTypes.any,
  onScrollToOffers: PropTypes.func,
  slim: PropTypes.bool
};

export default CampaignHero;
