import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourAbort, tourNext, TourState } from '../../../redux/reducers/tour';
import React, { useCallback } from 'react';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import Overlay from '../../atoms/Overlay';
import Button from '../../atoms/Button';
import './TourIntro.scss';

export function TourIntro() {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.intro;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => dispatch(tourAbort()), [dispatch]);
  const handleNext = useCallback(() => dispatch(tourNext()), [dispatch]);
  useHistoryListener(() => {
    if (active) dispatch(tourAbort('navigated'));
  }, [active, dispatch]);
  if (skip) return null;

  return (
    <Overlay visible={active} showCloseButton={false} rounded={true} onClose={handleAbort}>
      <div className='TourIntro__Container'>
        <span className='TourIntro__Headline'>Willkommen beim Entdecker-Bonus!</span>
        <p className='TourIntro__Content'>
          Hier findest Du Angebote und exklusive Events aus der Region.
          <br />
          <br />
          Dürfen wir Dir in einer kurzen Anleitung zeigen, wie Du die Vorteile nutzen kannst?
        </p>
        <div className='TourIntro__Buttons'>
          <Button variant='ORANGE_OUTLINE' title='Nein, Danke' onClick={handleAbort} />
          <Button variant='ORANGE_FILL' title='Anleitung starten' onClick={handleNext} />
        </div>
      </div>
    </Overlay>
  );
}
