export const AuthStatus = {
  Pending: 1,
  Authenticated: 2,
  Error: 3
};

const initialState = {
  token: null,
  status: AuthStatus.Pending
};

export const TOKEN_SET = 'auth/token/SET';
export const setToken = token => ({
  type: TOKEN_SET,
  payload: {
    token
  }
});

export const STATUS_SET = 'auth/status/SET';
export const setAuthenticationSuccess = () => ({
  type: STATUS_SET,
  payload: {
    status: AuthStatus.Authenticated
  }
});
export const setAuthenticationError = () => ({
  type: STATUS_SET,
  payload: {
    status: AuthStatus.Error
  }
});

export const selectToken = state => state.auth.token;
export const selectStatus = state => state.auth.status;

export default function auth(state = initialState, action) {
  switch (action.type) {
    case TOKEN_SET: {
      const { token } = action.payload;
      return {
        ...state,
        token
      };
    }
    case STATUS_SET: {
      const { status } = action.payload;
      return {
        ...state,
        status
      };
    }
    default:
      return state;
  }
}
