import React, { Fragment } from 'react';
import OfferSlider from './OfferSlider';
import TextLink from '../atoms/TextLink';
import Routes from '../../definitions/routes';
import useResources from '../../hooks/useResources';
import PropTypes from 'prop-types';

export const IndexOffersUpcoming = ({ items }) => {
  const resource = useResources();
  if (!items?.length) return null;
  return (
    <Fragment>
      <OfferSlider
        title={resource('index.offers.upcoming')}
        showAll={<TextLink title={resource('offers.showAll')} to={Routes.UpcomingOffers()} />}
        offers={items}
      />
    </Fragment>
  );
};

IndexOffersUpcoming.propTypes = {
  items: PropTypes.array
};
