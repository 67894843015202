import React from 'react';
import PropTypes from 'prop-types';

const IconPropTypes = {
  size: PropTypes.number,
  fill: PropTypes.string
};

export const Left = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 11) * 20} viewBox='0 0 11 20'>
    <path
      fill={fill}
      d='M10,20c0.4,0,0.7-0.2,0.8-0.6c0.1-0.3,0.1-0.7-0.2-1l-8.4-8.4l8.4-8.4c0.3-0.4,0.3-0.9,0-1.3S9.7,0,9.4,0.4
	L0.3,9.4c-0.4,0.4-0.4,0.9,0,1.3l9.1,9.1C9.5,19.9,9.8,20,10,20z'
    />
  </svg>
);
Left.propTypes = IconPropTypes;

export const Right = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 11) * 20} viewBox='0 0 11 20'>
    <path
      fill={fill}
      d='M1,20c-0.4,0-0.7-0.2-0.8-0.6s-0.1-0.7,0.2-1l8.4-8.4L0.4,1.6C0,1.3,0,0.7,0.4,0.4s0.9-0.4,1.3,0l9.1,9.1
	c0.4,0.4,0.4,0.9,0,1.3l-9.1,9.1C1.5,19.9,1.2,20,1,20z'
    />
  </svg>
);
Right.propTypes = IconPropTypes;

export const Up = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 14) * 8} viewBox='0 0 14 8'>
    <path
      fill={fill}
      id='Path'
      d='M14,7.3c0,0.3-0.2,0.5-0.4,0.6c-0.2,0.1-0.5,0-0.7-0.1L7,1.8L1.1,7.7c-0.2,0.2-0.6,0.2-0.9,0C0,7.4,0,7.1,0.2,6.8
	l6.3-6.3c0.2-0.2,0.7-0.2,0.9,0l6.3,6.3C14,6.9,14,7.1,14,7.3z'
    />
  </svg>
);
Up.propTypes = IconPropTypes;

export const Down = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 14) * 8} viewBox='0 0 14 8'>
    <path
      fill={fill}
      id='Path'
      d='M14,0.9c0-0.3-0.2-0.5-0.4-0.6s-0.5,0-0.7,0.1L7,6.4L1.1,0.5c-0.2-0.2-0.6-0.2-0.9,0S0,1.1,0.2,1.4l6.3,6.3
	c0.2,0.2,0.7,0.2,0.9,0l6.3-6.3C14,1.2,14,1.1,14,0.9z'
    />
  </svg>
);
Down.propTypes = IconPropTypes;

export const Percent = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 36 36'>
    <path
      fill={fill}
      d='M2.1,35.5c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.6,0-2.3L32.8,1c0.6-0.6,1.6-0.6,2.3,0
	c0.6,0.6,0.6,1.6,0,2.3L3.2,35C2.9,35.3,2.5,35.5,2.1,35.5'
    />
    <path
      fill={fill}
      d='M7.9,3.7c-2.3,0-4.2,1.9-4.2,4.2S5.6,12,7.9,12S12,10.2,12,7.9S10.2,3.7,7.9,3.7 M7.9,15.2
	c-4.1,0-7.4-3.3-7.4-7.4s3.3-7.4,7.4-7.4s7.4,3.3,7.4,7.4S11.9,15.2,7.9,15.2'
    />
    <path
      fill={fill}
      d='M28.1,24c-2.3,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2S30.4,24,28.1,24
	 M28.1,35.5c-4.1,0-7.4-3.3-7.4-7.4c0-4.1,3.3-7.4,7.4-7.4s7.4,3.3,7.4,7.4C35.5,32.2,32.2,35.5,28.1,35.5'
    />
  </svg>
);
Percent.propTypes = IconPropTypes;

export const Calendar = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 40) * 37} viewBox='0 0 40 37'>
    <path
      fill={fill}
      d='M37.5,4.9h-4.8V3.3c0-0.9-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6v1.6H10.5V3.3c0-0.9-0.7-1.6-1.6-1.6
	C8,1.7,7.3,2.4,7.3,3.3v1.6H2.5c-0.9,0-1.6,0.7-1.6,1.6v28.6c0,0.9,0.7,1.6,1.6,1.6h22.2c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6
	H4.1V17.6h31.8v17.5c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6V6.5C39.1,5.6,38.4,4.9,37.5,4.9z M4.1,14.4V8.1h3.2v1.6
	c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6V8.1h19.1v1.6c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6V8.1h3.2v6.3L4.1,14.4z'
    />
    <path
      fill={fill}
      d='M13.7,20.8h-3.2c-0.9,0-1.6,0.7-1.6,1.6S9.6,24,10.5,24h3.2c0.9,0,1.6-0.7,1.6-1.6S14.6,20.8,13.7,20.8z'
    />
    <path
      fill={fill}
      d='M13.7,27.2h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S14.6,27.2,13.7,27.2z'
    />
    <path
      fill={fill}
      d='M29.5,20.8h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S30.4,20.8,29.5,20.8z'
    />
    <path
      fill={fill}
      d='M29.5,27.2h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S30.4,27.2,29.5,27.2z'
    />
    <path
      fill={fill}
      d='M21.6,27.2h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S22.5,27.2,21.6,27.2
	L21.6,27.2z'
    />
    <path
      fill={fill}
      d='M21.6,20.8h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S22.5,20.8,21.6,20.8
	L21.6,20.8z'
    />
  </svg>
);
Calendar.propTypes = IconPropTypes;

export const Close = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 24 24'>
    <path
      fill={fill}
      d='M13.8,12l9.4-9.4c0.3-0.3,0.5-0.8,0.4-1.3c-0.1-0.4-0.5-0.8-0.9-0.9s-0.9,0-1.3,0.4L12,10.2L2.6,0.8
	C2.3,0.5,1.8,0.3,1.4,0.5S0.6,0.9,0.5,1.4s0,0.9,0.4,1.3l9.4,9.4l-9.4,9.4c-0.4,0.4-0.5,0.9-0.3,1.4c0.2,0.5,0.7,0.8,1.2,0.8
	c0.3,0,0.7-0.1,0.9-0.4l9.4-9.4l9.4,9.4c0.3,0.3,0.8,0.5,1.3,0.4c0.4-0.1,0.8-0.5,0.9-0.9c0.1-0.4,0-0.9-0.4-1.3L13.8,12z'
    />
  </svg>
);
Close.propTypes = IconPropTypes;

export const User = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} viewBox='0 0 18 24'>
    <path
      fill={fill}
      d='M9,12.5c-2.5,0-4.8-1.5-5.7-3.8s-0.4-5,1.3-6.8s4.4-2.3,6.8-1.3s3.8,3.2,3.8,5.7l0,0c0,1.6-0.7,3.2-1.8,4.4
	C12.2,11.9,10.6,12.5,9,12.5L9,12.5z M9,1.9C7.2,1.9,5.6,3,4.9,4.6S4.6,8.2,5.9,9.4c1.3,1.3,3.1,1.6,4.8,1c1.6-0.7,2.7-2.3,2.7-4
	l0,0C13.4,3.9,11.4,1.9,9,1.9z'
    />
    <path
      fill={fill}
      d='M9.6,23.7H1.1c-0.5,0-0.9-0.3-0.9-0.8l0-0.2v-5.6c0-1.6,1.3-3,3-3h11.7c1.6,0,3,1.3,3,3v5.5
	c0,0.5-0.4,0.9-0.9,0.9S16,23.1,16,22.6v-5.5c0-0.6-0.5-1.2-1.2-1.2H3.2c-0.6,0-1.2,0.5-1.2,1.2v4.8h7.6c0.5,0,0.9,0.4,0.9,0.9
	C10.5,23.3,10.1,23.7,9.6,23.7L9.6,23.7z'
    />
  </svg>
);
User.propTypes = IconPropTypes;

export const Plus = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 16 16'>
    <path
      fill={fill}
      d='M15.2,7.4H8.6V0.8c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6v6.6H0.8C0.4,7.4,0.2,7.6,0.2,8
	c0,0.4,0.3,0.6,0.6,0.6h6.6v6.6c0,0.4,0.3,0.6,0.6,0.6c0.4,0,0.6-0.3,0.6-0.6V8.6h6.6c0.4,0,0.6-0.3,0.6-0.6
	C15.8,7.6,15.6,7.4,15.2,7.4z'
    />
  </svg>
);
Plus.propTypes = IconPropTypes;

export const Minus = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 16) * 2} viewBox='0 0 16 2'>
    <path
      fill={fill}
      d='M8.6,1.6h6.6c0.4,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H8.6H7.4H0.8C0.4,0.4,0.2,0.6,0.2,1
	s0.3,0.6,0.6,0.6h6.6H8.6z'
    />
  </svg>
);
Minus.propTypes = IconPropTypes;

export const Tick = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 16) * 12} viewBox='0 0 16 12'>
    <path
      fill={fill}
      d='M5.3,11.3c-0.2,0-0.3-0.1-0.4-0.2L0.2,6.5C0,6.3,0,6.1,0,5.8c0.1-0.2,0.2-0.4,0.5-0.5s0.5,0,0.6,0.2l4.2,4.2
	l8.9-8.9c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0.1,0.4,0.2,0.5,0.5s0,0.5-0.2,0.6l-9.3,9.3C5.6,11.2,5.5,11.3,5.3,11.3z'
    />
  </svg>
);
Tick.propTypes = IconPropTypes;

export const Help = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 10.3) * 16.6} viewBox='0 0 10.3 16.6'>
    <path
      fill={fill}
      d='M5.1,16.6c-0.4,0-0.6-0.3-0.6-0.6v-0.8c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6v0.8
	C5.8,16.3,5.5,16.6,5.1,16.6z'
    />
    <path
      fill={fill}
      d='M5.1,12.8c-0.4,0-0.6-0.3-0.6-0.6V9.7C4.5,9.3,4.8,9,5.1,9c1.6,0,3-0.9,3.6-2.4C9.3,5.2,9,3.5,7.9,2.4
	S5.1,1,3.7,1.6s-2.4,2-2.4,3.6c0,0.4-0.3,0.6-0.6,0.6S0,5.5,0,5.2c0-2.8,2.2-5,4.9-5.2s5.1,2,5.3,4.7c0.2,2.8-1.8,5.2-4.5,5.5v1.9
	c0,0.2-0.1,0.3-0.2,0.5C5.5,12.7,5.3,12.8,5.1,12.8z'
    />
  </svg>
);
Help.propTypes = IconPropTypes;

export const NoEdit = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 14 14'>
    <path
      fill={fill}
      d='M2.2,9.3C2.2,9.2,2.1,9.2,2,9.1C1.7,9,1.4,9.2,1.3,9.5L0,13.2c0,0.1,0,0.1,0,0.2C0,13.7,0.3,14,0.6,14
	c0.1,0,0.1,0,0.2,0l3.7-1.2c0.1,0,0.2-0.1,0.2-0.1C5,12.4,5,12,4.7,11.8L2.2,9.3z M1.5,12.5l0.6-1.7l1.1,1.1L1.5,12.5z'
    />
    <path
      fill={fill}
      d='M6.1,9.5L6,9.7L4.3,8l0.2-0.2c0.2-0.2,0.2-0.6,0-0.8C4.3,6.8,3.9,6.8,3.7,7L3.1,7.6c-0.2,0.2-0.2,0.6,0,0.8
	l2.5,2.5C5.7,11,5.8,11.1,6,11.1s0.3-0.1,0.4-0.2L7,10.3c0.2-0.2,0.2-0.6,0-0.8S6.4,9.3,6.1,9.5z'
    />
    <path
      fill={fill}
      d='M9.1,8.2l1.5-1.5c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.2-0.6-0.2-0.8,0L8.2,7.4L6.6,5.8l4.4-4.3L13,3.5
	c0.2,0.2,0.6,0.2,0.8,0c0.2-0.2,0.2-0.6,0-0.8l-2.5-2.5c-0.2-0.2-0.6-0.2-0.8,0L5.8,4.9L1,0.2C0.8,0,0.4,0,0.2,0.2
	C0,0.4,0,0.8,0.2,1L13,13.8c0.1,0.1,0.3,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.6,0-0.8L9.1,8.2z'
    />
  </svg>
);
NoEdit.propTypes = IconPropTypes;

export const CheckboxTick = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 17) * 13.3} viewBox='0 0 17 13.3'>
    <path
      fill={fill}
      d='M5.8,13.3L0.4,7.9c-0.6-0.6-0.6-1.5,0-2.1C1,5.2,2,5.2,2.6,5.8l3.3,3.3l8.6-8.6c0.6-0.6,1.5-0.6,2.1,0
	c0.6,0.6,0.6,1.5,0,2.1L5.8,13.3z'
    />
  </svg>
);
CheckboxTick.propTypes = IconPropTypes;

export const Heart = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 200 200'>
    <g transform='matrix(14.3662,0,0,14.3662,-58.0236,-66.2559)'>
      <path
        fill={fill}
        d='M11,17.752C10.868,17.752 10.741,17.699 10.647,17.605L5.166,12.123C3.663,10.612 3.663,8.134 5.166,6.623L5.266,6.523C6.776,5.017 9.256,5.017 10.766,6.523L11,6.75L11.226,6.523C12.738,5.017 15.219,5.017 16.731,6.523L16.831,6.623C18.337,8.133 18.337,10.613 16.831,12.123L15.714,13.241C15.621,13.331 15.496,13.381 15.367,13.381C15.092,13.381 14.867,13.156 14.867,12.881C14.867,12.752 14.917,12.627 15.007,12.534L16.124,11.416C17.241,10.292 17.241,8.451 16.124,7.327L16.024,7.227C14.9,6.11 13.059,6.11 11.935,7.227L11.355,7.808C11.158,7.995 10.845,7.995 10.648,7.808L10.062,7.222C8.938,6.105 7.097,6.105 5.973,7.222L5.873,7.322C4.755,8.446 4.755,10.289 5.873,11.413L11,16.544L12.154,15.39C12.247,15.3 12.372,15.25 12.501,15.25C12.776,15.25 13.001,15.475 13.001,15.75C13.001,15.879 12.951,16.004 12.861,16.097L11.354,17.605C11.26,17.699 11.133,17.752 11,17.752Z'
      />
    </g>
  </svg>
);
Heart.propTypes = IconPropTypes;

export const HandWithHeart = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 41) * 44.5} viewBox='0 0 41 44.5'>
    <path
      fill={fill}
      d='M20.5,23.6c-0.4,0-0.8-0.2-1.1-0.5l-9.9-9.9c-3-3-3-7.9,0-10.9C10.9,0.9,12.9,0,14.9,0s4.1,0.7,5.5,2.2
	c3.1-2.9,7.9-2.8,10.9,0.2s3,7.8,0.1,10.9c-0.6,0.6-1.6,0.6-2.2,0c-0.6-0.6-0.6-1.6,0-2.2c1.7-1.8,1.7-4.6,0-6.4
	c-0.8-0.9-2-1.4-3.2-1.5c-1.2,0-2.4,0.4-3.3,1.3l-1,1c-0.6,0.6-1.6,0.6-2.3,0l-1-1.1c-1.8-1.8-4.6-1.8-6.4,0
	c-0.9,0.9-1.5,2.1-1.5,3.4c0,1.2,0.5,2.3,1.3,3.2l8.7,8.7l1.6-1.6c0.6-0.6,1.6-0.6,2.2,0c0.6,0.6,0.6,1.6,0,2.2l-2.7,2.7
	C21.3,23.4,20.9,23.6,20.5,23.6z'
    />
    <path
      fill={fill}
      d='M40.6,33.6c-2-2.2-4.3-4.1-6.8-5.6c-4.5-1.7-14.9-0.7-16-0.5c-0.2,0-0.4,0.1-0.5,0.2c-0.5,0.2-1.6,1-1.2,3.2
	c0.1,0.7,0.4,1.3,0.7,2c-3.5-0.7-6.9-1.8-10-3.4l-1.1-0.5C4,28.3,2.3,28.8,1.2,30c-1.1,0.9-1.5,2.5-1,3.8c2.5,5.4,14.4,10.7,27,10.7
	c1.6,0,3.3-0.1,4.9-0.3c0.9-0.1,1.5-0.9,1.4-1.8s-0.9-1.5-1.8-1.4C18.9,42.6,5.2,37,3.2,32.7c0.1-0.3,0.3-0.5,0.6-0.7
	c0.2-0.1,0.3-0.2,0.5-0.2l1.1,0.5c7.2,3.7,15.4,5.1,23.4,3.8c0.9-0.1,1.5-0.9,1.3-1.8c0-0.4-0.3-0.8-0.6-1.1s-0.8-0.4-1.2-0.3
	c-2.4,0.3-4.9,0.5-7.3,0.3c-0.8-0.7-1.4-1.7-1.7-2.8c4.5-0.6,9-0.4,13.4,0.4c2.1,1.4,4,3,5.7,4.9c0.6,0.6,1.6,0.7,2.2,0
	C41.2,35.3,41.2,34.3,40.6,33.6L40.6,33.6z'
    />
  </svg>
);
HandWithHeart.propTypes = IconPropTypes;

export const Info = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 6.7) * 19.8} viewBox='0 0 6.7 19.8'>
    <path
      fill={fill}
      d='M3.3,6.7C2,6.7,0.8,5.9,0.3,4.6S0,1.9,1,1s2.4-1.2,3.6-0.7S6.7,2,6.7,3.3C6.7,5.2,5.2,6.7,3.3,6.7
	L3.3,6.7z M3.3,1.3c-0.8,0-1.6,0.5-1.9,1.3S1.3,4.2,1.9,4.8s1.5,0.8,2.3,0.4s1.3-1.1,1.3-1.9C5.4,2.2,4.5,1.3,3.3,1.3z'
    />
    <path
      fill={fill}
      d='M5.9,19.8H0.7c-0.4,0-0.6-0.3-0.6-0.6v-10c0-0.4,0.3-0.6,0.6-0.6h5.2c0.4,0,0.6,0.3,0.6,0.6v5.4
	c0,0.4-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6V9.8H1.4v8.7h4.5c0.4,0,0.6,0.3,0.6,0.6S6.3,19.8,5.9,19.8L5.9,19.8z'
    />
  </svg>
);
Info.propTypes = IconPropTypes;

export const StarFilled = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 23.6) * 22.5} viewBox='0 0 23.6 22.5'>
    <path
      fill={fill}
      d='M11.8,0c0.3,0,0.6,0.2,0.8,0.5l3.2,6.4l7,1c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1
	c0.1,0,0.1,0.1,0.2,0.2l0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
	c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
	c0,0-0.1,0.1-0.1,0.1l0.1-0.1c0,0-0.1,0.1-0.1,0.1L23,9.7l-4.8,4.7l1.2,7c0.1,0.3-0.1,0.7-0.4,0.9c-0.1,0.1-0.3,0.1-0.4,0.2
	c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0l0.1,0c-0.1,0-0.3,0-0.4-0.1l-6.3-3.3l-6.3,3.3
	c-0.1,0.1-0.2,0.1-0.3,0.1l-0.1,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.3-0.1-0.4-0.2
	c-0.3-0.2-0.4-0.5-0.4-0.9l1.2-7L0.6,9.7L0.3,9.4c0,0-0.1-0.1-0.1-0.1l0.1,0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0
	c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
	c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2l0.1-0.1c0-0.1,0.1-0.1,0.2-0.2C0.4,8,0.5,8,0.6,7.9c0,0,0.1,0,0.1,0l7-1L11,0.5
	C11.2,0.2,11.5,0,11.8,0L11.8,0L11.8,0z'
    />
  </svg>
);
StarFilled.propTypes = IconPropTypes;

export const StarUnfilled = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={(size / 23.6) * 22.5} viewBox='0 0 23.6 22.5'>
    <path
      fill={fill}
      d='M18.5,22.5c-0.1,0-0.3,0-0.4-0.1l-6.3-3.3l-6.3,3.3c-0.3,0.2-0.7,0.1-1-0.1s-0.4-0.5-0.4-0.9l1.2-7l-5.1-5
	C0,9.2-0.1,8.8,0,8.5c0.1-0.3,0.4-0.6,0.7-0.6l7.1-1L11,0.5C11.2,0.2,11.5,0,11.8,0c0.3,0,0.6,0.2,0.8,0.5l3.2,6.4l7,1
	c0.3,0,0.6,0.3,0.7,0.6s0,0.7-0.2,0.9l-2.8,2.7c-0.4,0.4-0.9,0.3-1.3,0s-0.3-0.9,0-1.3l1.4-1.4L15,8.6c-0.3,0-0.6-0.2-0.7-0.5
	l-2.5-5.2L9.3,8.1C9.1,8.4,8.9,8.6,8.6,8.6L2.9,9.4l4.1,4c0.2,0.2,0.3,0.5,0.3,0.8l-1,5.7l5.1-2.7c0.3-0.1,0.6-0.1,0.8,0l5.1,2.7
	l-0.4-2.1c-0.1-0.5,0.2-1,0.7-1s1,0.2,1.1,0.7l0.7,4c0,0.3,0,0.5-0.2,0.7S18.8,22.5,18.5,22.5L18.5,22.5z'
    />
  </svg>
);
StarUnfilled.propTypes = IconPropTypes;

export const Facebook = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 200 200'>
    <path
      fill={fill}
      d='M78.3,193.5v-83.6h-28V76.6h28V50.4c0-28.5,17.4-43.9,42.8-43.9c12.2,0,22.6,0.9,25.6,1.3v29.7h-17.6
    c-13.8,0-16.5,6.6-16.5,16.2v22.9h31.2l-4.3,33.2h-26.9v83.6'
    />
  </svg>
);
Facebook.propTypes = IconPropTypes;

export const Twitter = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 200 200'>
    <path
      fill={fill}
      d='M63,183.2c75.3,0,116.6-62.5,116.6-116.6c0-1.8,0-3.4,0-5.3c8-5.8,15-13,20.5-21.1
    c-7.4,3.3-15.5,5.4-23.7,6.4c8.4-5.1,15-13.1,18-22.7c-8,4.8-16.8,8.1-26,9.8c-15.5-16.5-41.5-17.3-58-1.8
    c-10.7,10-15.1,25-11.8,39.1c-33-1.7-63.7-17.1-84.5-42.8C3.2,47.1,8.7,71,26.7,83c-6.4-0.1-12.8-2-18.7-5.1c0,0.1,0,0.3,0,0.4
    c0,19.5,13.8,36.4,32.8,40.1c-6,1.7-12.3,2-18.5,0.7c5.3,16.7,20.8,28.1,38.4,28.5c-14.5,11.5-32.5,17.7-50.8,17.7
    c-3.3,0-6.4-0.1-9.8-0.4C18.8,176.8,40.6,183.2,63,183.2'
    />
  </svg>
);
Twitter.propTypes = IconPropTypes;

export const Instagram = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 200 200'>
    <path
      fill={fill}
      d='M100,18.02c26.7,0,29.86,0.1,40.41,0.58c9.75,0.44,15.04,2.07,18.57,3.44c4.67,1.81,8,3.98,11.5,7.48
    s5.67,6.83,7.48,11.5c1.37,3.52,3,8.82,3.44,18.57c0.48,10.54,0.58,13.71,0.58,40.41s-0.1,29.86-0.58,40.41
    c-0.44,9.75-2.07,15.04-3.44,18.57c-1.81,4.67-3.98,8-7.48,11.5c-3.5,3.5-6.83,5.67-11.5,7.48c-3.52,1.37-8.82,3-18.57,3.44
    c-10.54,0.48-13.71,0.58-40.41,0.58s-29.87-0.1-40.41-0.58c-9.75-0.44-15.04-2.07-18.57-3.44c-4.67-1.81-8-3.98-11.5-7.48
    c-3.5-3.5-5.67-6.83-7.48-11.5c-1.37-3.52-3-8.82-3.44-18.57c-0.48-10.54-0.58-13.71-0.58-40.41s0.1-29.86,0.58-40.41
    c0.44-9.75,2.07-15.04,3.44-18.57c1.81-4.67,3.98-8,7.48-11.5s6.83-5.67,11.5-7.48c3.52-1.37,8.82-3,18.57-3.44
    C70.14,18.12,73.3,18.02,100,18.02 M100,0C72.84,0,69.44,0.12,58.77,0.6C48.13,1.09,40.86,2.78,34.5,5.25
    c-6.58,2.56-12.15,5.97-17.71,11.53S7.81,27.92,5.25,34.5C2.78,40.86,1.09,48.13,0.6,58.77C0.11,69.44,0,72.84,0,100
    s0.11,30.56,0.6,41.23c0.49,10.64,2.18,17.91,4.65,24.27c2.56,6.58,5.97,12.15,11.53,17.71c5.56,5.56,11.14,8.98,17.71,11.53
    c6.36,2.47,13.63,4.16,24.27,4.65c10.67,0.49,14.07,0.6,41.23,0.6s30.56-0.11,41.23-0.6c10.64-0.49,17.91-2.18,24.27-4.65
    c6.58-2.56,12.15-5.97,17.71-11.53c5.56-5.56,8.98-11.14,11.53-17.71c2.47-6.36,4.16-13.63,4.65-24.27
    c0.49-10.67,0.6-14.07,0.6-41.23s-0.12-30.56-0.6-41.23c-0.49-10.64-2.18-17.91-4.65-24.27c-2.56-6.58-5.97-12.15-11.53-17.71
    c-5.56-5.56-11.14-8.98-17.71-11.53c-6.36-2.47-13.63-4.16-24.27-4.65C130.56,0.12,127.16,0,100,0z M100,48.65
    c-28.36,0-51.35,22.99-51.35,51.35s22.99,51.35,51.35,51.35s51.35-22.99,51.35-51.35S128.36,48.65,100,48.65z M100,133.33
    c-18.41,0-33.33-14.92-33.33-33.33S81.59,66.67,100,66.67s33.33,14.92,33.33,33.33S118.41,133.33,100,133.33z M165.38,46.62
    c0,6.63-5.37,12-12,12s-12-5.37-12-12s5.37-12,12-12S165.38,39.99,165.38,46.62z'
    />
  </svg>
);
Instagram.propTypes = IconPropTypes;

export const YouTube = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 200 200'>
    <path
      fill={fill}
      d='M158.4,29.3h-117C18.6,29.3,0,48,0,71v58.3c0,23,18.7,41.4,41.4,41.4h117c23,0,41.6-18.7,41.6-41.4V71
    C200,48,181.3,29.3,158.4,29.3z M130.3,103l-54.7,26.2c-1.6,0.7-3.2-0.6-3.2-2V73.3c0-1.7,1.7-2.7,3.2-2L130.3,99
    C132,99.8,132,102.1,130.3,103z'
    />
  </svg>
);
YouTube.propTypes = IconPropTypes;

export const WhatsApp = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 200 200'>
    <g transform='matrix(9.09091,0,0,9.09091,-190.909,-1372.73)'>
      <path
        fill={fill}
        d='M37.04,164.184C36.766,164.047 35.421,163.388 35.17,163.297C34.919,163.207 34.736,163.161 34.554,163.434C34.371,163.706 33.847,164.32 33.687,164.502C33.528,164.684 33.368,164.706 33.094,164.57C32.821,164.434 31.939,164.146 30.893,163.218C30.08,162.496 29.53,161.604 29.371,161.331C29.211,161.058 29.354,160.911 29.491,160.775C29.614,160.653 29.764,160.457 29.901,160.298C30.038,160.139 30.084,160.025 30.175,159.843C30.266,159.662 30.221,159.503 30.152,159.366C30.084,159.23 29.536,157.889 29.308,157.344C29.086,156.813 28.86,156.884 28.692,156.876C28.533,156.868 28.35,156.866 28.168,156.866C27.985,156.866 27.689,156.935 27.438,157.207C27.187,157.48 26.48,158.139 26.48,159.48C26.48,160.821 27.461,162.116 27.598,162.298C27.734,162.479 29.528,165.231 32.273,166.411C32.926,166.691 33.436,166.859 33.834,166.984C34.489,167.192 35.086,167.162 35.558,167.092C36.083,167.014 37.177,166.433 37.405,165.797C37.633,165.161 37.633,164.615 37.565,164.502C37.496,164.388 37.314,164.32 37.04,164.184M32.046,170.97L32.042,170.97C30.409,170.969 28.806,170.532 27.408,169.707L27.076,169.51L23.63,170.41L24.55,167.066L24.333,166.724C23.422,165.281 22.941,163.614 22.941,161.901C22.943,156.906 27.028,152.841 32.05,152.841C34.481,152.842 36.767,153.786 38.486,155.498C40.205,157.211 41.151,159.488 41.151,161.909C41.148,166.905 37.064,170.97 32.046,170.97M39.795,154.197C37.727,152.136 34.976,151.001 32.046,151C26.008,151 21.094,155.89 21.092,161.901C21.091,163.822 21.595,165.698 22.554,167.351L21,173L26.807,171.484C28.407,172.353 30.208,172.81 32.042,172.811L32.046,172.811C38.083,172.811 42.998,167.92 43,161.909C43.001,158.997 41.863,156.258 39.795,154.197'
      />
    </g>
  </svg>
);
WhatsApp.propTypes = IconPropTypes;

export const Xing = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 200 200'>
    <path
      fill={fill}
      d='M30.4,39.5c-1.7,0-3.2,0.6-3.9,1.8c-0.8,1.2-0.6,2.8,0.2,4.4l19.5,33.8c0,0.1,0,0.1,0,0.2
    l-30.6,54.1c-0.8,1.6-0.8,3.2,0,4.4c0.7,1.2,2,2,3.8,2h28.8c4.3,0,6.4-2.9,7.9-5.6c0,0,30-53,31.1-55.1C87,79.3,67.3,44.9,67.3,44.9
    c-1.4-2.6-3.6-5.4-8-5.4L30.4,39.5L30.4,39.5z'
    />
    <path
      fill={fill}
      d='M151.6,0c-4.3,0-6.2,2.7-7.7,5.5c0,0-62.1,110.2-64.2,113.8c0.1,0.2,41,75.2,41,75.2
    c1.4,2.6,3.6,5.5,8.1,5.5h28.8c1.7,0,3.1-0.7,3.8-1.8c0.8-1.2,0.7-2.9-0.1-4.5l-40.7-74.3c0-0.1,0-0.1,0-0.2L184.5,6.3
    c0.8-1.6,0.8-3.2,0.1-4.5c-0.7-1.2-2.1-1.8-3.8-1.8L151.6,0L151.6,0z'
    />
  </svg>
);
Xing.propTypes = IconPropTypes;

export const SignOut = ({ size, fill, ...props }) => (
  <svg {...props} version='1.1' width={size} height={size} viewBox='0 0 512 512'>
    <path
      fill={fill}
      d='M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z'
    />
  </svg>
);
SignOut.propTypes = IconPropTypes;

export const Adventure = ({ size, fill, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 200 200'
  >
    <g transform='matrix(1,0,0,1,6,2)'>
      <g transform='matrix(9.30233,0,0,9.30233,40.8489,4.97674)'>
        <path
          fill={fill}
          d='M5.016,13.576C5.016,13.576 4.372,16.034 4.372,16.034L7.055,16.034L6.412,13.576C6.31,13.186 6.533,12.786 6.918,12.668C7.237,12.571 7.544,12.436 7.834,12.268C8.192,12.061 8.651,12.183 8.859,12.542C9.066,12.9 8.943,13.359 8.585,13.566C8.406,13.67 8.222,13.764 8.034,13.849L8.752,16.594C8.811,16.819 8.762,17.058 8.62,17.242C8.478,17.426 8.259,17.534 8.027,17.534L3.401,17.534C3.168,17.534 2.949,17.426 2.807,17.242C2.665,17.058 2.616,16.819 2.675,16.594L3.394,13.848C0.991,12.767 -0.75,10.032 -0.75,6.799C-0.75,2.597 2.182,-0.75 5.714,-0.75C9.245,-0.75 12.177,2.597 12.177,6.799C12.177,8.383 11.76,9.85 11.054,11.058C10.845,11.415 10.385,11.535 10.028,11.326C9.671,11.117 9.55,10.658 9.759,10.3C10.34,9.308 10.677,8.1 10.677,6.799C10.677,3.491 8.494,0.75 5.714,0.75C2.933,0.75 0.75,3.491 0.75,6.799C0.75,9.61 2.322,12.001 4.509,12.668C4.864,12.777 5.087,13.129 5.032,13.496C5.028,13.523 5.022,13.55 5.016,13.576Z'
        />
      </g>
      <g transform='matrix(9.30233,0,0,9.30233,40.8489,4.97674)'>
        <path
          fill={fill}
          d='M4.986,16.784C4.986,16.37 5.322,16.034 5.736,16.034C6.15,16.034 6.486,16.37 6.486,16.784L6.486,20C6.486,20.414 6.15,20.75 5.736,20.75C5.322,20.75 4.986,20.414 4.986,20L4.986,16.784Z'
        />
      </g>
      <g transform='matrix(9.30233,0,0,9.30233,40.8489,4.97674)'>
        <path
          fill={fill}
          d='M3.393,5.698C3.331,6.108 2.948,6.39 2.539,6.328C2.13,6.266 1.848,5.884 1.909,5.474C1.909,5.474 2.243,3.376 3.84,2.375C4.191,2.156 4.654,2.262 4.874,2.613C5.093,2.963 4.987,3.427 4.636,3.646C3.587,4.304 3.393,5.698 3.393,5.698Z'
        />
      </g>
    </g>
  </svg>
);
Adventure.propTypes = IconPropTypes;

export const Culinary = ({ size, fill, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 200 200'
  >
    <g transform='matrix(1,0,0,1,4,5)'>
      <g transform='matrix(11.6425,0,0,11.6425,4.68736,13.4788)'>
        <path
          fill={fill}
          d='M8.205,9.978C7.794,9.928 7.501,9.553 7.552,9.143C7.602,8.732 7.977,8.439 8.387,8.489C9.411,8.615 10.449,8.481 11.408,8.101C11.41,8.1 11.412,8.099 11.414,8.098C13.914,7.134 15.488,5.402 14.75,3.438C14.008,1.462 11.323,0.926 8.82,1.956C8.589,2.051 8.325,2.024 8.117,1.885C7.728,1.624 7.31,1.411 6.87,1.249C6.86,1.245 6.849,1.241 6.839,1.237C4.362,0.196 1.683,0.844 0.926,2.769C0.308,4.402 1.329,6.303 3.153,7.495C3.499,7.722 3.597,8.187 3.37,8.534C3.144,8.88 2.679,8.977 2.332,8.751C-0.145,7.132 -1.317,4.452 -0.475,2.233C-0.474,2.231 -0.473,2.229 -0.472,2.226C0.543,-0.37 4.066,-1.548 7.4,-0.154C7.823,0.002 8.231,0.197 8.617,0.427C11.877,-0.741 15.189,0.342 16.154,2.91C17.16,5.587 15.362,8.183 11.956,9.497C10.766,9.969 9.476,10.134 8.205,9.978Z'
        />
      </g>
      <g transform='matrix(11.6425,0,0,11.6425,4.68736,13.4788)'>
        <path
          fill={fill}
          d='M12.186,8.208C12.186,7.794 12.522,7.458 12.936,7.458C13.35,7.458 13.686,7.794 13.686,8.208L13.686,14C13.686,14.414 13.35,14.75 12.936,14.75L2.743,14.75C2.328,14.75 1.993,14.414 1.993,14L1.993,8.053C1.993,7.639 2.329,7.303 2.743,7.303C3.157,7.303 3.493,7.639 3.493,8.053C3.493,8.053 3.493,13.25 3.493,13.25L12.186,13.25L12.186,8.208Z'
        />
      </g>
      <g transform='matrix(11.6425,0,0,11.6425,4.68736,13.4788)'>
        <path
          fill={fill}
          d='M8.939,0.244C9.337,0.133 9.751,0.367 9.862,0.766C9.973,1.165 9.739,1.579 9.341,1.69C8.35,1.965 7.429,2.447 6.638,3.104C6.32,3.369 5.847,3.325 5.582,3.007C5.318,2.689 5.361,2.215 5.68,1.951C6.633,1.158 7.744,0.577 8.939,0.244Z'
        />
      </g>
    </g>
  </svg>
);
Culinary.propTypes = IconPropTypes;

export const Culture = ({ size, fill, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 200 200'
  >
    <g transform='matrix(1,0,0,1,4,4)'>
      <g transform='matrix(11.4286,0,0,11.4286,5.66969,4.57142)'>
        <path
          fill={fill}
          d='M7.03,12.147C7.41,11.983 7.852,12.159 8.016,12.539C8.18,12.919 8.005,13.36 7.625,13.524L0.297,16.689C0.019,16.809 -0.305,16.749 -0.523,16.538C-0.74,16.327 -0.809,16.005 -0.697,15.723L3.889,4.194C3.982,3.959 4.187,3.787 4.435,3.736C4.682,3.685 4.938,3.762 5.116,3.94L11.791,10.615C12.083,10.907 12.083,11.383 11.791,11.675C11.498,11.968 11.023,11.968 10.73,11.675L4.862,5.807C4.862,5.807 1.367,14.593 1.367,14.593L7.03,12.147Z'
        />
      </g>
      <g transform='matrix(11.4286,0,0,11.4286,5.66969,4.57142)'>
        <path
          fill={fill}
          d='M3.133,6.303C3.105,5.89 3.417,5.532 3.83,5.503C4.243,5.475 4.601,5.787 4.63,6.2L5.168,14.04C5.196,14.453 4.884,14.811 4.471,14.84C4.058,14.868 3.7,14.556 3.671,14.143L3.133,6.303Z'
        />
      </g>
      <g transform='matrix(11.4286,0,0,11.4286,5.66969,4.57142)'>
        <path
          fill={fill}
          d='M7.725,-0.609C8.824,-0.609 9.717,0.284 9.717,1.384C9.717,2.483 8.824,3.376 7.725,3.376C6.625,3.376 5.732,2.483 5.732,1.384C5.732,0.284 6.625,-0.609 7.725,-0.609ZM7.725,0.891C7.453,0.891 7.232,1.112 7.232,1.384C7.232,1.655 7.453,1.876 7.725,1.876C7.996,1.876 8.217,1.655 8.217,1.384C8.217,1.112 7.996,0.891 7.725,0.891Z'
        />
      </g>
      <g transform='matrix(11.4286,0,0,11.4286,5.66969,4.57142)'>
        <path
          fill={fill}
          d='M14.091,-0.75C15.453,-0.75 16.558,0.355 16.558,1.717C16.558,3.078 15.453,4.183 14.091,4.183C12.73,4.183 11.625,3.078 11.625,1.717C11.625,0.355 12.73,-0.75 14.091,-0.75ZM14.091,0.75C13.558,0.75 13.125,1.183 13.125,1.717C13.125,2.25 13.558,2.683 14.091,2.683C14.625,2.683 15.058,2.25 15.058,1.717C15.058,1.183 14.625,0.75 14.091,0.75Z'
        />
      </g>
      <g transform='matrix(11.4286,0,0,11.4286,5.66969,4.57142)'>
        <path
          fill={fill}
          d='M10.768,6.817C10.371,6.934 9.954,6.707 9.836,6.31C9.719,5.913 9.946,5.496 10.343,5.378L12.598,4.712C12.995,4.595 13.412,4.822 13.53,5.219C13.647,5.616 13.42,6.034 13.023,6.151L10.768,6.817Z'
        />
      </g>
      <g transform='matrix(11.4286,0,0,11.4286,5.66969,4.57142)'>
        <path
          fill={fill}
          d='M12.89,9.02C12.501,8.88 12.298,8.45 12.437,8.061C12.577,7.671 13.007,7.468 13.396,7.608L15.613,8.402C16.002,8.542 16.205,8.971 16.066,9.361C15.926,9.751 15.496,9.954 15.106,9.814L12.89,9.02Z'
        />
      </g>
    </g>
  </svg>
);
Culture.propTypes = IconPropTypes;

export const Energy = ({ size, fill, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 200 200'
  >
    <g transform='matrix(1,0,0,1,7,3)'>
      <g transform='matrix(10.2565,0,0,10.2565,38.385,4.69086)'>
        <path
          fill={fill}
          d='M3.8,15.259C3.492,15.535 3.017,15.509 2.741,15.2C2.465,14.892 2.491,14.417 2.8,14.141L8.312,9.21L2.19,9.21C1.887,9.21 1.613,9.027 1.497,8.747C1.381,8.467 1.445,8.144 1.66,7.93L10.12,-0.53C10.412,-0.823 10.888,-0.823 11.18,-0.53C11.473,-0.238 11.473,0.238 11.18,0.53L4.001,7.71L10.275,7.71C10.586,7.71 10.865,7.902 10.976,8.192C11.087,8.483 11.007,8.812 10.775,9.019L3.8,15.259Z'
        />
      </g>
      <g transform='matrix(10.2565,0,0,10.2565,38.385,4.69086)'>
        <path
          fill={fill}
          d='M4.395,17.25C4.809,17.25 5.145,17.586 5.145,18C5.145,18.414 4.809,18.75 4.395,18.75L0,18.75C-0.414,18.75 -0.75,18.414 -0.75,18L-0.75,13.35C-0.75,12.936 -0.414,12.6 -0,12.6C0.414,12.6 0.75,12.936 0.75,13.35L0.75,17.25L4.395,17.25Z'
        />
      </g>
    </g>
  </svg>
);
Energy.propTypes = IconPropTypes;

export const Myhome = ({ size, fill, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 200 200'
  >
    <g transform='matrix(1,0,0,1,6,5)'>
      <g transform='matrix(12.1212,0,0,12.1212,16.7397,4.09091)'>
        <path
          fill={fill}
          d='M7.592,14.186C8.006,14.186 8.342,14.522 8.342,14.936C8.342,15.35 8.006,15.686 7.592,15.686L0.002,15.686C-0.413,15.686 -0.748,15.35 -0.748,14.936C-0.748,14.936 -0.748,7.101 -0.748,7.109C-0.775,6.414 -0.504,5.741 -0.01,5.266L5.853,-0.533C6.147,-0.824 6.62,-0.822 6.911,-0.53C6.911,-0.53 12.759,5.331 12.759,5.331C13.253,5.811 13.521,6.477 13.496,7.165L13.496,15C13.496,15.414 13.16,15.75 12.746,15.75C12.332,15.75 11.996,15.414 11.996,15L11.996,7.152C11.996,7.141 11.997,7.13 11.997,7.12C12.009,6.851 11.904,6.59 11.711,6.404C11.707,6.4 11.704,6.397 11.7,6.393L6.377,1.058L1.044,6.332C1.042,6.335 1.04,6.337 1.037,6.339C0.844,6.526 0.739,6.787 0.751,7.055C0.751,7.066 0.752,7.077 0.752,7.088L0.752,14.186L7.592,14.186Z'
        />
      </g>
      <g transform='matrix(12.1212,0,0,12.1212,16.7397,4.09091)'>
        <path
          fill={fill}
          d='M4.407,5.823C4.7,5.53 5.175,5.53 5.467,5.823C5.76,6.115 5.76,6.591 5.467,6.883L3.599,8.752C3.306,9.045 2.831,9.045 2.538,8.752C2.246,8.459 2.246,7.984 2.538,7.691L4.407,5.823Z'
        />
      </g>
    </g>
  </svg>
);
Myhome.propTypes = IconPropTypes;

export const MapPin = ({ size, fill, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 200 200'
  >
    <g transform='matrix(15.3842,0,0,15.3842,-69.2367,-69.2239)'>
      <path
        fill={fill}
        d='M10.96,17.5C10.762,17.499 10.583,17.381 10.504,17.2L7.135,9.676C6.78,8.89 6.754,7.994 7.064,7.189C7.681,5.573 9.241,4.5 10.971,4.5C13.265,4.5 15.153,6.387 15.153,8.682C15.153,8.704 15.152,8.727 15.152,8.749C15.152,9.023 14.926,9.249 14.652,9.249C14.378,9.249 14.152,9.023 14.152,8.749C14.152,8.726 14.153,8.703 14.153,8.68C14.153,6.935 12.716,5.498 10.971,5.498C9.65,5.498 8.461,6.321 7.994,7.556C7.783,8.111 7.802,8.727 8.047,9.268L10.958,15.768L12.529,12.233C12.609,12.053 12.789,11.936 12.986,11.936C13.26,11.936 13.486,12.162 13.486,12.436C13.486,12.506 13.471,12.575 13.443,12.639L11.417,17.2C11.338,17.381 11.159,17.499 10.961,17.5L10.96,17.5Z'
      />
      <path
        fill={fill}
        d='M10.96,10.436C9.934,10.436 9.089,9.591 9.089,8.565C9.089,7.539 9.934,6.694 10.96,6.694C11.986,6.694 12.831,7.539 12.831,8.565C12.83,9.591 11.986,10.435 10.96,10.436ZM10.96,7.694C10.482,7.694 10.089,8.087 10.089,8.565C10.089,9.043 10.482,9.436 10.96,9.436C11.438,9.436 11.831,9.043 11.831,8.565C11.831,8.087 11.438,7.694 10.96,7.694Z'
      />
    </g>
  </svg>
);
MapPin.propTypes = IconPropTypes;

export const Recreation = ({ size, fill, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 200 200'
  >
    <g transform='matrix(1,0,0,1,3,4)'>
      <g transform='matrix(9.77272,0,0,9.77272,4.32956,22.7405)'>
        <path
          fill={fill}
          d='M13.022,7.407C13.022,11.774 9.822,15.317 9.822,15.317C9.68,15.474 9.478,15.563 9.266,15.563C9.055,15.563 8.853,15.474 8.711,15.317C8.711,15.317 5.511,11.774 5.511,7.407C5.511,3.04 8.711,-0.504 8.711,-0.504C8.853,-0.661 9.055,-0.75 9.266,-0.75C9.478,-0.75 9.68,-0.661 9.822,-0.504C9.822,-0.504 13.022,3.04 13.022,7.407ZM11.522,7.407C11.522,4.68 10.085,2.325 9.266,1.203C8.447,2.325 7.011,4.68 7.011,7.407C7.011,10.133 8.447,12.489 9.266,13.611C10.085,12.489 11.522,10.133 11.522,7.407Z'
        />
      </g>
      <g transform='matrix(9.77272,0,0,9.77272,4.32956,22.7405)'>
        <path
          fill={fill}
          d='M6.95,4.947L5.982,6.093C5.084,5.334 4.143,4.829 3.495,4.534C3.359,5.781 3.308,8.136 4.439,10.146C6.058,13.025 9.492,14.098 9.492,14.098L9.041,15.529C9.041,15.529 5.029,14.256 3.131,10.881C1.239,7.516 2.166,3.325 2.166,3.325C2.212,3.12 2.342,2.944 2.524,2.839C2.706,2.734 2.923,2.711 3.124,2.774C3.124,2.774 5.117,3.397 6.95,4.947Z'
        />
      </g>
      <g transform='matrix(9.77272,0,0,9.77272,4.32956,22.7405)'>
        <path
          fill={fill}
          d='M3.319,8.483L3.105,9.968C2.304,9.853 1.567,9.874 1.032,9.924C1.461,10.892 2.358,12.489 3.901,13.404C6.212,14.774 9.094,14.084 9.094,14.084L9.439,15.544C9.439,15.544 5.943,16.358 3.136,14.694C0.34,13.037 -0.719,9.535 -0.719,9.535C-0.778,9.337 -0.753,9.124 -0.649,8.945C-0.546,8.766 -0.373,8.638 -0.172,8.591C-0.172,8.591 1.424,8.21 3.319,8.483Z'
        />
      </g>
      <g transform='matrix(9.77272,0,0,9.77272,4.32956,22.7405)'>
        <path
          fill={fill}
          d='M12.643,6.395L11.618,5.3C13.548,3.493 15.841,2.774 15.841,2.774C16.042,2.711 16.259,2.734 16.441,2.839C16.623,2.944 16.753,3.12 16.799,3.325C16.799,3.325 17.726,7.516 15.834,10.881C13.936,14.256 9.924,15.529 9.924,15.529L9.473,14.098C9.473,14.098 12.907,13.025 14.527,10.146C15.657,8.136 15.606,5.781 15.47,4.534C14.74,4.866 13.636,5.465 12.643,6.395Z'
        />
      </g>
      <g transform='matrix(9.77272,0,0,9.77272,4.32956,22.7405)'>
        <path
          fill={fill}
          d='M15.86,9.968L15.647,8.483C17.542,8.21 19.137,8.591 19.137,8.591C19.338,8.638 19.511,8.766 19.614,8.945C19.718,9.124 19.743,9.337 19.684,9.535C19.684,9.535 18.625,13.037 15.829,14.694C13.022,16.358 9.527,15.543 9.527,15.543L9.871,14.084C9.871,14.084 12.753,14.774 15.064,13.404C16.607,12.489 17.504,10.892 17.933,9.924C17.398,9.874 16.661,9.853 15.86,9.968Z'
        />
      </g>
    </g>
  </svg>
);
Recreation.propTypes = IconPropTypes;

export const Exclamation = ({ size, ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} {...props}>
    <title>{'achtung-round'}</title>
    <g fill='#FFF'>
      <path d='M16 19.703a3.812 3.812 0 1 0 3.815 3.813A3.792 3.792 0 0 0 16 19.703Zm0 6.17a2.36 2.36 0 0 1-2.358-2.359A2.358 2.358 0 1 1 16 25.874ZM13.037 17.574h5.926a.727.727 0 0 0 .727-.727v-6.163a.727.727 0 0 0-1.454 0v5.437h-4.472V6.127h5.199a.727.727 0 1 0 0-1.455h-5.926a.727.727 0 0 0-.727.727v11.448c0 .401.325.727.727.727Z' />
    </g>
  </svg>
);
Exclamation.propTypes = IconPropTypes;
