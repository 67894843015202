import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';
import { CheckboxTick } from '../../../images/icons';
import Colors from '../../../definitions/colors';

const Checkbox = ({ children, disabled, onUpdateValue, value }) => {
  return (
    <div
      className={`Checkbox
      ${disabled ? 'Checkbox--Disabled' : ''}
      ${value ? 'Checkbox--Ticked' : ''}
      `}
    >
      <div className='Checkbox__Indicator' onClick={() => onUpdateValue(!value)}>
        <div className='Checkbox__Tick'>
          <CheckboxTick fill={disabled ? Colors.Gray : Colors.Orange} size={17} />
        </div>
      </div>
      <div className='Checkbox__Label'>{children}</div>
    </div>
  );
};

Checkbox.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  onUpdateValue: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired
};

export default Checkbox;
