import React from 'react';
import PropTypes from 'prop-types';
import { contractTypeLabel } from '../../../helpers/formatters';
import './ContractSelectItem.scss';
import { Tick } from '../../../images/icons';
import Colors from '../../../definitions/colors';
import useResources from '../../../hooks/useResources';

const ContractSelectItem = ({ contract, picked, onPick }) => {
  const resource = useResources();
  return (
    <div
      className={`ContractSelectItem ${picked ? 'ContractSelectItem--Picked' : ''}`}
      onClick={onPick}
    >
      <h3 className='ContractSelectItem__Name'>{`${contract.firstName} ${contract.lastName}`}</h3>
      <p className='ContractSelectItem__Details'>
        {contract.address.replace(/(\r\n|\r\n)/g, ', ')}
        <br />
        {`${contractTypeLabel(contract.types, resource)} (${contract.customerId})`}
      </p>
      <div className='ContractSelectItem__Check'>
        <div className='ContractSelectItem__Tick'>
          <Tick fill={Colors.Orange} size={24} />
        </div>
      </div>
    </div>
  );
};

ContractSelectItem.propTypes = {
  contract: PropTypes.object.isRequired,
  picked: PropTypes.bool,
  onPick: PropTypes.func.isRequired
};

export default ContractSelectItem;
