export const apiRequest = (
  token,
  endpoint,
  method = 'GET',
  data = null,
  api = process.env.REACT_APP_API_INTERACT,
  acceptErrors = false,
  signal
) => {
  return fetch(`${api}${endpoint}`, {
    method,
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      'X-Client': 'Portal',
      ...(data && (method === 'POST' || method === 'PUT') && { 'Content-Type': 'application/json' })
    },
    ...(data && { body: JSON.stringify(data) }),
    signal
  }).then(response => {
    if (!response.ok && !acceptErrors) {
      throw new Error(response.statusText);
    }
    // Introduce an artificial delay, if environment variable is set
    if (process.env.REACT_APP_API_DELAY) {
      return new Promise(resolve =>
        setTimeout(() => resolve(response), parseInt(process.env.REACT_APP_API_DELAY, 10))
      );
    }
    return response;
  });
};

export const apiIdentityRequest = (token, endpoint, method = 'GET', data = null) => {
  return fetch(`${process.env.REACT_APP_API_IDENTITY}${endpoint}`, {
    method,
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Client': 'Portal',
      ...(data && (method === 'POST' || method === 'PUT') && { 'Content-Type': 'application/json' })
    },
    ...(data && { body: JSON.stringify(data) })
  }).then(response => {
    // Introduce an artificial delay, if environment variable is set
    if (process.env.REACT_APP_API_DELAY) {
      return new Promise(resolve =>
        setTimeout(() => resolve(response), parseInt(process.env.REACT_APP_API_DELAY, 10))
      );
    }
    return response;
  });
};
