import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.scss';

const ProgressBar = ({ value, max }) => {
  return (
    <div className='ProgressBar'>
      <div className='ProgressBar__Progress' style={{ transform: `scaleX(${value / max})` }} />
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
};

export default ProgressBar;
