import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Close } from '../../images/icons';
import Colors from '../../definitions/colors';
import useResources from '../../hooks/useResources';
import { classes } from '../../helpers/styles';
import { AppDivContext } from '../App';
import { createPortal } from 'react-dom';
import './Overlay.scss';

/**
 * Overlay container and renderer.
 * @param fixed True if fixed positioning should be used instead of scrolling with main view.
 * @param visible True if visible.
 * @param onClose Action to run when overlay is closed.
 * @param children The content of the overlay.
 * @param showCloseButton True if close button is present.
 * @param containerStyle Extra style for the container/background.
 * @param windowStyle Extra style for the window.
 * @param rounded True if the window should be rounded.
 * @constructor
 */
const Overlay = ({
  fixed,
  visible,
  onClose,
  children,
  showCloseButton,
  containerStyle,
  windowStyle,
  rounded
}) => {
  // Use resources for title.
  const resource = useResources();

  // Compose container and window classes.
  const containerClasses = classes(
    'Overlay',
    visible ? 'Overlay--In' : 'Overlay--Out',
    fixed && 'Overlay--Fixed'
  );
  const windowClasses = classes(
    'Overlay__Window',
    visible ? 'Overlay__Window--In' : 'Overlay__Window--Out',
    rounded && 'Overlay__Window--Rounded'
  );

  const appDiv = useContext(AppDivContext);
  if (!appDiv) return null;
  return createPortal(
    <div className={containerClasses} style={containerStyle}>
      <div className={windowClasses} style={windowStyle}>
        {showCloseButton && (
          <button className='Overlay__Close' title={resource('overlay.close')} onClick={onClose}>
            <Close size={23} fill={Colors.Orange} />
          </button>
        )}
        {children}
      </div>
    </div>,
    appDiv
  );
};

Overlay.propTypes = {
  fixed: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  showCloseButton: PropTypes.bool,
  containerStyle: PropTypes.object,
  windowStyle: PropTypes.object,
  rounded: PropTypes.bool
};

Overlay.defaultProps = {
  showCloseButton: true
};

export default Overlay;
