import { combineReducers } from 'redux';
import activations from './activations';
import auth from './auth';
import bookmarks from './bookmarks';
import interests from './interests';
import modal from './modal';
import offers from './offers';
import onboarding, { OnboardingStatus } from './onboarding';
import preload from './preload';
import resources from './resources';
import settings from './settings';
import tour from './tour';

export default combineReducers({
  activations,
  auth,
  bookmarks,
  modal,
  interests,
  offers,
  onboarding,
  preload,
  resources,
  settings,
  tour
});

export const selectAppReady = state => {
  return (
    state.onboarding.status !== OnboardingStatus.Indeterminate &&
    !state.onboarding.prefillLoading &&
    !state.offers.loading &&
    !state.preload.loadingImages &&
    !state.interests.loading
  );
};
