import { apiRequest } from '../../api/api';
import { requestPreload } from './preload';
import { setAuthenticationError, setAuthenticationSuccess } from './auth';

const initialState = {
  loading: false,
  highlights: [],
  campaigns: [],
  offersStandalone: [],
  offersUpcoming: [],
  offers: []
};

export const OFFERS_REQUEST = 'offers/REQUEST';
const requestOffers = () => ({
  type: OFFERS_REQUEST
});

export const OFFERS_RECEIVE = 'offers/RECEIVE';
const receiveOffers = data => ({
  type: OFFERS_RECEIVE,
  payload: data
});

export const OFFERS_FAILURE = 'offers/FAILURE';
export const failureOffers = message => ({
  type: OFFERS_FAILURE,
  payload: {
    message
  }
});

export const fetchOffers = token => {
  return dispatch => {
    dispatch(requestOffers());
    return apiRequest(token, '/')
      .then(response => {
        if (response.status >= 400) {
          throw new Error('Offers and campaigns could not be loaded.');
        }
        return response.json();
      })
      .then(data => {
        dispatch(setAuthenticationSuccess());
        dispatch(receiveOffers(data));
        dispatch(requestPreload());
      })
      .catch(error => {
        dispatch(setAuthenticationError());
        dispatch(failureOffers(error));
      });
  };
};

export const selectLoading = state => state.offers.loading;
export const selectCampaigns = state => state.offers.campaigns;
export const selectAllOffers = state => state.offers.offers;
export const selectOffersStandalone = state => state.offers.offersStandalone;
export const selectOffersUpcoming = state => state.offers.offersUpcoming;
export const selectCampaign = (id, resource) => state => {
  // Mocking special campaign: Standalone offers
  if (id === 'standalone') {
    return {
      title: resource('offers.standalone.title'),
      excerpt: resource('offers.standalone.intro'),
      offers: state.offers.offersStandalone
    };
  }
  // Mocking special campaign: Upcoming offers
  if (id === 'upcoming') {
    return {
      title: resource('offers.upcoming.title'),
      excerpt: resource('offers.upcoming.intro'),
      offers: state.offers.offersUpcoming
    };
  }
  // Regular campaign with an actual ID
  if (state.offers.campaigns.length > 0) {
    return state.offers.campaigns.find(campaign => campaign.id === id);
  }
  return null;
};
export const selectOffer = id => state => {
  if (state.offers.offers.length > 0) {
    return state.offers.offers.find(offer => offer.id === id);
  }
  return null;
};
export const selectOffersById = ids => state => {
  if (ids && ids.length > 0) {
    const offers = [];
    ids.forEach(id => {
      const offer = selectOffer(id)(state);
      offer && offers.push(offer);
    });
    return offers;
  }
  return null;
};

export default function offers(state = initialState, action) {
  switch (action.type) {
    case OFFERS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case OFFERS_RECEIVE: {
      const { highlights, campaigns, offers, offersUpcoming } = action.payload;
      const offersLookupTable = [];
      offersLookupTable.push(...offers.map(offer => ({ campaign: 'standalone', ...offer })));
      offersLookupTable.push(...offersUpcoming.map(offer => ({ campaign: 'upcoming', ...offer })));
      campaigns.forEach(campaign =>
        offersLookupTable.push(
          ...campaign.offers.map(offer => ({ campaign: campaign.id, ...offer }))
        )
      );

      return {
        ...state,
        loading: false,
        highlights,
        campaigns,
        offersStandalone: offers,
        offersUpcoming,
        offers: offersLookupTable
      };
    }
    case OFFERS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
}
