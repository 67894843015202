import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Routes from '../../definitions/routes';
import scrollToTop from '../../helpers/scrollToTop';
import Bookmarks from '../views/Bookmarks';
import Index from '../views/Index';
import OfferPreview from '../views/OfferPreview';
import Onboarding from '../views/Onboarding';
import Profile from '../views/Profile';
import SingleCampaign from '../views/SingleCampaign';
import SingleOffer from '../views/SingleOffer';
import './ViewSwitch.scss';

const ViewSwitch__TransitionDuration = 300;

const ViewSwitch = () => {
  const [lastPath, setLastPath] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== lastPath) {
      setLastPath(location.pathname);
      scrollToTop(ViewSwitch__TransitionDuration);
    }
  }, [location.pathname, lastPath, setLastPath]);

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        classNames='ViewSwitch__Transition'
        timeout={ViewSwitch__TransitionDuration}
      >
        <Switch location={location}>
          <Route exact path={Routes.Index()}>
            <Index />
          </Route>
          <Route path={Routes.Onboarding()}>
            <Onboarding />
          </Route>
          <Route path={Routes.Bookmarks()}>
            <Bookmarks />
          </Route>
          <Route path={Routes.Profile()}>
            <Profile />
          </Route>
          <Route path={Routes.SingleCampaign()}>
            <SingleCampaign />
          </Route>
          <Route path={Routes.SingleOffer()}>
            <SingleOffer />
          </Route>
          <Route path={Routes.OfferPreview()}>
            <OfferPreview />
          </Route>
          <Route path='*'>
            <Redirect to={Routes.Index()} />
          </Route>
        </Switch>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default ViewSwitch;
