import pageTitle from '../../helpers/pageTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useResources from '../../hooks/useResources';
import { selectToken } from '../../redux/reducers/auth';
import { fetchBookmarks } from '../../redux/reducers/bookmarks';
import {
  selectCampaigns,
  selectOffersStandalone,
  selectOffersUpcoming
} from '../../redux/reducers/offers';
import Container from '../layout/Container';
import AppTeaser from '../modules/AppTeaser';
import FAQs from '../modules/FAQs';
import RecommendationProgram from '../modules/RecommendationProgram';
import withAuthentication from '../wrappers/withAuthentication';
import OfferGrid from '../modules/OfferGrid';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { useEdgeDebounce } from '../../hooks/useDebounce';
import { IndexFilter, isFilterEmpty, isFilterError, isFilterResults } from '../modules/IndexFilter';
import { IndexCampaigns } from '../modules/IndexCampaigns';
import { IndexOffersStandalone } from '../modules/IndexOffersStandalone';
import { IndexOffersUpcoming } from '../modules/IndexOffersUpcoming';
import './Index.scss';
import { TourIntro } from '../modules/tour/TourIntro';

const Index = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const resource = useResources();

  // Sourced from page-load.
  const campaigns = useSelector(selectCampaigns);
  const offersStandalone = useSelector(selectOffersStandalone);
  const offersUpcoming = useSelector(selectOffersUpcoming);

  // Sourced from filtering.
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);

  // Do not show loading immediately.
  const loadingShow = useEdgeDebounce(loading, 300);

  // Load bookmarks
  useEffect(() => {
    dispatch(fetchBookmarks(token));
  }, []);

  return (
    <div className='Index'>
      {pageTitle()}
      <div className='Index__Main'>
        <Container>
          <h1 className='Index__Headline'>{resource('index.intro.headline')}</h1>
          <p className='Index__Intro'>{resource('index.intro.text')}</p>

          <IndexFilter setLoading={setLoading} setResults={setResults} />

          <LoadingOverlay show={loading || loadingShow} cover={false} />

          {(isFilterError(results) && (
            <span className='Index__FilterError'>{resource('index.offers.filter.error')}</span>
          )) ||
            (isFilterEmpty(results) && (
              <span className='Index__FilterNoResults'>
                {resource('index.offers.filter.noResults')}
              </span>
            )) ||
            (isFilterResults(results) && (
              <OfferGrid className='Index__FilterResults' offers={results} />
            )) || (
              <>
                <IndexCampaigns items={campaigns} />
                <IndexOffersStandalone items={offersStandalone} />
                <IndexOffersUpcoming items={offersUpcoming} />
              </>
            )}
        </Container>
      </div>
      <AppTeaser />
      <RecommendationProgram />
      <FAQs />
      <TourIntro />
    </div>
  );
};

export default withAuthentication(Index);
