import React from 'react';
import PropTypes from 'prop-types';
import Container from '../layout/Container';
import './Hero.scss';

const Hero = ({ children, imageUrl, imageAlt, isDummy, slim }) => {
  return (
    <div className={`Hero ${isDummy ? 'Hero--Dummy' : ''} ${slim ? 'Hero--Slim' : ''}`}>
      {imageUrl && <img src={imageUrl} alt={imageAlt} className='Hero__Image' />}
      <Container>
        <div className='Hero__Wrapper'>
          {!isDummy && <div className='Hero__Content'>{children}</div>}
        </div>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  isDummy: PropTypes.bool,
  imageAlt: PropTypes.string,
  imageUrl: PropTypes.string,
  slim: PropTypes.bool,
  children: PropTypes.any
};

export default Hero;
