import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourAbort, tourNext, TourState } from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import { Blinds } from '../../atoms/Blinds';
import { Flyout } from '../../layout/Flyout';
import { TourCallout } from './TourCallout';
import { Scroller } from '../../atoms/Scroller';
import { useBreakpoint } from '../../../hooks/useBreakpoint';

export function TourEvmOffers() {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutEvmOffers;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => dispatch(tourAbort()), [dispatch]);
  const handleNext = useCallback(() => dispatch(tourNext()), [dispatch]);
  const packed = useBreakpoint('md-down');
  useHistoryListener(() => {
    if (active) dispatch(tourAbort('navigated'));
  }, [active, dispatch]);
  if (skip) return null;
  return (
    <Fragment>
      <Blinds
        active={active}
        radius={8}
        insetTop={-30}
        insetBottom={packed ? 10 : -30}
        insetLeft={20}
        insetRight={1000}
      />
      <Flyout active={active} offset={-5}>
        <TourCallout
          button='Weiter'
          stepAt={11}
          stepTotal={12}
          distance={-10}
          onNext={handleNext}
          onClose={handleAbort}
        >
          <Scroller active={active} />
          Auch bei uns kannst Du sparen: Entdecke energiegeladene Vorteile der evm rund um
          Förderungen und Services.
        </TourCallout>
      </Flyout>
    </Fragment>
  );
}
