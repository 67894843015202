import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';
import Routes from '../../definitions/routes';
import { AuthStatus, selectStatus as selectAuthStatus } from '../../redux/reducers/auth';
import {
  OnboardingStatus,
  selectDeeplinkAfterSubmission,
  selectStatus as selectOnboardingStatus
} from '../../redux/reducers/onboarding';

const withAuthentication = BaseComponent => {
  const WithAuthenticationComponent = props => {
    if (props.noAuth) {
      return <BaseComponent {...props} />;
    }
    const authStatus = useSelector(selectAuthStatus);
    const onboardingStatus = useSelector(selectOnboardingStatus);
    const matchOnboarding = useRouteMatch('/onboarding');
    const deeplinkAfterOnboarding = useSelector(selectDeeplinkAfterSubmission);
    const currentLocation = useLocation();
    if (authStatus === AuthStatus.Pending || onboardingStatus === OnboardingStatus.Indeterminate) {
      return null; // Wait until auth and onboarding status are known
    } else if (authStatus === AuthStatus.Authenticated) {
      // Check if onboarding is pending and redirect, if necessary
      if (onboardingStatus === OnboardingStatus.Required && matchOnboarding === null) {
        return <Redirect to={Routes.Onboarding()} />;
      }
      // Check if onboarding is completed and redirect from onboarding, if necessary
      if (onboardingStatus === OnboardingStatus.Completed && matchOnboarding) {
        return <Redirect to={deeplinkAfterOnboarding || Routes.Index()} />;
      }
      return <BaseComponent {...props} />;
    }

    // Not authenticated, redirect to evm SSP to retry authentication with deeplink
    const reauthUrl = (process.env.REACT_APP_EVM_SSP || '').replace(
      '{DEEPLINK}',
      deeplinkAfterOnboarding || (currentLocation?.pathname !== '/' ? currentLocation.pathname : '')
    );

    // No reauth set, skip.
    if (!reauthUrl) {
      // eslint-disable-next-line
      console.warn('No reauthentication URL assigned');
      return null;
    }
    return (
      <Route
        render={() => {
          window.location.href = reauthUrl;
          return null;
        }}
      />
    );
  };
  WithAuthenticationComponent.displayName = 'withAuthentication';
  WithAuthenticationComponent.propTypes = {
    noAuth: PropTypes.bool
  };
  return WithAuthenticationComponent;
};

export default withAuthentication;
