import moment from 'moment';
import { apiIdentityRequest, apiRequest } from '../../api/api';
import deeplinkRedirectable from '../../helpers/deeplinkRedirectable';
import { setAuthenticationError, setAuthenticationSuccess } from './auth';
import { setModal } from './modal';
import { fetchOffers } from './offers';
moment.locale('de');

export const OnboardingStatus = {
  Indeterminate: 1,
  Required: 2,
  Completed: 3,
  Error: 4
};

export const OnboardingSubmissionStatus = {
  Idle: 1,
  Submitting: 2,
  Submitted: 3,
  Error: 4
};

const initialState = {
  status: OnboardingStatus.Indeterminate,
  deeplinkAfterSubmission: null,
  prefillLoading: false,
  contracts: [],
  pickedContractIndex: null,
  input: {
    birthday: '',
    telephone: '',
    email: '',
    wantsNewsletter: false,
    allowContact: false,
    householdPeople: 1,
    householdHasChildren: false,
    householdChildren: null,
    interests: []
  },
  submissionStatus: OnboardingSubmissionStatus.Idle
};

export const selectStatus = state => state.onboarding.status;
const ONBOARDING_STATUS_SET = 'onboarding/status/SET';
export const setStatus = status => ({
  type: ONBOARDING_STATUS_SET,
  payload: {
    status
  }
});

export const selectDeeplinkAfterSubmission = state => state.onboarding.deeplinkAfterSubmission;
const ONBOARDING_SET_DEEPLINK_AFTER_SUBMISSION = 'onboarding/deeplinkAfterSubmission/SET';
export const setDeeplinkAfterSubmission = deeplink => ({
  type: ONBOARDING_SET_DEEPLINK_AFTER_SUBMISSION,
  payload: {
    deeplink
  }
});

const ONBOARDING_PREFILL_REQUEST = 'onboarding/prefill/REQUEST';
const requestPrefill = () => ({
  type: ONBOARDING_PREFILL_REQUEST
});
const ONBOARDING_PREFILL_RECEIVE = 'onboarding/prefill/RECEIVE';
const receivePrefill = data => ({
  type: ONBOARDING_PREFILL_RECEIVE,
  payload: data
});
export const getPrefill = token => {
  return dispatch => {
    dispatch(requestPrefill());
    return apiIdentityRequest(token, '/describe')
      .then(response => {
        if (response.status >= 400) {
          throw new Error('Onboarding prefills could not be loaded.');
        }
        return response.json();
      })
      .then(prefillData => dispatch(receivePrefill(prefillData)))
      .catch(() => {
        dispatch(setStatus(OnboardingStatus.Error));
        dispatch(setAuthenticationError());
      });
  };
};
export const selectPrefillLoading = state => state.onboarding.prefillLoading;

export const determineStatus = token => {
  return dispatch => {
    return apiRequest(token, '/onboarding/required')
      .then(response => {
        if (response.status >= 400) {
          throw new Error('Onboarding status could not be determined.');
        }
        return response.json();
      })
      .then(onboardingRequired => {
        dispatch(setAuthenticationSuccess());
        if (onboardingRequired === false) {
          // The customer has completed the onboarding
          dispatch(fetchOffers(token));
          dispatch(setStatus(OnboardingStatus.Completed));
        } else {
          // The customer still needs to complete the onboarding
          dispatch(getPrefill(token));
          dispatch(setStatus(OnboardingStatus.Required));
        }
      })
      .catch(() => {
        dispatch(setStatus(OnboardingStatus.Error));
        dispatch(setAuthenticationError());
      });
  };
};

const ONBOARDING_INPUT = 'onboarding/INPUT';

const getPickedContractValue = (attribute, state) => {
  if (state.onboarding.pickedContractIndex === null) return null;
  if (state.onboarding.contracts?.length < 1) return null;
  if (state.onboarding.pickedContractIndex >= state.onboarding.contracts?.length) return null;
  return state.onboarding.contracts[state.onboarding.pickedContractIndex][attribute];
};

const attributeIsPrefilled = (attribute, state) => {
  const valueFromContract = getPickedContractValue(attribute, state);
  return valueFromContract && valueFromContract !== '';
};

/* CONTRACTS */
const ONBOARDING_PICK_CONTRACT = 'onboarding/contract/pick';
export const selectContracts = state => state.onboarding.contracts;
export const selectPickedContractIndex = state => state.onboarding.pickedContractIndex;
export const selectCanPickContract = state => state.onboarding.contracts?.length > 1;
export const selectPickedContract = state =>
  state.onboarding.contracts[state.onboarding.pickedContractIndex];
export const pickContract = index => ({
  type: ONBOARDING_PICK_CONTRACT,
  payload: {
    index
  }
});

/* DATE OF BIRTH */
export const selectDateOfBirth = state => state.onboarding.input.birthday;
export const selectDateOfBirthEditable = state => !attributeIsPrefilled('birthday', state);
export const setDateOfBirth = birthday => ({
  type: ONBOARDING_INPUT,
  payload: {
    birthday
  }
});

/* PHONE */
export const selectPhone = state => state.onboarding.input.telephone;
export const selectPhoneEditable = state => !attributeIsPrefilled('telephone', state);
export const setPhone = telephone => ({
  type: ONBOARDING_INPUT,
  payload: {
    telephone
  }
});

/* EMAIL */
export const selectEmail = state => state.onboarding.input.email;
export const selectEmailEditable = state => !attributeIsPrefilled('email', state);
export const setEmail = email => ({
  type: ONBOARDING_INPUT,
  payload: {
    email
  }
});

/* CONSENT NEWSLETTER */
export const selectConsentNewsletter = state => state.onboarding.input.wantsNewsletter;
export const selectConsentNewsletterOriginal = state =>
  getPickedContractValue('wantsNewsletter', state);
export const setConsentNewsletter = wantsNewsletter => ({
  type: ONBOARDING_INPUT,
  payload: {
    wantsNewsletter: !!wantsNewsletter
  }
});

/* CONSENT EMAILS */
export const selectConsentEmails = state => state.onboarding.input.allowContact;
export const selectConsentEmailsOriginal = state => getPickedContractValue('allowContact', state);
export const setConsentEmails = allowContact => ({
  type: ONBOARDING_INPUT,
  payload: {
    allowContact: !!allowContact
  }
});

/* PEOPLE IN HOUSEHOLD (AMOUNT) */
export const selectPeopleInHouseholdAmount = state => state.onboarding.input.householdPeople;
export const setPeopleInHouseholdAmount = amount => ({
  type: ONBOARDING_INPUT,
  payload: {
    householdPeople: amount
  }
});

/* KIDS IN HOUSEHOLD (BOOLEAN) */
export const selectKidsInHousehold = state => state.onboarding.input.householdHasChildren;
export const setKidsInHousehold = value => ({
  type: ONBOARDING_INPUT,
  payload: {
    householdHasChildren: !!value
  }
});

/* KIDS IN HOUSEHOLD (AMOUNT) */
export const selectKidsInHouseholdAmount = state => state.onboarding.input.householdChildren;
export const setKidsInHouseholdAmount = amount => ({
  type: ONBOARDING_INPUT,
  payload: {
    householdChildren: amount
  }
});

/* INTERESTS */
const ONBOARDING_TOGGLE_INTEREST = 'onboarding/input/interests/TOGGLE';
export const selectPickedInterests = state => state.onboarding.input.interests;
export const togglePickedInterest = key => ({
  type: ONBOARDING_TOGGLE_INTEREST,
  payload: {
    key
  }
});

/* SUBMISSION */
export const selectSubmissionStatus = state => state.onboarding.submissionStatus;
const ONBOARDING_SUBMISSION_STATUS_SET = 'onboarding/submissionStatus/SET';
export const setSubmissionStatus = submissionStatus => ({
  type: ONBOARDING_SUBMISSION_STATUS_SET,
  payload: {
    submissionStatus
  }
});
export const submit = token => {
  return (dispatch, getState) => {
    dispatch(setSubmissionStatus(OnboardingSubmissionStatus.Submitting));

    // Compile submission data
    const state = getState();
    const contractData = selectPickedContract(state);
    const data = {
      customerId: contractData?.customerId,
      salutation: '',
      firstName: contractData?.firstName,
      lastName: contractData?.lastName,
      email: selectEmail(state) || null,
      birthday: selectDateOfBirth(state) || null,
      telephone: selectPhone(state) || null,
      wantsNewsletter: selectConsentNewsletter(state),
      allowContact: selectConsentEmails(state),
      householdPeople: selectPeopleInHouseholdAmount(state),
      householdHasChildren: selectKidsInHousehold(state),
      householdChildren: selectKidsInHouseholdAmount(state),
      interests: selectPickedInterests(state)
    };

    return apiRequest(token, '/onboarding/update', 'POST', data)
      .then(response => {
        if (response.status === 200) {
          dispatch(setSubmissionStatus(OnboardingSubmissionStatus.Submitted));
        } else {
          throw new Error('Onboarding could not be completed.');
        }
      })
      .catch(() => {
        dispatch(setSubmissionStatus(OnboardingSubmissionStatus.Error));
        dispatch(
          setModal(
            'Registrierung fehlgeschlagen',
            'Es tut uns leid, aber bei Ihrer Registrierung ist ein Fehler aufgetreten. Bitte melden Sie sich erneut im Kundenportal an und versuchen Sie es noch einmal.'
          )
        );
      });
  };
};

export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case ONBOARDING_STATUS_SET: {
      const { status } = action.payload;
      return {
        ...state,
        status
      };
    }

    case ONBOARDING_SET_DEEPLINK_AFTER_SUBMISSION: {
      const { deeplink } = action.payload;
      return {
        ...state,
        deeplinkAfterSubmission: deeplinkRedirectable(deeplink) ? deeplink : null
      };
    }

    case ONBOARDING_PREFILL_REQUEST: {
      return {
        ...state,
        prefillLoading: true
      };
    }

    case ONBOARDING_PREFILL_RECEIVE: {
      const prefillData = action.payload;
      const contracts =
        prefillData?.entries?.map(entry => ({
          firstName: entry.firstName || '',
          lastName: entry.lastName || '',
          address: entry.address || '',
          types: entry.contractTypes || '',
          customerId: entry.customerId || '',
          contractType: entry.contractType || '',
          birthday: entry.birthday || '',
          telephone: entry.telephone || '',
          email: entry.email || '',
          wantsNewsletter: !!entry.wantsNewsletter,
          allowContact: !!entry.allowContact
        })) || [];
      return {
        ...state,
        prefillLoading: false,
        contracts
      };
    }

    case ONBOARDING_PICK_CONTRACT: {
      const { index } = action.payload;
      if (index >= 0 && index < state.contracts.length) {
        const contract = state.contracts[index];
        return {
          ...state,
          pickedContractIndex: index,
          input: {
            ...state.input,
            birthday: contract?.birthday || '',
            telephone: contract?.telephone || '',
            email: contract?.email || '',
            wantsNewsletter: contract?.wantsNewsletter,
            allowContact: contract?.allowContact
          }
        };
      }
      return state;
    }

    case ONBOARDING_INPUT:
      return {
        ...state,
        input: {
          ...state.input,
          ...action.payload
        }
      };

    case ONBOARDING_TOGGLE_INTEREST: {
      const { key } = action.payload;
      const newInterests = [...state.input.interests];
      const interestIndex = newInterests.indexOf(key);
      interestIndex >= 0 ? newInterests.splice(interestIndex, 1) : newInterests.push(key);
      return {
        ...state,
        input: {
          ...state.input,
          interests: newInterests
        }
      };
    }

    case ONBOARDING_SUBMISSION_STATUS_SET: {
      const { submissionStatus } = action.payload;
      return {
        ...state,
        submissionStatus
      };
    }

    default:
      return state;
  }
}
