import { useEffect, useState } from 'react';

export default function useTileDimensions() {
  const [width, setWidth] = useState(367);
  const [gap, setGap] = useState(20);
  const [perPage, setPerPage] = useState(1);

  const calculateWidth = () => {
    if (window.innerWidth >= 1024) {
      setWidth(305);
      setGap(40);
      setPerPage(3);
    } else if (window.innerWidth >= 768) {
      setWidth(325);
      setGap(40);
      setPerPage(2);
    } else if (window.innerWidth >= 576) {
      setWidth(510);
      setGap(20);
      setPerPage(1);
    } else {
      setWidth(window.innerWidth * 0.8);
      setGap(20);
      setPerPage(1);
    }
  };

  useEffect(() => {
    calculateWidth();
    window.addEventListener('resize', calculateWidth);
    return () => window.removeEventListener('resize', calculateWidth);
  }, []);

  return { width, gap, perPage };
}
