import React from 'react';
import PropTypes from 'prop-types';
import './Textbox.scss';
import { NoEdit } from '../../../images/icons';
import Tooltip from '../Tooltip';

const Textbox = ({
  disabled,
  instantValidator,
  name,
  onUpdateValue,
  placeholder,
  readonly,
  required,
  showLabel,
  title,
  tooltip,
  type,
  value
}) => {
  const validation = instantValidator ? instantValidator(value, !required) : true;
  return (
    <div
      className={`Textbox
      ${readonly ? 'Textbox--Readonly' : ''}
      ${disabled ? 'Textbox--Disabled' : ''}
      `}
    >
      {showLabel && (
        <div className='Textbox__Label'>
          <span>{title}</span>
          {tooltip && <Tooltip text={tooltip} />}
        </div>
      )}
      <input
        className='Textbox__Input'
        onChange={event => onUpdateValue(event.currentTarget.value)}
        disabled={readonly || disabled}
        {...{ name, placeholder, title, type, value }}
      />
      <div
        className={`Textbox__Validation ${
          validation !== true ? 'Textbox__Validation--Visible' : ''
        }`}
      >
        {validation}
      </div>
      {readonly && (
        <div className='Textbox__Readonly'>
          <Tooltip text={readonly} size={28} icon={NoEdit} iconSize={14} />
        </div>
      )}
    </div>
  );
};

Textbox.propTypes = {
  disabled: PropTypes.bool,
  instantValidator: PropTypes.func,
  name: PropTypes.string.isRequired,
  onUpdateValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readonly: PropTypes.string,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'tel', 'url']).isRequired,
  value: PropTypes.string.isRequired
};

Textbox.defaultProps = {
  showLabel: true,
  type: 'text',
  value: ''
};

export default Textbox;
