import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import Routes from '../../definitions/routes';
import pageTitle from '../../helpers/pageTitle';
import useGuidParam from '../../hooks/useGuidParam';
import useResources from '../../hooks/useResources';
import { selectCampaign, selectOffer, selectOffersById } from '../../redux/reducers/offers';
import { selectAppReady } from '../../redux/reducers/rootReducer';
import Breadcrumbs from '../atoms/Breadcrumbs';
import Container from '../layout/Container';
import AppTeaser from '../modules/AppTeaser';
import FAQs from '../modules/FAQs';
import OfferBody from '../modules/OfferBody';
import OfferHeader from '../modules/OfferHeader';
import OfferSlider from '../modules/OfferSlider';
import RecommendationProgram from '../modules/RecommendationProgram';
import withAuthentication from '../wrappers/withAuthentication';
import './SingleOffer.scss';

const SingleOffer = ({ previewOffer }) => {
  const guid = useGuidParam();
  const resource = useResources();
  // TODO: Offers selector map returns new object and is not stable.
  const offer = previewOffer ?? useLocation().state ?? useSelector(selectOffer(guid));
  const parentCampaign = useSelector(selectCampaign(offer?.campaign, resource));
  const appReady = useSelector(selectAppReady);
  const relatedOffers = useSelector(selectOffersById(offer?.relatedOfferIds));
  const isPreview = !!previewOffer;

  if (appReady && !offer) {
    // Offer could not be found, redirect to main page
    return <Redirect to={Routes.Index()} />;
  }

  const buildOfferBreadcrumbs = () => {
    const campaignNode = (offer?.campaign === 'standalone' && {
      to: Routes.StandaloneOffers(),
      label: resource('breadcrumbs.offers.standalone')
    }) ||
      (offer?.campaign === 'upcoming' && {
        to: Routes.UpcomingOffers(),
        label: resource('breadcrumbs.offers.upcoming')
      }) || {
        to: Routes.SingleCampaign(parentCampaign?.id),
        label: parentCampaign?.title
      };
    return [campaignNode, { to: Routes.SingleOffer(offer?.id), label: offer?.title }];
  };

  const breadcrumbs = offer ? buildOfferBreadcrumbs() : [];

  return (
    <div className='SingleOffer'>
      {pageTitle(offer?.title)}
      <div className='SingleOffer__Main'>
        {!isPreview && <Breadcrumbs nodes={breadcrumbs} />}
        <OfferHeader offer={offer} isPreview={isPreview} />
        <OfferBody offer={offer} isPreview={isPreview} />
      </div>
      {relatedOffers?.length > 0 && (
        <div className='SingleOffer__Related'>
          <Container>
            <OfferSlider
              title={resource('offer.related.headline')}
              titleSize='BIG'
              offers={relatedOffers}
            />
          </Container>
        </div>
      )}
      {!isPreview && (
        <>
          <AppTeaser />
          <RecommendationProgram />
          <FAQs />
        </>
      )}
    </div>
  );
};

SingleOffer.propTypes = {
  previewOffer: PropTypes.object
};

export default withAuthentication(SingleOffer);
