import React, { createElement, useState } from 'react';
import PropTypes from 'prop-types';
import CircleIcon from './CircleIcon';
import { Help } from '../../images/icons';
import Colors from '../../definitions/colors';
import './Tooltip.scss';
import useOnResize from '../../hooks/useOnResize';
import useDimensions from '../../hooks/useDimensions';

const Tooltip = ({ icon, iconSize, size, text }) => {
  const [visible, setVisible] = useState(false);
  const finalIconSize = iconSize || size * 0.4;
  const [tooltipStyle, setTooltipStyle] = useState({});

  const [refIcon, iconDimensions] = useDimensions();
  const [refTooltip, tooltipDimensions] = useDimensions();

  const iconElement = createElement(icon, { size: finalIconSize, fill: Colors.White });
  const isMobile = window.innerWidth <= 575;

  const styleTooltip = () => {
    const displayOnLeft =
      iconDimensions?.x + iconDimensions?.width + 8 + tooltipDimensions?.width > window.innerWidth;
    const style = {};

    if (displayOnLeft && !isMobile) {
      // Desktop (on left)
      style.right = size + 8;
    } else if (!displayOnLeft && !isMobile) {
      // Desktop (on right)
      style.left = size + 8;
    } else {
      // Mobile (fixed)
      style.top = iconDimensions?.y - tooltipDimensions?.height - 8;
    }

    setTooltipStyle(style);
  };
  useOnResize(styleTooltip);

  return (
    <div
      className='Tooltip'
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className='Tooltip__Icon' ref={refIcon} onClick={() => setVisible(true)}>
        <CircleIcon offsetX={0} offsetY={0} icon={iconElement} size={size} />
      </div>
      <div
        className={`Tooltip__Content ${visible ? 'Tooltip__Content--Visible' : ''}`}
        style={tooltipStyle}
        ref={refTooltip}
      >
        {text}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  icon: PropTypes.any.isRequired,
  iconSize: PropTypes.number,
  size: PropTypes.number.isRequired,
  text: PropTypes.element.isRequired
};

Tooltip.defaultProps = {
  size: 14,
  icon: Help
};

export default Tooltip;
