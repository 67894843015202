import { apiRequest } from '../../api/api';

const initialState = {
  loading: true,
  data: {}
};

export const RESOURCES_REQUEST = 'resources/REQUEST';
const requestResources = () => ({
  type: RESOURCES_REQUEST
});

export const RESOURCES_RECEIVE = 'resources/RECEIVE';
const receiveResources = data => ({
  type: RESOURCES_RECEIVE,
  payload: data
});

export const RESOURCES_FAILURE = 'resources/FAILURE';
const failureResources = message => ({
  type: RESOURCES_FAILURE,
  payload: {
    message
  }
});

export const fetchResources = () => {
  return dispatch => {
    dispatch(requestResources());
    return apiRequest(null, '/resources/1')
      .then(response => {
        if (response.status >= 400) {
          throw new Error('Resources could not be loaded.');
        }
        return response.json();
      })
      .then(data => {
        dispatch(receiveResources(data));
      })
      .catch(error => dispatch(failureResources(error)));
  };
};

export const selectLoading = state => state.resources.loading;
export const selectResources = state => state.resources.data;

export default function resources(state = initialState, action) {
  switch (action.type) {
    case RESOURCES_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case RESOURCES_RECEIVE: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    case RESOURCES_FAILURE: {
      return {
        ...state,
        loading: false,
        data: {}
      };
    }
    default:
      return state;
  }
}
