// Polyfills etc.
import 'react-app-polyfill/ie11';
import 'url-search-params-polyfill';
import 'ie-array-find-polyfill';
import 'polyfill-array-includes';

// Emit build version, if available
const version = process.env.REACT_APP_VERSION;
/* eslint-disable no-console */
version
  ? console.log(`%cVersion ${version}`, 'color:gray')
  : console.log('%cUnknown version', 'color:gray');
/* eslint-enable no-console */

// Main App
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import './index.scss';

// Detect touch device
const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;
document.body.classList.add(`Context__${isTouchDevice ? 'Touch' : 'NoTouch'}`);

const root = createRoot(document.getElementById('root'));
root.render(<App />);
