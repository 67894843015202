import Button from '../../atoms/Button';
import PropTypes from 'prop-types';
import React from 'react';
import './TourCallout.scss';

/**
 * Renders the tour abort callout.
 * @param button The button text.
 * @param onClick Handler on clicking the button.
 * @param head Head alignment (callout triangle).
 * @param children The content.
 * @return {JSX.Element}
 * @constructor
 */
export function TourCalloutAbort({ button, onClick, head = 'center', children }) {
  return (
    <div
      className='TourCallout TourCallout--abort TourCallout--below'
      style={{
        top: 30
      }}
    >
      <div className='TourCallout__Content'>{children}</div>
      <div className='TourCallout__Action'>
        <Button title={button} onClick={onClick} />
      </div>
      <div className={`TourCallout__Head TourCallout__Head--${head}`} />
    </div>
  );
}

TourCalloutAbort.propTypes = {
  button: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  head: PropTypes.oneOf(['start', 'center', 'end']),
  children: PropTypes.node.isRequired
};
