import React, { useEffect } from 'react';
import './Profile.scss';
import { useHistory } from 'react-router-dom';
import pageTitle from '../../helpers/pageTitle';
import withAuthentication from '../wrappers/withAuthentication';
import Container from '../layout/Container';
import IconButton from '../atoms/IconButton';
import Routes from '../../definitions/routes';
import { Left } from '../../images/icons';
import Colors from '../../definitions/colors';
import Button from '../atoms/Button';
import Textbox from '../atoms/inputs/Textbox';
import { validateDate, validateEmail, validatePhone } from '../../helpers/validators';
import BigLabel from '../atoms/inputs/BigLabel';
import NumericPicker from '../atoms/inputs/NumericPicker';
import RadioButtons from '../atoms/inputs/RadioButtons';
import Checkbox from '../atoms/inputs/Checkbox';
import FullwidthCheckbox from '../atoms/inputs/FullwidthCheckbox';
import TextLink from '../atoms/TextLink';
import CircleSpinner from '../atoms/CircleSpinner';
import Tooltip from '../atoms/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../redux/reducers/auth';
import LoadingOverlay from '../atoms/LoadingOverlay';
import {
  loadSettings,
  selectConsentEmails,
  selectConsentEmailsOriginal,
  selectConsentNewsletter,
  selectConsentNewsletterOriginal,
  selectDateOfBirth,
  selectDateOfBirthEditable,
  selectEmail,
  selectEmailEditable,
  selectKidsInHousehold,
  selectKidsInHouseholdAmount,
  selectName,
  selectPeopleInHouseholdAmount,
  selectPhone,
  selectPhoneEditable,
  selectPickedInterests,
  selectStatus,
  setConsentEmails,
  setConsentNewsletter,
  setDateOfBirth,
  setEmail,
  setKidsInHousehold,
  setKidsInHouseholdAmount,
  setPeopleInHouseholdAmount,
  setPhone,
  setStatus,
  SettingsStatus,
  submit,
  togglePickedInterest
} from '../../redux/reducers/settings';
import { selectInterests } from '../../redux/reducers/interests';
import {
  fetchActivations,
  selectActivations,
  selectLoading
} from '../../redux/reducers/activations';
import { formatLongDate, formatTime } from '../../helpers/formatters';
import useResources from '../../hooks/useResources';
import reactStringReplace from 'react-string-replace';

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector(selectToken);
  const status = useSelector(selectStatus);
  const interests = useSelector(selectInterests);
  const resource = useResources();
  const readonlyNotice = resource('readonlyTooltip');

  // Activations
  const activationsLoading = useSelector(selectLoading);
  const activations = useSelector(selectActivations);

  // Values
  const name = useSelector(selectName);
  const dateOfBirth = useSelector(selectDateOfBirth);
  const dateOfBirthEditable = useSelector(selectDateOfBirthEditable);
  const email = useSelector(selectEmail);
  const emailEditable = useSelector(selectEmailEditable);
  const phone = useSelector(selectPhone);
  const phoneEditable = useSelector(selectPhoneEditable);
  const peopleInHouseholdAmount = useSelector(selectPeopleInHouseholdAmount);
  const kidsInHousehold = useSelector(selectKidsInHousehold);
  const kidsInHouseholdAmount = useSelector(selectKidsInHouseholdAmount);
  const consentNewsletter = useSelector(selectConsentNewsletter);
  const consentNewsletterOriginal = useSelector(selectConsentNewsletterOriginal);
  const consentEmails = useSelector(selectConsentEmails);
  const consentEmailsOriginal = useSelector(selectConsentEmailsOriginal);
  const pickedInterests = useSelector(selectPickedInterests);

  const hasKidsOptions = [
    { value: true, label: resource('profile.household.kids.yes') },
    { value: false, label: resource('profile.household.kids.no') }
  ];
  const inputsDisabled = status !== SettingsStatus.Idle;

  useEffect(() => {
    dispatch(loadSettings(token));
    dispatch(fetchActivations(token));
  }, [token, loadSettings, fetchActivations, dispatch]);

  const save = () => {
    dispatch(submit(token));
  };

  // Error and success redirect
  useEffect(() => {
    if (status === SettingsStatus.Saved) {
      const toRedirect = setTimeout(() => {
        history.push(Routes.Index());
      }, 1000);
      const toReset = setTimeout(() => {
        dispatch(setStatus(SettingsStatus.Idle));
      }, 1500);
      return () => {
        clearTimeout(toRedirect);
        clearTimeout(toReset);
      };
    } else if (status === SettingsStatus.Error) {
      dispatch(setStatus(SettingsStatus.Idle));
      history.push(Routes.Index());
    }
  }, [status]);

  const validateKids = (kids, household) => {
    return kids < household || resource('invalid.household');
  };

  const interestsValid = pickedInterests.length > 0;

  const allFieldsValid =
    (!dateOfBirthEditable || validateDate(dateOfBirth, resource, true) === true) &&
    (!emailEditable || validateEmail(email, resource, false) === true) &&
    (!phoneEditable || validatePhone(phone, resource, true) === true) &&
    (!kidsInHousehold || validateKids(kidsInHouseholdAmount, peopleInHouseholdAmount) === true) &&
    interestsValid;

  return (
    <div className='Profile'>
      {pageTitle('Ihr Profil')}
      <LoadingOverlay show={status === SettingsStatus.Loading} />
      <div className='Profile__Main'>
        <Container>
          <div className='Profile__Back'>
            <IconButton
              title={resource('profile.back')}
              showLabel
              iconCircle={{ icon: <Left fill={Colors.White} size={11} />, offsetX: -1 }}
              to={Routes.Index()}
            />
          </div>
          <div className='Profile__PersonalData'>
            <div className='Profile__Information'>
              <h2 className='Profile__Headline'>{resource('profile.data.headline')}</h2>
              <p className='Profile__Text'>{resource('profile.data.intro')}</p>
              <Textbox
                title={resource('profile.data.name.label')}
                name='name'
                value={name}
                readonly={readonlyNotice}
                onUpdateValue={() => {}}
              />
              <Textbox
                title={resource('profile.data.birthday.label')}
                name='dateOfBirth'
                value={dateOfBirth}
                onUpdateValue={val => dispatch(setDateOfBirth(val))}
                instantValidator={(value, required) => validateDate(value, resource, required)}
                placeholder={resource('profile.data.birthday.placeholder')}
                disabled={inputsDisabled}
                readonly={!dateOfBirthEditable ? readonlyNotice : ''}
              />
              <Textbox
                title={`${resource('profile.data.email.label')} *`}
                name='email'
                value={email}
                onUpdateValue={val => dispatch(setEmail(val))}
                instantValidator={(value, required) => validateEmail(value, resource, required)}
                disabled={inputsDisabled}
                readonly={!emailEditable ? readonlyNotice : ''}
              />
              <Textbox
                title={resource('profile.data.phone.label')}
                name='phone'
                value={phone}
                onUpdateValue={val => dispatch(setPhone(val))}
                disabled={inputsDisabled}
                instantValidator={(value, required) => validatePhone(value, resource, required)}
                readonly={!phoneEditable ? readonlyNotice : ''}
              />
              <BigLabel>{resource('profile.household.persons.headline')}</BigLabel>
              <NumericPicker
                title={resource('profile.household.persons.label')}
                showLabel={false}
                value={peopleInHouseholdAmount}
                min={1}
                onUpdateValue={val => dispatch(setPeopleInHouseholdAmount(val))}
                disabled={inputsDisabled}
                valueDisplay={n =>
                  `${n} ${
                    n !== 1
                      ? resource('profile.household.persons.label.plural')
                      : resource('profile.household.persons.label.singular')
                  }`
                }
              />
              <BigLabel>{resource('profile.household.kids.headline')}</BigLabel>
              <RadioButtons
                onUpdateValue={val => dispatch(setKidsInHousehold(val))}
                value={kidsInHousehold}
                options={hasKidsOptions}
                disabled={inputsDisabled}
              />
              {kidsInHousehold && (
                <>
                  <BigLabel>{resource('profile.household.kidsAmount.headline')}</BigLabel>
                  <NumericPicker
                    disabled={!kidsInHousehold || inputsDisabled}
                    title={resource('profile.household.kidsAmount.label')}
                    showLabel={false}
                    value={kidsInHouseholdAmount}
                    min={1}
                    nullable
                    onUpdateValue={val => dispatch(setKidsInHouseholdAmount(val))}
                    valueDisplay={n =>
                      `${n} ${
                        n !== 1
                          ? resource('profile.household.kidsAmount.label.plural')
                          : resource('profile.household.kidsAmount.label.singular')
                      }`
                    }
                    instantValidator={val => validateKids(val, peopleInHouseholdAmount)}
                  />
                </>
              )}
              {(!consentNewsletterOriginal || !consentEmailsOriginal) && (
                <BigLabel>{resource('profile.contact.email.headline')}</BigLabel>
              )}
              {consentNewsletterOriginal ? null : (
                <Checkbox
                  value={consentNewsletter}
                  onUpdateValue={val => dispatch(setConsentNewsletter(val))}
                  disabled={inputsDisabled}
                >
                  {reactStringReplace(
                    resource('profile.contact.newsletter.checkbox'),
                    '{privacyLink}',
                    (_, index) => (
                      <TextLink
                        key={index}
                        title={resource('profile.contact.newsletter.privacyLink.text')}
                        target='_blank'
                        href={resource('profile.contact.newsletter.privacyLink.url')}
                      />
                    )
                  )}
                </Checkbox>
              )}
              {consentEmailsOriginal ? null : (
                <Checkbox
                  value={consentEmails}
                  onUpdateValue={val => dispatch(setConsentEmails(val))}
                  disabled={inputsDisabled}
                >
                  {resource('profile.contact.optIn.checkbox')}
                  <div className='Profile__Tooltip'>
                    <Tooltip text={resource('profile.contact.optIn.tooltip')} size={20} />
                  </div>
                </Checkbox>
              )}
            </div>
            <div className='Profile__Interests'>
              <h2 className='Profile__Headline'>{resource('profile.interests.headline')}</h2>
              <p className='Profile__Text'>{resource('profile.interests.intro')}</p>
              {interests.map(interest => (
                <FullwidthCheckbox
                  key={interest.name}
                  label={interest.label}
                  disabled={inputsDisabled}
                  onUpdateValue={() => dispatch(togglePickedInterest(interest.name))}
                  value={pickedInterests && pickedInterests.includes(interest.name)}
                />
              ))}
              {interestsValid ? null : (
                <p className='Profile__InterestsInvalid'>{resource('profile.interests.invalid')}</p>
              )}
            </div>
          </div>
          <div className='Profile__SavePersonalData'>
            {status === SettingsStatus.Saving || status === SettingsStatus.Saved ? (
              <CircleSpinner size={48} complete={status === SettingsStatus.Saved} />
            ) : (
              <>
                {!allFieldsValid && (
                  <div className='Profile__Validation'>{resource('profile.invalid')}</div>
                )}
                <Button
                  title={resource('profile.save')}
                  disabled={!allFieldsValid}
                  onClick={save}
                />
              </>
            )}
          </div>
        </Container>
      </div>
      <div className='Profile__Activations'>
        <Container>
          <h2 className='Profile__Headline'>{resource('profile.history.headline')}</h2>
          {activationsLoading || !activations
            ? null
            : activations.map((activation, index) => (
                <div className='Profile__Activation' key={index}>
                  <div className='Profile__ActivationPartner'>{activation.partnerName}</div>
                  <h3 className='Profile__ActivationOffer'>{activation.offerName}</h3>
                  <p className='Profile__ActivationDetails'>
                    {resource('profile.history.activation', {
                      date: formatLongDate(activation.at),
                      time: formatTime(activation.at)
                    })}
                  </p>
                </div>
              ))}
        </Container>
      </div>
    </div>
  );
};

export default withAuthentication(Profile);
