import React, { memo } from 'react';
import PropTypes from 'prop-types';
import WysiwygStyling from './WysiwygStyling';
import ReactMarkdown from 'react-markdown';

const TextRenderer = memo(({ content: { value, type }, plainTextClassName }) => {
  const addBreaks = text =>
    text
      .split(/\r\n|\r|\n/)
      .map(part => part.trim())
      .map((part, i) => (
        <React.Fragment key={i}>
          {part}
          <br />
        </React.Fragment>
      ));

  switch (type) {
    case 'text/html':
      return <WysiwygStyling html={value} />;
    case 'text/markdown':
      return (
        <WysiwygStyling>
          <ReactMarkdown>{value}</ReactMarkdown>
        </WysiwygStyling>
      );
    default:
      return plainTextClassName ? (
        <div className={plainTextClassName}>{addBreaks(value)}</div>
      ) : (
        addBreaks(value)
      );
  }
});
TextRenderer.displayName = 'TextRenderer';
TextRenderer.propTypes = {
  content: PropTypes.shape({
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    richMedia: PropTypes.array
  }).isRequired,
  plainTextClassName: PropTypes.string
};

export default TextRenderer;
