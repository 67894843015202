import React from 'react';
import { BrowserRouter } from 'react-router-dom';

const withBrowserRouter = BaseComponent => {
  const WithBrowserRouterComponent = props => {
    return (
      <BrowserRouter>
        <BaseComponent {...props} />
      </BrowserRouter>
    );
  };
  WithBrowserRouterComponent.displayName = 'withBrowserRouter';
  return WithBrowserRouterComponent;
};

export default withBrowserRouter;
