import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AuthStatus, selectStatus as selectAuthStatus } from '../../redux/reducers/auth';
import {
  OnboardingStatus,
  selectStatus as selectOnboardingStatus
} from '../../redux/reducers/onboarding';
import './MenuButton.scss';
import { select } from '../../helpers/handlers';

const MenuButton = ({ onClick, title, onlyAuthenticated, badge, active, width }) => {
  // Auth status
  const authStatus = useSelector(selectAuthStatus);
  const onboardingStatus = useSelector(selectOnboardingStatus);
  const showItem = useMemo(
    () =>
      !onlyAuthenticated ||
      (authStatus === AuthStatus.Authenticated && onboardingStatus === OnboardingStatus.Completed),
    [authStatus, onboardingStatus, onlyAuthenticated]
  );

  return !showItem ? null : (
    <div
      role='button'
      tabIndex={0}
      onKeyDown={select(onClick)}
      onClick={select(onClick)}
      title={title}
      style={width ? { width } : undefined}
      className={['MenuButton', active && 'MenuButton--Active'].filter(Boolean).join(' ')}
    >
      {title}
      {badge >= 1 && <span className='MenuButton__Badge'>{badge}</span>}
    </div>
  );
};

MenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onlyAuthenticated: PropTypes.bool,
  badge: PropTypes.number,
  active: PropTypes.string,
  width: PropTypes.number
};

export default MenuButton;
