import { useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function useOnResize(callback, element = document.body) {
  useEffect(() => {
    if (element) {
      const ro = new ResizeObserver(callback);
      ro.observe(element);
      return () => ro.unobserve(element);
    }
  }, [callback, element]);
}
