import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { useSelector } from 'react-redux';
import { selectToken } from '../redux/reducers/auth';
import useResources from './useResources';

export default function useRedeem(offerId, type = 'qr', amount = 1) {
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const token = useSelector(selectToken);
  const resource = useResources();

  useEffect(() => {
    if (offerId) {
      setBusy(true);
      const endpoint =
        (type === 'qr' && `/redeem/initiate/qr/${offerId}`) ||
        (type === 'shopify' && `/redeem/initiate/shopify/${offerId}?amount=${amount}`) ||
        (type === 'form' && `/redeem/initiate/form/${offerId}`) ||
        (type === 'externalMg' && `/redeem/activate/external/mg/${offerId}`) ||
        undefined;
      apiRequest(token, endpoint, 'POST')
        .then(response => {
          if (response.status === 200) {
            // OK returns parseable data.
            return response.json();
          } else if (response.status === 202) {
            // Accepted returns no data, just marked as status.
            return true;
          } else {
            throw new Error(resource('offer.redeemError.message'));
          }
        })
        .then(data => {
          setData(data);
          setError(data.failure);
          setBusy(false);
        })
        .catch(e => {
          setData(null);
          setError(e.message);
          setBusy(false);
        });
    } else {
      // No offer to redeem, reset everything
      setBusy(false);
      setData(null);
      setError(null);
    }
  }, [offerId]);

  return [busy, data, error];
}
