import { LocationSelect, LocationSelected, locationSet } from '../atoms/LocationSelect';
import { useSelector } from 'react-redux';
import { selectInterests } from '../../redux/reducers/interests';
import { interestIcons } from '../../definitions/interestIcons';
import Colors from '../../definitions/colors';
import { categoriesSet, CategorySelect } from '../atoms/CategorySelect';
import { apiRequest } from '../../api/api';
import React, { useRef, useCallback, createElement, useEffect, useMemo } from 'react';
import { selectToken } from '../../redux/reducers/auth';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import { select } from '../../helpers/handlers';
import './IndexFilter.scss';
import { useSessionState } from '../../hooks/useSessionState';

/**
 * True if filter value is an error.
 * @param value The value to check.
 * @return {boolean}
 */
export const isFilterError = value => value instanceof Error;

/**
 * True if filter value is an empty result.
 * @param value The value to check.
 * @return {boolean}
 */
export const isFilterEmpty = value => Array.isArray(value) && value.length === 0;

/**
 * True if filter value is a non-empty result.
 * @param value The value to check.
 * @return {boolean}
 */
export const isFilterResults = value => Array.isArray(value) && value.length !== 0;

/**
 * Executes remote filtering with the given parameters.
 * @param categories The categories to filter, an array of keys.
 * @param location The location filter object.
 * @param token The authentication token.
 * @param signal Abort signal.
 * @return {Promise<*[]|null>} Returns a promise on either the result offers or null.
 */
const executeFilter = async (categories, location, token, signal) => {
  // No search parameters, no operation.
  if (!categoriesSet(categories) && !locationSet(location)) return null;

  // Build search parameters.
  const urlSearch = new URLSearchParams();

  // Add parameters from selection.
  if (location?.zip) urlSearch.append('zip', location.zip);
  if (location?.name) urlSearch.append('location', location.name);
  if (location?.latitude) urlSearch.append('latitude', location.latitude);
  if (location?.longitude) urlSearch.append('longitude', location.longitude);
  if (location?.distance) urlSearch.append('distance', location.distance);
  for (const category of categories) urlSearch.append('interests', category);

  // Compose endpoint, use personalized search if token is assigned.
  const endpoint = token
    ? `/search?${urlSearch.toString()}`
    : `/search/public?${urlSearch.toString()}`;

  // Get response with signal.
  const response = await apiRequest(
    token,
    endpoint,
    'GET',
    null,
    process.env.REACT_APP_API_INTERACT,
    false,
    signal
  );

  // Read data.
  const data = await response.json();

  // Transform and return.
  return data.offers;
};

export const IndexFilter = ({ setLoading, setResults }) => {
  // All category options selected from the resolved list from the API.
  const allInterests = useSelector(selectInterests);
  const allCategories = useMemo(() => {
    return allInterests.map(interest => ({
      value: interest.name,
      label: interest.label,
      icon: createElement(interestIcons[interest.name], {
        size: 20,
        fill: Colors.Orange
      })
    }));
  }, [allInterests]);

  // Use the auth token.
  const token = useSelector(selectToken);

  // Filter state and filter parameters.
  const [categories, setCategories] = useSessionState('IndexFilter_categories', []);
  const [location, setLocation] = useSessionState('IndexFilter_location', null);

  /**
   * Cross-call abort controller to stop requests when new requests come in.
   * @type {React.MutableRefObject<AbortController>}
   */
  const controller = useRef();

  // Perform filter with the parameters.
  const filterFor = useCallback((categories, location, token) => {
    // Abort last operation.
    controller.current?.abort();

    // Propagation stop flag.
    let propagate = true;

    // Create new abort controller and connect stop flag.
    controller.current = new AbortController();
    controller.current.signal.addEventListener('abort', () => (propagate = false));

    // Activate loading.
    setLoading(true);

    // Do filter. Connect conditionally with propagation stop flag.
    executeFilter(categories, location, token, controller.current.signal)
      .then(
        results => propagate && setResults(results),
        error => propagate && setResults(error)
      )
      .finally(() => propagate && setLoading(false));
  }, []);

  // Handle find from button press.
  const handleFind = useCallback(() => {
    // Always use given values and filter for them.
    filterFor(categories, location, token);
  }, [categories, location, token]);

  // Handle category update and automatic filtering.
  const updateCategories = useCallback(
    value => {
      // Set categories and filter for new value.
      setCategories(value);
      filterFor(value, location, token);
    },
    [location, token]
  );

  // Handle location update and automatic filtering on location selection.
  const updateLocation = useCallback(
    (value, source) => {
      // Set location.
      setLocation(value);

      // If setting from clear or from pinning location, filter for new value.
      if (source === LocationSelected.fromClear || source === LocationSelected.fromCoordinate) {
        filterFor(categories, value, token);
      }
    },
    [categories, token]
  );

  // Connect initial filtering, this is used when the state is restored from storage.
  useEffect(() => {
    filterFor(categories, location, token);
  }, []);

  return (
    <div className='IndexFilter'>
      {/* Category selection input. */}
      <CategorySelect
        className='IndexFilter__Categories'
        options={allCategories}
        categories={categories}
        setCategories={updateCategories}
      />

      {/* Main spacing between categories and location. */}
      <div className='IndexFilter__Spacer' />

      {/* Location selection input. */}
      <LocationSelect
        className='IndexFilter__Locations'
        location={location}
        setLocation={updateLocation}
        onClear={handleFind}
        onConfirm={handleFind}
      />

      {/* Direct find via button. */}
      <div className='IndexFilter__Find'>
        <Button title='Finden' onClick={select(handleFind)} onKeyPress={select(handleFind)} />
      </div>
    </div>
  );
};

IndexFilter.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setResults: PropTypes.func.isRequired
};
