import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import { Down } from '../../images/icons';
import './AccordionItem.scss';

const AccordionItem = ({ question, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={`AccordionItem ${open ? 'AccordionItem--Open' : ''}`}>
      <button className='AccordionItem__Toggle' onClick={() => setOpen(!open)}>
        <div className='AccordionItem__Question'>{question}</div>
        <div className={`AccordionItem__Caret ${open ? 'AccordionItem__Caret--Flipped' : ''}`}>
          <Down fill='#000000' size={15} />
        </div>
      </button>
      <AnimateHeight
        height={open ? 'auto' : 0}
        duration={500}
        contentClassName='AccordionItem__Content'
      >
        {children}
      </AnimateHeight>
    </div>
  );
};

AccordionItem.propTypes = {
  question: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default AccordionItem;
