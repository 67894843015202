import { useEffect } from 'react';

export function useClickOutside(ref, handler) {
  const handleClick = event => {
    if (!ref.current.contains(event.target)) {
      handler();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [ref, handler]);
}
