import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Routes from '../../definitions/routes';
import pageTitle from '../../helpers/pageTitle';
import useGuidParam from '../../hooks/useGuidParam';
import useResources from '../../hooks/useResources';
import { selectCampaign } from '../../redux/reducers/offers';
import { selectAppReady } from '../../redux/reducers/rootReducer';
import Breadcrumbs from '../atoms/Breadcrumbs';
import Container from '../layout/Container';
import AppTeaser from '../modules/AppTeaser';
import CampaignHero from '../modules/CampaignHero';
import FAQs from '../modules/FAQs';
import OfferGrid from '../modules/OfferGrid';
import RecommendationProgram from '../modules/RecommendationProgram';
import withAuthentication from '../wrappers/withAuthentication';
import './SingleCampaign.scss';

const SingleCampaign = () => {
  const guid = useGuidParam();
  const resource = useResources();
  const campaign = useSelector(selectCampaign(guid, resource));
  const appReady = useSelector(selectAppReady);
  const offersGrid = useRef(null);

  if (appReady && !campaign) {
    // Campaign could not be found, redirect to main page
    return <Redirect to={Routes.Index()} />;
  }

  const breadcrumbs = campaign ? [{ to: Routes.SingleCampaign(guid), label: campaign?.title }] : [];
  return (
    <div className='SingleCampaign'>
      {pageTitle(campaign?.title)}
      <div className='SingleCampaign__Main'>
        <Breadcrumbs nodes={breadcrumbs} />
        <CampaignHero
          campaign={campaign}
          onScrollToOffers={() => offersGrid.current.scrollIntoView({ behavior: 'smooth' })}
        />
        <div className='SingleCampaign__Offers' ref={offersGrid}>
          <Container>{campaign?.offers && <OfferGrid offers={campaign.offers} />}</Container>
        </div>
      </div>
      <AppTeaser />
      <RecommendationProgram />
      <FAQs />
    </div>
  );
};

export default withAuthentication(SingleCampaign);
