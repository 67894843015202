import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import './RedeemQrCode.scss';
import useRedeem from '../../hooks/useRedeem';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../redux/reducers/modal';
import QrCode from './QrCode';
import scrollToTop from '../../helpers/scrollToTop';
import useValid from '../../hooks/useValid';
import CircleSpinner from '../atoms/CircleSpinner';
import OfferNotValid from '../atoms/OfferNotValid';
import useResources from '../../hooks/useResources';
import BookmarkButton from './BookmarkButton';
import { Box } from '../layout/Box';
import { selectTourAt, tourNext, TourState } from '../../redux/reducers/tour';
import { TourRedeem } from './tour/TourRedeem';
import { TourPdf } from './tour/TourPdf';
import { TourBookmarking } from './tour/TourBookmarking';
import { selectOfferIsBookmarked } from '../../redux/reducers/bookmarks';

const RedeemQrCode = ({ offer }) => {
  const dispatch = useDispatch();
  const resource = useResources();

  const at = useSelector(selectTourAt);
  const atRedeemGeneral = at === TourState.aboutRedeemGeneral;
  const atRedeemThis = at === TourState.aboutRedeemThis;
  const atPdf = at === TourState.aboutPdf;
  const atBookmarking = at === TourState.aboutBookmarking;
  const atQrCode = at === TourState.aboutQrCode;
  const alreadyBookmarked = useSelector(selectOfferIsBookmarked(offer.id));

  // Check validity
  const [validBusy, valid, invalidMessage, allowBookmark] = useValid(offer, 'qr');

  // For QR code display
  const [idToRedeemQr, setIdToRedeemQr] = useState(null);
  const [redeemQrBusy, redeemQrResponse, redeemQrError] = useRedeem(idToRedeemQr, 'qr');

  // For PDF download
  const [idToRedeemPdf, setIdToRedeemPdf] = useState(null);
  const [redeemPdfBusy, redeemPdfResponse, redeemPdfError] = useRedeem(idToRedeemPdf, 'qr');
  const [pdfWindowRef, setPdfWindowRef] = useState(null);

  if (!offer.definition?.qrCode) {
    return null;
  }
  const redeemButton =
    offer.definition?.qrCode?.buttonRedeemUsedName || resource('offer.qrCode.redeemButton');

  // Initiate pdf request
  const redeemPdf = () => {
    setPdfWindowRef(window.open()); // This is necessary to circumvent safari popup blocker
    setIdToRedeemPdf(offer?.id);
  };

  // Open pdf in new tab and then reset
  useEffect(() => {
    if (redeemPdfResponse?.pdf?.location) {
      if (pdfWindowRef) {
        pdfWindowRef.location = redeemPdfResponse.pdf.location;
      }
      setIdToRedeemPdf(null);
      setPdfWindowRef(null);
    }
  }, [redeemPdfResponse?.pdf?.location]);

  // Scroll to top when QR code is shown
  useEffect(() => {
    if (redeemQrResponse?.failure === null) {
      scrollToTop();
    }
  }, [redeemQrResponse?.failure]);

  // Show errors, if any occurred, and then reset
  useEffect(() => {
    if (redeemQrError) {
      scrollToTop();
      dispatch(setModal(resource('offer.redeemError.title'), redeemQrError));
      setIdToRedeemQr(null);
    }
  }, [redeemQrError]);
  useEffect(() => {
    if (redeemPdfError) {
      scrollToTop();
      dispatch(setModal(resource('offer.redeemError.title'), redeemPdfError));
      setIdToRedeemPdf(null);
    }
  }, [redeemPdfError]);

  // Set QR code to redeem if at QR code tour step but the ID is not set.
  useEffect(() => {
    if (atQrCode && !idToRedeemQr) setIdToRedeemQr(offer?.id);
  }, [atQrCode, idToRedeemQr, offer?.id]);

  return (
    <div className='RedeemQrCode'>
      <div
        className={`RedeemQrCode__Busy ${
          validBusy || redeemQrBusy || redeemPdfBusy ? 'RedeemQrCode__Busy--Visible' : ''
        }`}
      >
        <CircleSpinner size={40} />
      </div>
      {!valid ? null : (
        <>
          <Box
            active={atRedeemGeneral || atRedeemThis}
            disabled={atRedeemGeneral}
            className='RedeemQrCode__Redeem'
          >
            <Button
              title={redeemButton}
              disabled={redeemQrBusy}
              fullWidth
              onClick={() => {
                if (atRedeemThis) dispatch(tourNext());
                setIdToRedeemQr(offer?.id);
              }}
            />
            <TourRedeem />
          </Box>
          <Box active={atPdf} disabled className='RedeemQrCode__Print'>
            <Button
              title={resource('offer.qrCode.printButton')}
              disabled={redeemPdfBusy}
              fullWidth
              onClick={redeemPdf}
            />
            <TourPdf />
          </Box>
          <QrCode
            visible={redeemQrResponse?.failure === null}
            onClose={() => setIdToRedeemQr(null)}
            title={offer?.title}
            partner={offer?.partner}
            alias={redeemQrResponse?.alias}
            qrCodeLocation={redeemQrResponse?.qrCode?.location}
          />
        </>
      )}
      {valid || validBusy ? null : <OfferNotValid message={invalidMessage} />}

      <Box active={atBookmarking} disabled={alreadyBookmarked}>
        <BookmarkButton
          bookmarkable={allowBookmark}
          offerId={offer.id}
          onToggle={() => {
            if (atBookmarking) dispatch(tourNext());
          }}
        />
        <TourBookmarking alreadyBookmarked={alreadyBookmarked} />
      </Box>
    </div>
  );
};

RedeemQrCode.propTypes = {
  offer: PropTypes.any
};

export default RedeemQrCode;
