import { Close } from '../../../images/icons';
import Colors from '../../../definitions/colors';
import Button from '../../atoms/Button';
import PropTypes from 'prop-types';
import React from 'react';
import './TourCallout.scss';

/**
 * Renders a tour callout.
 * @param button The button text.
 * @param onNext Handler on clicking next.
 * @param onClose Handler on closing.
 * @param stepAt The step index.
 * @param stepTotal The total number of steps.
 * @param location The location of the callout (above or below).
 * @param head Head alignment (callout triangle).
 * @param distance The extra distance to add between callout and target.
 * @param children The content.
 * @return {JSX.Element}
 * @constructor
 */
export function TourCallout({
  button,
  onNext,
  onClose,
  stepAt,
  stepTotal,
  location = 'above',
  head = 'center',
  distance = 25,
  children
}) {
  return (
    <div
      className={`TourCallout TourCallout--${location}`}
      style={{
        bottom: location === 'above' ? distance : undefined,
        top: location === 'below' ? distance : undefined
      }}
    >
      <div className='TourCallout__Content'>{children}</div>
      <button className='TourCallout__Close' title='Close' onClick={onClose}>
        <Close size={15} fill={Colors.Orange} />
      </button>
      <div className='TourCallout__Action'>
        <span>
          Tipp {stepAt} von {stepTotal}
        </span>
        {!onNext ? null : <Button slim title={button} onClick={onNext} />}
      </div>
      <div className={`TourCallout__Head TourCallout__Head--${head}`} />
    </div>
  );
}

TourCallout.propTypes = {
  button: PropTypes.string,
  onNext: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  stepAt: PropTypes.number.isRequired,
  stepTotal: PropTypes.number.isRequired,
  location: PropTypes.oneOf(['above', 'below']),
  head: PropTypes.oneOf(['start', 'center', 'end']),
  distance: PropTypes.number,
  children: PropTypes.node.isRequired
};
