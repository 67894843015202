import { useSelector } from 'react-redux';
import { selectCampaigns } from '../redux/reducers/offers';
import { useMemo } from 'react';

/**
 * Uses the target campaign of the tour (about current offers).
 * @return {{id:string}|null} Returns the campaign (relevant ID).
 */
export const useTourTargetCampaign = () => {
  const campaigns = useSelector(selectCampaigns);
  return useMemo(() => {
    if (!campaigns) return null;

    for (const campaign of campaigns) {
      for (const offer of campaign.offers) {
        if (offer.definition.qrCode) {
          return campaign;
        }
      }
    }
    return null;
  }, [campaigns]);
};

/**
 * Uses the target evm campaign of the tour (about evm offers).
 * @return {{id:string}|null} Returns the campaign (relevant ID).
 */
export const useTourTargetEvmCampaign = () => {
  const campaigns = useSelector(selectCampaigns);
  return useMemo(() => {
    return (
      campaigns?.find(campaign => campaign.id === process.env.REACT_APP_EVM_CAMPAIGN_ID) ?? null
    );
  }, [campaigns]);
};

/**
 * Uses the target offer of the tour (about single or any detail).
 * @return {{id:string}|null} Returns the offer (relevant ID).
 */
export const useTourTargetOffer = () => {
  const campaigns = useSelector(selectCampaigns);
  return useMemo(() => {
    if (!campaigns) return null;

    for (const campaign of campaigns) {
      for (const offer of campaign.offers) {
        if (offer.definition.qrCode) {
          return offer;
        }
      }
    }
    return null;
  }, [campaigns]);
};
