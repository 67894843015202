import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourAbort, TourState } from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import { matchPath } from 'react-router-dom';
import Routes from '../../../definitions/routes';
import { Blinds } from '../../atoms/Blinds';
import { Flyout } from '../../layout/Flyout';
import { TourCallout } from './TourCallout';
import { Scroller } from '../../atoms/Scroller';

export function TourOneOffer() {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutOneOffer;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => dispatch(tourAbort()), [dispatch]);
  useHistoryListener(
    location => {
      if (active && !matchPath(location.pathname, Routes.SingleOffer())) {
        dispatch(tourAbort('navigated'));
      }
    },
    [active, dispatch]
  );

  if (skip) return null;
  return (
    <Fragment>
      <Blinds active={active} inset={0} radius={0} />
      <Flyout active={active}>
        <TourCallout stepAt={2} stepTotal={12} distance={15} onClose={handleAbort}>
          <Scroller active={active} />
          Klicke auf diesen Vorteil, um mehr darüber zu erfahren.
        </TourCallout>
      </Flyout>
    </Fragment>
  );
}
