import React, { useEffect, useState } from 'react';
import './OnboardingWizard.scss';
import OnboardingNavigation from './onboarding/OnboardingNavigation';
import OnboardingPickContract from './onboarding/steps/OnboardingPickContract';
import OnboardingPersonalData from './onboarding/steps/OnboardingPersonalData';
import OnboardingEmail from './onboarding/steps/OnboardingEmail';
import OnboardingHousehold from './onboarding/steps/OnboardingHousehold';
import OnboardingInterests from './onboarding/steps/OnboardingInterests';
import Button from '../atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import CircleSpinner from '../atoms/CircleSpinner';
import {
  determineStatus,
  OnboardingStatus,
  OnboardingSubmissionStatus,
  pickContract,
  selectCanPickContract,
  selectPickedContractIndex,
  selectPrefillLoading,
  selectStatus,
  selectSubmissionStatus,
  submit
} from '../../redux/reducers/onboarding';
import { selectToken } from '../../redux/reducers/auth';
import useResources from '../../hooks/useResources';

const OnboardingWizard = () => {
  const token = useSelector(selectToken);
  const [step, setStep] = useState(1);
  const submissionStatus = useSelector(selectSubmissionStatus);
  const dispatch = useDispatch();
  const resource = useResources();

  const [currentStepValid, setCurrentStepValid] = useState(false);

  // Auto-pick contract if only one is available
  const prefillLoading = useSelector(selectPrefillLoading);
  const canPickContract = useSelector(selectCanPickContract);
  const pickedContractIndex = useSelector(selectPickedContractIndex);
  const status = useSelector(selectStatus);
  useEffect(() => {
    if (
      status === OnboardingStatus.Required &&
      !prefillLoading &&
      !pickedContractIndex &&
      !canPickContract
    ) {
      dispatch(pickContract(0));
    }
  }, [status, prefillLoading, pickedContractIndex, canPickContract, dispatch, pickContract]);

  const questions = [
    canPickContract && <OnboardingPickContract key={1} onValidate={setCurrentStepValid} />,
    <OnboardingPersonalData key={2} onValidate={setCurrentStepValid} />,
    <OnboardingEmail key={3} onValidate={setCurrentStepValid} />,
    <OnboardingHousehold key={4} onValidate={setCurrentStepValid} />,
    <OnboardingInterests key={5} onValidate={setCurrentStepValid} />
  ].filter(Boolean);

  const canGoBack = step > 1;
  const goBack = () => (canGoBack ? setStep(step - 1) : undefined);

  const canGoNext = currentStepValid && step < questions.length;
  const canSubmit = currentStepValid && step === questions.length;
  const goNext = () => {
    if (canGoNext) {
      setStep(step + 1);
    } else if (canSubmit) {
      dispatch(submit(token));
    }
  };

  useEffect(() => {
    if (submissionStatus === OnboardingSubmissionStatus.Submitted) {
      // Redirect after submission by re-determining the onboarding status
      const to = setTimeout(() => dispatch(determineStatus(token)), 2500);
      return () => clearTimeout(to);
    }
  }, [submissionStatus]);

  return (
    <div className='OnboardingWizard'>
      <div className='OnboardingWizard__Wrapper'>
        <div
          className={`OnboardingWizard__LoadingOverlay
          ${
            submissionStatus === OnboardingSubmissionStatus.Submitting ||
            submissionStatus === OnboardingSubmissionStatus.Submitted
              ? 'OnboardingWizard__LoadingOverlay--Visible'
              : ''
          }
          `}
        >
          <CircleSpinner
            size={100}
            complete={submissionStatus === OnboardingSubmissionStatus.Submitted}
          />
        </div>
        <div className='OnboardingWizard__Header'>
          <OnboardingNavigation
            currentStep={step}
            maxSteps={questions.length}
            canGoNext={canGoNext}
            onGoNext={goNext}
            canGoBack={canGoBack}
            onGoBack={goBack}
          />
        </div>
        {questions[step - 1]}
        <div className='OnboardingWizard__Footer'>
          <Button
            title={
              step === questions.length
                ? resource('onboarding.navigation.submit')
                : resource('onboarding.navigation.next')
            }
            disabled={!canGoNext && !canSubmit}
            onClick={goNext}
          />
        </div>
      </div>
      <div className='OnboardingWizard__RequiredRemark'>{resource('onboarding.footnote')}</div>
    </div>
  );
};

export default OnboardingWizard;
