import React from 'react';
import './Onboarding.scss';
import withAuthentication from '../wrappers/withAuthentication';
import Container from '../layout/Container';
import OnboardingWizard from '../modules/OnboardingWizard';
import Hero from '../atoms/Hero';
import useResources from '../../hooks/useResources';

const Onboarding = () => {
  const resource = useResources();
  return (
    <div className='Onboarding'>
      <Hero>
        <h1 className='Onboarding__Title'>{resource('onboarding.hero.headline')}</h1>
        <h4 className='Onboarding__Intro'>{resource('onboarding.hero.text')}</h4>
      </Hero>
      <div className='Onboarding__Wizard'>
        <Container>
          <OnboardingWizard />
        </Container>
      </div>
    </div>
  );
};

export default withAuthentication(Onboarding);
