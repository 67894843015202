import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../definitions/routes';
import useResources from '../../hooks/useResources';
import Container from '../layout/Container';
import './Breadcrumbs.scss';

const Breadcrumbs = ({ nodes }) => {
  const resource = useResources();
  const baseNode = {
    to: Routes.Index(),
    label: resource('breadcrumbs.home')
  };
  return (
    <div className='Breadcrumbs'>
      <Container>
        <div className='Breadcrumbs__Main'>
          {[baseNode, ...nodes].map((node, index) => (
            <div className='Breadcrumbs__Node' key={index}>
              <Link className='Breadcrumbs__Link' to={node.to} title={node.label}>
                {node.label}
              </Link>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

Breadcrumbs.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired
  )
};

export default Breadcrumbs;
