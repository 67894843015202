import React from 'react';
import { useParams } from 'react-router-dom';
import usePreview from '../../hooks/usePreview';
import useResources from '../../hooks/useResources';
import LoadingOverlay from '../atoms/LoadingOverlay';
import Container from '../layout/Container';
import './OfferPreview.scss';
import SingleOffer from './SingleOffer';

const OfferPreview = () => {
  const { alias } = useParams();
  const resource = useResources();
  const [loading, offerData, error] = usePreview(alias);
  return (
    <div className='OfferPreview'>
      <LoadingOverlay show={loading} />
      {error && (
        <Container>
          <div className='OfferPreview__Error'>
            <h2 className='OfferPreview__ErrorTitle'>{resource('preview.error.title')}</h2>
            <p className='OfferPreview__ErrorText'>{resource('preview.error.text')}</p>
          </div>
        </Container>
      )}
      {offerData && <SingleOffer previewOffer={offerData} noAuth />}
    </div>
  );
};

export default OfferPreview;
