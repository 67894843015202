import { useEffect, useState } from 'react';
import { apiRequest } from '../api/api';

const usePreview = alias => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const loading = !data && !error;

  useEffect(() => {
    if (alias) {
      apiRequest(
        undefined,
        `/previews/public/${alias}`,
        'GET',
        undefined,
        process.env.REACT_APP_API_MANAGE
      )
        .then(res => res.json())
        .then(setData)
        .catch(setError);
    }
  }, [apiRequest, alias, setData, setError]);

  return [loading, data, error];
};

export default usePreview;
