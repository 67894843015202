import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export default function useQueryVar(key) {
  const location = useLocation();
  return useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.has(key) ? params.get(key) : null;
  }, [location, key]);
}
