import React from 'react';
import PropTypes from 'prop-types';
import Container from '../layout/Container';
import './OfferHeader.scss';
import OfferPanel from './OfferPanel';
import IconButton from '../atoms/IconButton';
import Colors from '../../definitions/colors';
import { Left } from '../../images/icons';
import { useHistory } from 'react-router-dom';
import useResources from '../../hooks/useResources';

const OfferHeader = ({ offer, isPreview }) => {
  const history = useHistory();
  const resource = useResources();
  return (
    <div className={`OfferHeader ${!offer ? 'OfferHeader--Dummy' : ''}`}>
      <div className='OfferHeader__Wrapper'>
        {!isPreview && offer && (
          <div className='OfferHeader__Back'>
            <Container>
              <IconButton
                title={resource('offer.back')}
                onClick={history.goBack}
                color={Colors.White}
                showLabel
                labelPosition='RIGHT'
                iconCircle={{
                  icon: <Left fill={Colors.Orange} size={11} />,
                  offsetX: -1,
                  color: Colors.White
                }}
              />
            </Container>
          </div>
        )}
        {offer && (
          <div className='OfferHeader__Content'>
            <Container>
              <h1 className='OfferHeader__Title'>{offer.title}</h1>
              <h4 className='OfferHeader__Excerpt'>{offer.excerpt}</h4>
            </Container>
          </div>
        )}
      </div>
      {offer && (
        <Container>
          <div className='OfferHeader__Panel'>
            <OfferPanel offer={offer} isPreview={isPreview} />
          </div>
        </Container>
      )}
    </div>
  );
};

OfferHeader.propTypes = {
  offer: PropTypes.any,
  isPreview: PropTypes.bool
};

export default OfferHeader;
