import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Renders a link if given active. Otherwise renders a div with the incompatible props removed.
 * @type {React.NamedExoticComponent<{readonly onClick?: *, readonly link?: *, readonly to?: *}>}
 */
export const OptLink = memo(({ link, to, onClick, ...props }) => {
  return !link ? <div {...props} /> : <Link to={to} onClick={onClick} {...props} />;
});

OptLink.displayName = 'OptLink';
OptLink.propTypes = {
  link: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func
};
