import React, { useEffect } from 'react';
import OnboardingQuestion from '../OnboardingQuestion';
import NumericPicker from '../../../atoms/inputs/NumericPicker';
import './OnboardingHousehold.scss';
import RadioButtons from '../../../atoms/inputs/RadioButtons';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectKidsInHousehold,
  selectKidsInHouseholdAmount,
  selectPeopleInHouseholdAmount,
  setKidsInHousehold,
  setKidsInHouseholdAmount,
  setPeopleInHouseholdAmount
} from '../../../../redux/reducers/onboarding';
import PropTypes from 'prop-types';
import useResources from '../../../../hooks/useResources';

const OnboardingHousehold = ({ onValidate }) => {
  const peopleInHouseholdAmount = useSelector(selectPeopleInHouseholdAmount);
  const kidsInHousehold = useSelector(selectKidsInHousehold);
  const kidsInHouseholdAmount = useSelector(selectKidsInHouseholdAmount);
  const dispatch = useDispatch();
  const resource = useResources();

  const kidsInHouseholdOptions = [
    { value: true, label: resource('onboarding.household.kids.yes') },
    { value: false, label: resource('onboarding.household.kids.no') }
  ];

  const validateKids = (kids, household) => {
    return kids < household || resource('invalid.household');
  };

  useEffect(() => {
    onValidate(
      !kidsInHousehold || validateKids(kidsInHouseholdAmount, peopleInHouseholdAmount) === true
    );
  }, [kidsInHousehold, kidsInHouseholdAmount, peopleInHouseholdAmount, onValidate]);

  return (
    <>
      <OnboardingQuestion question={`${resource('onboarding.household.persons.headline')} *`}>
        <p className='OnboardingHousehold__Intro'>
          {resource('onboarding.household.persons.text')}
        </p>
        <div className='OnboardingHousehold__Input'>
          <NumericPicker
            name='people'
            title={resource('onboarding.household.persons.label')}
            showLabel={false}
            value={peopleInHouseholdAmount}
            min={1}
            max={5}
            onUpdateValue={value => dispatch(setPeopleInHouseholdAmount(value))}
            valueDisplay={n =>
              `${n} ${
                n !== 1
                  ? resource('onboarding.household.persons.label.plural')
                  : resource('onboarding.household.persons.label.singular')
              }`
            }
          />
        </div>
      </OnboardingQuestion>
      <OnboardingQuestion question={`${resource('onboarding.household.kids.headline')} *`}>
        <p className='OnboardingHousehold__Intro'>{resource('onboarding.household.kids.text')}</p>
        <div className='OnboardingHousehold__Input'>
          <RadioButtons
            onUpdateValue={value => dispatch(setKidsInHousehold(value))}
            value={kidsInHousehold}
            options={kidsInHouseholdOptions}
            noMargin
          />
        </div>
      </OnboardingQuestion>
      {kidsInHousehold && (
        <OnboardingQuestion question={resource('onboarding.household.kidsAmount.headline')}>
          <div className='OnboardingHousehold__Input'>
            <NumericPicker
              name='kids'
              title={resource('onboarding.household.kidsAmount.label')}
              showLabel={false}
              value={kidsInHouseholdAmount}
              min={1}
              nullable
              onUpdateValue={value => dispatch(setKidsInHouseholdAmount(value))}
              valueDisplay={n =>
                `${n} ${
                  n !== 1
                    ? resource('onboarding.household.kidsAmount.label.plural')
                    : resource('onboarding.household.kidsAmount.label.singular')
                }`
              }
              instantValidator={val => validateKids(val, peopleInHouseholdAmount)}
            />
          </div>
        </OnboardingQuestion>
      )}
    </>
  );
};

OnboardingHousehold.propTypes = {
  onValidate: PropTypes.func.isRequired
};

export default OnboardingHousehold;
