import moment from 'moment';
import 'moment/locale/de';

moment.locale('de');

export const offerValidityInfo = (offer, resource) => {
  if (!offer) {
    return null;
  }
  const dateFormat = 'DD.MM.YYYY';

  const from = moment(offer.validFrom);
  const until = moment(offer.validUntil);
  const now = moment();

  if (offer.validFrom && from > now) {
    return resource('offerValidity.validFrom', { from: from.format(dateFormat) });
  } else if (offer.validUntil) {
    return resource('offerValidity.validUntil', { until: until.format(dateFormat) });
  }

  // No date limitation
  return resource('offerValidity.unlimited');
};

export const formatDate = date => {
  if (!date) {
    return null;
  }
  return moment(date).format('DD.MM.YYYY');
};

export const formatLongDate = date => {
  if (!date) {
    return null;
  }
  return moment(date).format('dddd, DD.MM.YYYY');
};

export const formatTime = date => {
  if (!date) {
    return null;
  }
  return moment(date).format('HH:mm');
};

export const sanitizeWebUrl = url => {
  return /^https?:\/\/(.)*/.test(url) ? url : `https://${url}`;
};

export const sanitizePhoneUrl = phone => {
  return `tel:${phone.replace(/[^0-9]/g, '')}`;
};

export const contractTypeLabel = (types, resource) => {
  const gas = types.includes('G');
  const water = types.includes('W');
  const energy = types.includes('E');
  const [p0, p1, ...pRest] = [
    gas && resource('energyType.gas'),
    water && resource('energyType.water'),
    energy && resource('energyType.electricity')
  ].filter(Boolean);

  if (0 < pRest?.length) return [p0, p1, ...pRest].join(', ');
  if (p1) return `${p0} ${resource('energyType.and')} ${p1}`;
  return p0;
};

export const formatSiteLocality = site => {
  return site.address.split(',')[1];
};

export const formatSiteAddress = site => {
  const [streetName, locality] = site.address.split(',');
  return `${site.name}\r\n${streetName}\r\n${locality}`;
};

export const formatDistance = distance => {
  return distance < 1
    ? `${Math.round(distance * 1000)} m entfernt`
    : `${(Math.round(distance * 10) / 10).toString().replace('.', ',')} km entfernt`;
};
