import { useSelector } from 'react-redux';
import { selectResources, selectLoading } from '../redux/reducers/resources';
import defaultResources from '../definitions/defaultResources.json';
import { useCallback } from 'react';

const logSeen = new Set();

const logMissing = (fullKey, inFallback) => {
  // Check if already logged, if so return, otherwise add to seen.
  if (logSeen.has(fullKey)) return;
  logSeen.add(fullKey);

  // Log for case.
  if (inFallback) {
    // eslint-disable-next-line no-console
    console.log(
      `%cResource ${fullKey} is not provided by API and does not exist in fallback file`,
      'color: red'
    );
  } else {
    // eslint-disable-next-line no-console
    console.log(`Resource ${fullKey} is not provided by API`);
  }
};

/* Returns a helper function that yields resource texts based on the key */
export default function useResources(namespace = 'portal') {
  const resources = useSelector(selectResources);
  const loading = useSelector(selectLoading);
  return useCallback(
    (key, replacements = {}) => {
      // Find resource value
      const fullKey = `${namespace}.${key}`;
      let value = fullKey;
      if (Object.prototype.hasOwnProperty.call(resources, fullKey)) {
        // Search resources from API
        value = resources[fullKey];
      } else if (Object.prototype.hasOwnProperty.call(defaultResources, fullKey)) {
        // Search default resources from local file
        value = defaultResources[fullKey];
        if (!loading) {
          logMissing(fullKey, false);
        }
      } else {
        logMissing(fullKey, true);
      }

      // Run replacements
      for (const [placeholder, replaceWith] of Object.entries(replacements)) {
        value = value.replaceAll(`{${placeholder}}`, replaceWith);
      }

      return value;
    },
    [resources, loading, namespace]
  );
}
