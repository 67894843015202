import React from 'react';
import './Blinds.scss';
import PropTypes from 'prop-types';

/**
 * Renders a square outline with the middle cut out. Animates in and out with opacity.
 * @param active True if active.
 * @param insetLeft Extra inset on the left.
 * @param insetTop Extra inset on the top.
 * @param insetRight Extra inset on the right.
 * @param insetBottom Extra inset on the bottom.
 * @param inset Extra inset on all sides if not given explicitly.
 * @return {JSX.Element}
 * @constructor
 */
export const Blinds = ({ active, insetLeft, insetTop, insetRight, insetBottom, inset = 25 }) => {
  return (
    <div
      className={active ? 'Blinds Blinds--active' : 'Blinds'}
      style={{
        left: -(insetLeft ?? inset),
        top: -(insetTop ?? inset),
        right: -(insetRight ?? inset),
        bottom: -(insetBottom ?? inset)
      }}
    />
  );
};

Blinds.propTypes = {
  active: PropTypes.bool,
  insetLeft: PropTypes.number,
  insetTop: PropTypes.number,
  insetRight: PropTypes.number,
  insetBottom: PropTypes.number,
  inset: PropTypes.number
};
