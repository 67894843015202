import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../atoms/IconButton';
import { Left, Right } from '../../images/icons';
import Colors from '../../definitions/colors';
import OfferTile from './OfferTile';
import './OfferSlider.scss';
import useTileDimensions from '../../hooks/useTileDimensions';
import { useSwipeable } from 'react-swipeable';
import useResources from '../../hooks/useResources';
import { Box } from '../layout/Box';
import { useSelector } from 'react-redux';
import { selectTourAt, TourState } from '../../redux/reducers/tour';
import { TourCurrentOffers } from './tour/TourCurrentOffers';
import { useTourTargetOffer } from '../../hooks/useTourTargets';
import { TourEvmOffers } from './tour/TourEvmOffers';

const OfferSlider = ({ title, showAll, offers, titleSize, tour, tourEvm }) => {
  const tourOffer = useTourTargetOffer();
  const at = useSelector(selectTourAt);
  const atCurrentOffers = at === TourState.aboutCurrentOffers;
  const atEvmOffers = at === TourState.aboutEvmOffers;

  const [index, setIndex] = useState(0);
  const { width, gap, perPage } = useTileDimensions();
  const resource = useResources();

  const canGoRight = () => {
    return index < offers.length - perPage;
  };

  const canGoLeft = () => {
    return index > 0;
  };

  const goRight = () => {
    canGoRight() && setIndex(index + 1);
  };

  const goLeft = () => {
    canGoLeft() && setIndex(index - 1);
  };

  const swipeHandlers = useSwipeable({
    onSwipedRight: goLeft,
    onSwipedLeft: goRight,
    delta: 50,
    preventDefaultTouchmoveEvent: true
  });

  // React to changes of tiles per page, change calculation if necessary
  useEffect(() => {
    if (index >= offers.length - perPage && offers.length > perPage) {
      setIndex(offers.length - perPage);
    }
  }, [index, perPage, offers.length]);

  const headlineElement =
    titleSize === 'BIG' ? (
      <h2 className='OfferSlider__Headline OfferSlider__Headline--Big'>{title}</h2>
    ) : (
      <h3 className='OfferSlider__Headline OfferSlider__Headline--Small'>{title}</h3>
    );

  return (
    <Box
      active={(atCurrentOffers && tour) || (atEvmOffers && tourEvm)}
      disabled
      className={`OfferSlider ${!showAll ? 'OfferSlider--OnlyArrows' : ''}`}
    >
      <div className='OfferSlider__Header'>
        {headlineElement}
        <div className={`OfferSlider__Navigation`}>
          {showAll}
          {(canGoLeft() || canGoRight()) && (
            <div className='OfferSlider__Arrows'>
              <div className='OfferSlider__Arrow OfferSlider__Arrow--Left'>
                <IconButton
                  onClick={goLeft}
                  disabled={!canGoLeft()}
                  title={resource('offers.back')}
                  iconCircle={{ icon: <Left fill={Colors.White} size={11} />, offsetX: -1 }}
                />
              </div>
              <div className='OfferSlider__Arrow OfferSlider__Arrow--Right'>
                <IconButton
                  onClick={goRight}
                  disabled={!canGoRight()}
                  title={resource('offers.next')}
                  iconCircle={{ icon: <Right fill={Colors.White} size={11} />, offsetX: 1 }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className='OfferSlider__Viewport'
        style={{ margin: `0 ${gap / -2}px`, width: `calc(100% + ${gap}px` }}
        {...swipeHandlers}
      >
        <div
          className='OfferSlider__Shifter'
          style={{ marginLeft: `${index * (width + gap) * -1}px` }}
        >
          {offers.map((offer, index) => (
            <OfferTile
              key={index}
              offer={offer}
              context='SLIDER'
              tour={tour && tourOffer?.id === offer.id}
            />
          ))}
        </div>
      </div>
      {!tour ? null : <TourCurrentOffers />}
      {!tourEvm ? null : <TourEvmOffers />}
    </Box>
  );
};

OfferSlider.propTypes = {
  title: PropTypes.string,
  titleSize: PropTypes.oneOf(['BIG', 'SMALL']).isRequired,
  showAll: PropTypes.element,
  offers: PropTypes.arrayOf(PropTypes.object),
  tour: PropTypes.bool,
  tourEvm: PropTypes.bool
};

OfferSlider.defaultProps = {
  titleSize: 'SMALL'
};

export default OfferSlider;
