import React, { useEffect } from 'react';
import OnboardingQuestion from '../OnboardingQuestion';
import { useDispatch, useSelector } from 'react-redux';
import ContractSelectItem from '../../../atoms/inputs/ContractSelectItem';
import {
  selectContracts,
  selectPickedContractIndex,
  pickContract
} from '../../../../redux/reducers/onboarding';
import PropTypes from 'prop-types';
import useResources from '../../../../hooks/useResources';

const OnboardingPickContract = ({ onValidate }) => {
  const dispatch = useDispatch();
  const contracts = useSelector(selectContracts);
  const pickedContractIndex = useSelector(selectPickedContractIndex);
  const resource = useResources();

  useEffect(() => {
    onValidate(pickedContractIndex !== null && pickedContractIndex < contracts.length);
  }, [pickedContractIndex, contracts, onValidate]);

  const pickContractIndex = index => {
    if (pickedContractIndex === index) {
      // Remove current selection
      dispatch(pickContract(null));
    } else {
      // Pick selected contract
      dispatch(pickContract(index));
    }
  };

  return (
    <OnboardingQuestion question={resource('onboarding.selectContract.headline')} fullWidth>
      {contracts.map((contract, index) => (
        <ContractSelectItem
          key={index}
          contract={contract}
          picked={index === pickedContractIndex}
          onPick={() => pickContractIndex(index)}
        />
      ))}
    </OnboardingQuestion>
  );
};

OnboardingPickContract.propTypes = {
  onValidate: PropTypes.func.isRequired
};

export default OnboardingPickContract;
