import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourAbort, tourNext, TourState } from '../../../redux/reducers/tour';
import React, { useCallback } from 'react';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import { Flyout } from '../../layout/Flyout';
import { TourCallout } from './TourCallout';
import PropTypes from 'prop-types';

export function TourQrCode({ onAbort, onNext }) {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutQrCode;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => {
    onAbort();
    dispatch(tourAbort());
  }, [dispatch]);
  const handleNext = useCallback(() => {
    onNext();
    dispatch(tourNext());
  }, [dispatch, onNext]);
  useHistoryListener(() => {
    if (active) dispatch(tourAbort('navigated'));
  }, [active, dispatch]);
  if (skip) return null;
  return (
    <Flyout active={active}>
      <TourCallout
        button='Weiter'
        stepAt={6}
        stepTotal={12}
        distance={20}
        onNext={handleNext}
        onClose={handleAbort}
      >
        Unser Partner wird diesen QR-Code vor Ort scannen. Alternativ kannst Du ihm auch den
        zehnstelligen Code vorlesen, um Deinen Vorteil einzulösen.
      </TourCallout>
    </Flyout>
  );
}

TourQrCode.propTypes = {
  onAbort: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};
