import { apiRequest } from '../../api/api';

const initialState = {
  loading: false,
  data: []
};

export const ACTIVATIONS_REQUEST = 'activations/REQUEST';
const requestActivations = () => ({
  type: ACTIVATIONS_REQUEST
});

export const ACTIVATIONS_RECEIVE = 'activations/RECEIVE';
const receiveActivations = data => ({
  type: ACTIVATIONS_RECEIVE,
  payload: data
});

export const ACTIVATIONS_FAILURE = 'activations/FAILURE';
const failureActivations = message => ({
  type: ACTIVATIONS_FAILURE,
  payload: {
    message
  }
});

export const fetchActivations = token => {
  return dispatch => {
    dispatch(requestActivations());
    return apiRequest(token, '/activations/own')
      .then(response => {
        if (response.status >= 400) {
          throw new Error('Own activations could not be loaded.');
        }
        return response.json();
      })
      .then(data => {
        dispatch(receiveActivations(data));
      })
      .catch(error => dispatch(failureActivations(error)));
  };
};

export const selectLoading = state => state.activations.loading;
export const selectActivations = state => state.activations.data;

export default function activations(state = initialState, action) {
  switch (action.type) {
    case ACTIVATIONS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTIVATIONS_RECEIVE: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    case ACTIVATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        data: []
      };
    }
    default:
      return state;
  }
}
