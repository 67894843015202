import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Overlay from '../atoms/Overlay';
import './QrCode.scss';
import useResources from '../../hooks/useResources';
import { Box } from '../layout/Box';
import { useSelector } from 'react-redux';
import { selectTourAt, TourState } from '../../redux/reducers/tour';
import { TourQrCode } from './tour/TourQrCode';

const QrCode = ({ alias, onClose, partner, qrCodeLocation, title, visible }) => {
  const at = useSelector(selectTourAt);
  const atQrCode = at === TourState.aboutQrCode;
  const extraSpace = at === TourState.aboutQrCode || at === TourState.aboutPdf;

  const [closing, setClosing] = useState(false);
  const resource = useResources();

  const closeModal = () => {
    setClosing(true);
  };

  useEffect(() => {
    if (closing) {
      const to = setTimeout(onClose, 500);
      return () => clearTimeout(to);
    }
  }, [closing]);

  useEffect(() => {
    setClosing(false);
  }, [visible]);

  // Render overlay. If the QR code is part of the tour at the moment, add extra space to make the
  // flyout visible.
  return (
    <Overlay
      visible={visible && !closing}
      onClose={closeModal}
      showCloseButton={true}
      windowStyle={extraSpace ? { marginTop: 180 } : undefined}
    >
      {/* Box is elevated and blocks input when part of the tour. */}
      <Box active={atQrCode} disabled className='QrCode'>
        {partner && <div className='QrCode__Partner'>{partner}</div>}
        <h3 className='QrCode__Title'>{title}</h3>
        <div className='QrCode__Columns'>
          <div className='QrCode__Code'>
            <img
              src={qrCodeLocation}
              alt={resource('offer.qrCode.alt')}
              className='QrCode__Image'
            />
          </div>
          <div className='QrCode__Alias'>
            <div className='QrCode__TipWrapper'>
              <div className='QrCode__Tip'>{resource('offer.qrCode.help')}</div>
            </div>
            <div className='QrCode__AliasCodeWrapper'>
              <div className='QrCode__AliasCode'>{alias}</div>
            </div>
          </div>
        </div>
      </Box>

      {/* Extra flyout content. */}
      <TourQrCode onAbort={closeModal} onNext={closeModal} />
    </Overlay>
  );
};

QrCode.propTypes = {
  alias: PropTypes.string,
  onClose: PropTypes.func,
  partner: PropTypes.string,
  qrCodeLocation: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool
};

export default QrCode;
