import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import IconButton from './IconButton';
import { Left, Right } from '../../images/icons';
import Colors from '../../definitions/colors';
import './PictureGallery.scss';
import { preloadImages } from '../../helpers/preloadImages';
import { useSwipeable } from 'react-swipeable';
import useResources from '../../hooks/useResources';

const PictureGallery = ({ sources, altText }) => {
  const [index, setIndex] = useState(0);
  const [goingRight, setGoingRight] = useState(true);
  const [preloading, setPreloading] = useState(true);
  const resource = useResources();

  // Preload images in gallery
  useEffect(() => {
    let active = true;
    setPreloading(true);
    preloadImages(sources).then(() => {
      if (active) setPreloading(false);
    });
    return () => {
      active = false;
    };
  }, [sources]);

  const goRight = () => {
    setGoingRight(true);
    setIndex(index < sources.length - 1 ? index + 1 : 0);
  };

  const goLeft = () => {
    setGoingRight(false);
    setIndex(index > 0 ? index - 1 : sources.length - 1);
  };

  const swipeHandlers = useSwipeable({
    onSwipedRight: goLeft,
    onSwipedLeft: goRight,
    delta: 50,
    preventScrollOnSwipe: true
  });

  const transitionClassName = () =>
    `PictureGallery__Transition PictureGallery__Transition--${goingRight ? 'Right' : 'Left'}`;

  const childFactoryCreator = classNames => child => React.cloneElement(child, { classNames });

  return (
    <div className='PictureGallery' {...swipeHandlers}>
      <div
        className={`PictureGallery__Buttons ${
          sources.length > 1 && !preloading ? 'PictureGallery__Buttons--Active' : ''
        }`}
      >
        <div className='PictureGallery__Button'>
          <IconButton
            onClick={goLeft}
            title={resource('pictureGallery.left')}
            iconCircle={{ icon: <Left fill={Colors.White} size={11} />, offsetX: -1 }}
          />
        </div>
        <div className='PictureGallery__Button'>
          <IconButton
            onClick={goRight}
            title={resource('pictureGallery.right')}
            iconCircle={{ icon: <Right fill={Colors.White} size={11} />, offsetX: 1 }}
          />
        </div>
      </div>
      {!sources || sources.length < 1 ? null : (
        <TransitionGroup component={null} childFactory={childFactoryCreator(transitionClassName())}>
          <CSSTransition classNames={transitionClassName()} timeout={400} key={index}>
            <img
              src={sources[index]}
              alt={`${altText} (${index + 1})`}
              className='PictureGallery__Image'
            />
          </CSSTransition>
        </TransitionGroup>
      )}
    </div>
  );
};

PictureGallery.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  altText: PropTypes.string.isRequired
};

export default PictureGallery;
