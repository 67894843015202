import React from 'react';
import PropTypes from 'prop-types';
import './CircleSpinner.scss';

const CircleSpinner = ({ className, complete, size, ...props }) => {
  return (
    <div className={`CircleSpinner ${className}`} {...props}>
      <svg version='1.1' viewBox='0 0 100 100' width={size} height={size}>
        <circle
          cx={50}
          cy={50}
          r={48}
          fill='none'
          className={`CircleSpinner__SpinningCircle
          ${complete ? 'CircleSpinner__SpinningCircle--Complete' : ''}
          `}
        />
        <circle
          cx={50}
          cy={50}
          r={48}
          fill='none'
          className={`CircleSpinner__DoneCircle
          ${complete ? 'CircleSpinner__DoneCircle--Complete' : ''}
          `}
        />
        <path
          d='M25 50 L45 70 L80 35'
          fill='none'
          className={`CircleSpinner__Tick ${complete ? 'CircleSpinner__Tick--Complete' : ''}`}
        />
      </svg>
    </div>
  );
};

CircleSpinner.propTypes = {
  className: PropTypes.string,
  complete: PropTypes.bool,
  size: PropTypes.number
};

CircleSpinner.defaultProps = {
  size: 100
};

export default CircleSpinner;
