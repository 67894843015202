import { useEffect, useState } from 'react';

/**
 * Debounces the value, delaying it by a certain time.
 * @template T
 * @param {T} value The value to debounce.
 * @param {number} timeout The time to delay it.
 * @return {T}
 */
export const useDebounce = (value, timeout = 100) => {
  const [state, setState] = useState(value);
  useEffect(() => {
    const handle = setTimeout(() => setState(value), timeout);
    return () => clearTimeout(handle);
  }, [value, timeout]);

  return state;
};

/**
 * Debounces the value conditionally, delaying it by a certain time if a condition is met.
 * @template T
 * @param {T} value The value to debounce.
 * @param {number} timeout The time to delay it.
 * @param {function(T):boolean} on Returns true if the target value should be debounced. If false, the value is set
 * immediately.
 * @return {T}
 */
export const useEdgeDebounce = (value, timeout = 100, on = x => !!x) => {
  const [state, setState] = useState(value);
  useEffect(() => {
    if (on(value)) {
      // Debounce active, set timeout.
      const handle = setTimeout(() => setState(value), timeout);
      return () => clearTimeout(handle);
    } else {
      // Debounce inactive, set immediately.
      setState(value);
    }
  }, [value, timeout]);

  return state;
};
