import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import './RedeemShopify.scss';
import useRedeem from '../../hooks/useRedeem';
import { useDispatch } from 'react-redux';
import { setModal } from '../../redux/reducers/modal';
import NumericPicker from '../atoms/inputs/NumericPicker';
import scrollToTop from '../../helpers/scrollToTop';
import useValid from '../../hooks/useValid';
import CircleSpinner from '../atoms/CircleSpinner';
import OfferNotValid from '../atoms/OfferNotValid';
import Overlay from '../atoms/Overlay';
import useResources from '../../hooks/useResources';
import BookmarkButton from './BookmarkButton';
import TextLink from '../atoms/TextLink';
import Routes from '../../definitions/routes';

const RedeemShopify = ({ offer }) => {
  const dispatch = useDispatch();
  const resource = useResources();
  const [amount, setAmount] = useState(1);

  // Check validity
  const [validBusy, valid, invalidMessage, allowBookmark, linkToCampaign] = useValid(
    offer,
    'shopify'
  );

  // For shopify redirect
  const [idToRedeem, setIdToRedeem] = useState(null);
  const [redeemBusy, redeemResponse, redeemError] = useRedeem(idToRedeem, 'shopify', amount);
  const [redirectModalUrl, setRedirectModalUrl] = useState(null);

  if (!offer.definition?.shopify) {
    return null;
  }
  const redeemButton =
    offer.definition?.shopify?.buttonRedeemUsedName || resource('offer.shopify.redeemButton');

  // Open shop deeplink in new tab and then reset
  useEffect(() => {
    if (redeemResponse?.url) {
      scrollToTop();
      setRedirectModalUrl(redeemResponse?.url);
      setIdToRedeem(null);
      setAmount(1);
    }
  }, [redeemResponse?.url]);

  const redeem = () => setIdToRedeem(offer?.id);

  // Show errors, if any occurred, and then reset
  useEffect(() => {
    if (redeemError) {
      scrollToTop();
      dispatch(setModal(resource('offer.redeemError.title'), redeemError));
      setIdToRedeem(null);
    }
  }, [redeemError]);
  return (
    <div className='RedeemShopify'>
      <div
        className={`RedeemShopify__Busy ${
          validBusy || redeemBusy ? 'RedeemShopify__Busy--Visible' : ''
        }`}
      >
        <CircleSpinner size={40} />
      </div>
      {!valid ? null : (
        <>
          <div className='RedeemShopify__Picker'>
            <NumericPicker
              title={resource('offer.shopify.picker.label')}
              showLabel
              onUpdateValue={setAmount}
              value={amount}
              valueDisplay={val =>
                `${val} ${
                  val !== 1
                    ? resource('offer.shopify.picker.label.plural')
                    : resource('offer.shopify.picker.label.singular')
                }`
              }
              min={1}
              max={offer.definition?.shopify?.productLimit}
            />
          </div>
          <div className='RedeemShopify__Submit'>
            <Button title={redeemButton} disabled={redeemBusy} fullWidth onClick={redeem} />
          </div>
        </>
      )}
      {valid || validBusy ? null : (
        <OfferNotValid
          message={
            <>
              {invalidMessage}{' '}
              {!linkToCampaign ? null : (
                <TextLink
                  inline
                  to={Routes.SingleCampaign(offer.campaign)}
                  title={resource('offer.validityCheck.linkToCampaign')}
                />
              )}
            </>
          }
        />
      )}
      <BookmarkButton bookmarkable={allowBookmark} offerId={offer.id} />
      <Overlay
        visible={!!redirectModalUrl}
        onClose={() => setRedirectModalUrl(null)}
        showCloseButton
      >
        <h3 className='RedeemShopify__ModalTitle'>{resource('offer.shopify.redirect.title')}</h3>
        <p className='RedeemShopify__ModalMessage'>{resource('offer.shopify.redirect.text')}</p>
        <div className='RedeemShopify__ModalButton'>
          <Button
            title={resource('offer.shopify.redirect.button')}
            onClick={() => {
              window.open(redirectModalUrl);
              setRedirectModalUrl(null);
            }}
          />
        </div>
      </Overlay>
    </div>
  );
};

RedeemShopify.propTypes = {
  offer: PropTypes.any
};

export default RedeemShopify;
