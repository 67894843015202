const cachedNormal = new WeakMap();

/**
 * Converts an event handler for click or key selection to normalized behavior.
 * @param {function((KeyboardEvent|MouseEvent)): void} fn
 * @return {function((KeyboardEvent|MouseEvent)): void}
 */
export function select(fn) {
  // No function, ignore.
  if (!fn) return fn;

  // Try get and return existing value for stability.
  const existing = cachedNormal.get(fn);
  if (existing !== undefined) return existing;

  /**
   * @param {KeyboardEvent|MouseEvent} event
   */
  const created = event => {
    switch (event.type) {
      case 'keydown': {
        // No controls.
        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) return;

        // On enter or space, key down runs on all keys.
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          event.stopPropagation();
          return fn(event);
        }

        return;
      }

      case 'click': {
        // No controls.
        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) return;

        // Run handler, click implies logical selection click.
        event.preventDefault();
        event.stopPropagation();
        return fn(event);
      }

      default:
        return;
    }
  };

  // Return new function.
  cachedNormal.set(fn, created);
  return created;
}
