import { apiRequest } from '../../api/api';
import moment from 'moment';
import { setModal } from './modal';
moment.locale('de');

const initialInput = {
  birthday: '',
  telephone: '',
  email: '',
  wantsNewsletter: false,
  allowContact: false,
  householdPeople: 1,
  householdHasChildren: false,
  householdChildren: null,
  interests: []
};

export const SettingsStatus = {
  Idle: 1,
  Loading: 2,
  Saving: 3,
  Saved: 4,
  Error: 5
};

const initialState = {
  current: initialInput, // This will be populated by the API
  input: initialInput, // This is what will be sent back to the API on update
  status: SettingsStatus.Idle
};

export const selectStatus = state => state.settings.status;
const SETTINGS_STATUS_SET = 'settings/status/SET';
export const setStatus = status => ({
  type: SETTINGS_STATUS_SET,
  payload: {
    status
  }
});

/* LOADING */
const SETTINGS_RECEIVE = 'settings/RECEIVE';
const receiveSettings = data => ({
  type: SETTINGS_RECEIVE,
  payload: data
});
export const loadSettings = token => {
  return dispatch => {
    dispatch(setStatus(SettingsStatus.Loading));
    return apiRequest(token, '/onboarding/settings')
      .then(response => {
        if (response.status >= 400) {
          throw new Error('Settings could not be loaded.');
        }
        return response.json();
      })
      .then(settings => dispatch(receiveSettings(settings)))
      .catch(() => {
        dispatch(setStatus(SettingsStatus.Error));
      });
  };
};

/* ** FIELDS ** */
const SETTINGS_INPUT = 'settings/INPUT';
const fieldIsEmpty = field => field === '' || field === null;

/* NAME (READONLY ALWAYS) */
export const selectName = state =>
  `${state.settings.input.firstName || ''} ${state.settings.input.lastName || ''}`;

/* DATE OF BIRTH */
export const selectDateOfBirth = state => state.settings.input.birthday;
export const selectDateOfBirthEditable = state => fieldIsEmpty(state.settings.current.birthday);
export const setDateOfBirth = birthday => ({
  type: SETTINGS_INPUT,
  payload: {
    birthday
  }
});

/* PHONE */
export const selectPhone = state => state.settings.input.telephone;
export const selectPhoneEditable = state => fieldIsEmpty(state.settings.current.telephone);
export const setPhone = telephone => ({
  type: SETTINGS_INPUT,
  payload: {
    telephone
  }
});

/* EMAIL */
export const selectEmail = state => state.settings.input.email;
export const selectEmailEditable = state => fieldIsEmpty(state.settings.current.email);
export const setEmail = email => ({
  type: SETTINGS_INPUT,
  payload: {
    email
  }
});

/* CONSENT NEWSLETTER */
export const selectConsentNewsletter = state => !!state.settings.input.wantsNewsletter;
export const selectConsentNewsletterOriginal = state => state.settings.current.wantsNewsletter;
export const setConsentNewsletter = wantsNewsletter => ({
  type: SETTINGS_INPUT,
  payload: {
    wantsNewsletter
  }
});

/* CONSENT EMAILS */
export const selectConsentEmails = state => !!state.settings.input.allowContact;
export const selectConsentEmailsOriginal = state => state.settings.current.allowContact;
export const setConsentEmails = allowContact => ({
  type: SETTINGS_INPUT,
  payload: {
    allowContact
  }
});

/* PEOPLE IN HOUSEHOLD (AMOUNT) */
export const selectPeopleInHouseholdAmount = state => state.settings.input.householdPeople;
export const setPeopleInHouseholdAmount = householdPeople => ({
  type: SETTINGS_INPUT,
  payload: {
    householdPeople
  }
});

/* KIDS IN HOUSEHOLD (BOOLEAN) */
export const selectKidsInHousehold = state => !!state.settings.input.householdHasChildren;
export const setKidsInHousehold = householdHasChildren => ({
  type: SETTINGS_INPUT,
  payload: {
    householdHasChildren
  }
});

/* KIDS IN HOUSEHOLD (AMOUNT) */
export const selectKidsInHouseholdAmount = state => state.settings.input.householdChildren;
export const setKidsInHouseholdAmount = householdChildren => ({
  type: SETTINGS_INPUT,
  payload: {
    householdChildren
  }
});

/* INTERESTS */
const SETTINGS_TOGGLE_INTEREST = 'settings/input/interests/TOGGLE';
export const selectPickedInterests = state => state.settings.input.interests;
export const togglePickedInterest = key => ({
  type: SETTINGS_TOGGLE_INTEREST,
  payload: {
    key
  }
});

export const selectCustomerId = state => state.settings.input.customerId;

/* SAVE */
export const submit = token => {
  return (dispatch, getState) => {
    dispatch(setStatus(SettingsStatus.Saving));
    const data = getState().settings.input;

    return apiRequest(token, '/onboarding/settings', 'PUT', data)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          dispatch(setStatus(SettingsStatus.Saved));
        } else {
          throw new Error('Settings could not be saved.');
        }
      })
      .catch(() => {
        dispatch(setStatus(SettingsStatus.Error));
        dispatch(
          setModal(
            'Speichern fehlgeschlagen',
            'Es tut uns leid, aber wir konnten Ihre Änderung nicht speichern. Bitte melden Sie sich erneut im Kundenportal an und versuchen Sie es noch einmal.'
          )
        );
      });
  };
};

/* REDUCER */
export default function settings(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_STATUS_SET: {
      const { status } = action.payload;
      return {
        ...state,
        status
      };
    }

    case SETTINGS_RECEIVE: {
      const data = action.payload;
      // Reiterate the data object to make sure that the values are present
      const dataObject = {
        customerId: data.customerId || null,
        salutation: data.salutation || null,
        firstName: data.firstName || null,
        lastName: data.lastName || null,
        email: data.email || '',
        birthday: data.birthday || '',
        telephone: data.telephone || '',
        wantsNewsletter: !!data.wantsNewsletter,
        allowContact: !!data.allowContact,
        householdPeople: data.householdPeople || 1,
        householdHasChildren: !!data.householdHasChildren,
        householdChildren: data.householdChildren || null,
        interests: data.interests || []
      };
      // Received data goes into "current" and "input"
      return {
        ...state,
        current: dataObject,
        input: dataObject,
        status: SettingsStatus.Idle
      };
    }

    case SETTINGS_INPUT:
      return {
        ...state,
        input: {
          ...state.input,
          ...action.payload
        }
      };

    case SETTINGS_TOGGLE_INTEREST: {
      const { key } = action.payload;
      const newInterests = [...state.input.interests];
      const interestIndex = newInterests.indexOf(key);
      interestIndex >= 0 ? newInterests.splice(interestIndex, 1) : newInterests.push(key);
      return {
        ...state,
        input: {
          ...state.input,
          interests: newInterests
        }
      };
    }

    default:
      return state;
  }
}
