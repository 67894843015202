import React from 'react';
import PropTypes from 'prop-types';
import './LoadingOverlay.scss';

const LoadingOverlay = ({ show, cover = true }) => {
  return (
    <div className={`LoadingOverlay ${show ? 'LoadingOverlay--Visible' : ''}`}>
      <div className='LoadingOverlay__ProgressBar' />
      {!cover ? null : <div className='LoadingOverlay__Background' />}
    </div>
  );
};

LoadingOverlay.propTypes = {
  show: PropTypes.bool,
  cover: PropTypes.bool
};

export default LoadingOverlay;
