import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import './TextLink.scss';

const TextLink = memo(({ bold, href, inline, onClick, target, title, to }) => {
  const commonProps = {
    className: `TextLink ${inline ? 'TextLink--Inline' : ''} ${bold ? 'TextLink--Bold' : ''}`,
    title
  };

  const props = {
    ...(href && { href, target }),
    ...(onClick && { onClick }),
    ...(to && { to }),
    ...commonProps
  };

  const element = (to && Link) || (href && 'a') || 'button';
  return React.createElement(element, props, title);
});

TextLink.displayName = 'TextLink';
TextLink.propTypes = {
  bold: PropTypes.bool,
  href: PropTypes.string,
  inline: PropTypes.bool,
  onClick: PropTypes.func,
  target: PropTypes.oneOf(['_self', '_blank']),
  title: PropTypes.string.isRequired,
  to: PropTypes.any
};

export default TextLink;
