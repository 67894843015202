import { useCallback, useEffect, useState } from 'react';
import { apiRequest } from '../api/api';
import { useSelector } from 'react-redux';
import { selectToken } from '../redux/reducers/auth';
import { formatDate } from '../helpers/formatters';
import useResources from './useResources';
import { selectOfferIsBookmarked } from '../redux/reducers/bookmarks';

export default function useValid(offer, type = 'qr') {
  const [busy, setBusy] = useState(false);
  const [valid, setValid] = useState(false);
  const [message, setMessage] = useState(null);
  const [allowBookmark, setAllowBookmark] = useState(false);
  const [linkToCampaign, setLinkToCampaign] = useState(false);
  const token = useSelector(selectToken);
  const resource = useResources();
  const [tag, setTag] = useState(0);
  const isBookmarked = useSelector(selectOfferIsBookmarked(offer?.id));

  useEffect(() => {
    if (offer?.id) {
      setBusy(true);
      setLinkToCampaign(false);
      const endpoint =
        (type === 'qr' && `/redeem/valid/${type}/${offer?.id}`) ||
        (type === 'article' && `/redeem/valid/${type}/${offer?.id}`) ||
        (type === 'shopify' && `/redeem/valid/${type}/${offer?.id}`) ||
        (type === 'form' && `/redeem/valid/${type}/${offer?.id}`) ||
        (type === 'externalMg' && `/redeem/valid/external/mg/${offer?.id}`) ||
        undefined;
      apiRequest(token, endpoint, 'GET', undefined, process.env.REACT_APP_API_INTERACT, true)
        .then(response => {
          setValid(response.status === 200);
          setAllowBookmark(response.status === 200);
          setBusy(false);
          if (response.status !== 200) {
            return response.json();
          }
        })
        .then(errorData => {
          switch (errorData?.type) {
            case 'NoCouponCodesAvailable': {
              setMessage(resource('offer.validityCheck.externalMgNoCodesAvailable'));
              setAllowBookmark(true);
              return;
            }
            case 'CouponBlocked': {
              if ('offerLastActivation' in errorData) {
                const blockedDays = offer.definition?.externalMG?.blockedDays;
                const lastActivation = new Date(errorData.offerLastActivation);
                const day = lastActivation.getDate();
                const month = lastActivation.getMonth() + 1;
                const year = lastActivation.getFullYear();
                const activationDate = `${day}.${month}.${year}`;
                setMessage(
                  resource('offer.validityCheck.externalMgBlockedActivated', {
                    blockedDays,
                    activationDate
                  })
                );
                setAllowBookmark(true);
                return;
              } else {
                const blockedDays = offer.definition?.externalMG?.blockedDays;
                setMessage(resource('offer.validityCheck.externalMgBlocked', { blockedDays }));
                setAllowBookmark(true);
                return;
              }
            }
            case 'NotInValidityTimeRange': {
              if (errorData?.members?.includes('ValidFrom')) {
                setMessage(
                  resource('offer.validityCheck.validFrom', {
                    validFrom: formatDate(offer.validFrom)
                  })
                );
                setAllowBookmark(true);
              } else if (errorData?.members?.includes('ValidUntil')) {
                setMessage(resource('offer.validityCheck.validUntil'));
              }
              return;
            }
            case 'ActivationLimitExceeded': {
              setMessage(resource('offer.validityCheck.limit'));
              return;
            }
            case 'ProductOutOfStock': {
              setMessage(resource('offer.validityCheck.inventory'));
              setAllowBookmark(true);
              setLinkToCampaign(true);
              return;
            }
            default: {
              // Captures the following error modes:
              // * EmailNotAssigned
              // * CustomerIdNotAssigned
              // * NotOnboarded
              // * IncompletelyOnboarded
              // * MetadataInaccessible
              // * AddressInaccessible
              // * UnknownBackendError
              // * AlreadyActivated
              // * CustomerRequired
              // * ProductLimitExceeded
              // * InvalidDefinition
              // * InvalidActivation
              setMessage(resource('offer.validityCheck.error'));
              return;
            }
          }
        });
    } else {
      // No offer to redeem, reset everything
      setBusy(false);
      setValid(false);
    }
  }, [offer?.id, tag]);
  const revalidate = useCallback(() => setTag(t => t + 1), []);

  return [busy, valid, message, allowBookmark || isBookmarked, linkToCampaign, revalidate];
}
