import React from 'react';
import PropTypes from 'prop-types';
import './NumericPicker.scss';
import { NoEdit, Plus, Minus } from '../../../images/icons';
import Tooltip from '../Tooltip';
import IconButton from '../IconButton';
import Colors from '../../../definitions/colors';
import useResources from '../../../hooks/useResources';

const NumericPicker = ({
  disabled,
  instantValidator,
  max,
  min,
  nullable,
  onUpdateValue,
  readonly,
  showLabel,
  title,
  tooltip,
  value,
  valueDisplay
}) => {
  const resource = useResources();
  const validation = instantValidator ? instantValidator(value) : true;
  const canIncrease = (nullable && value === null) || value < max || !max;
  const increase = () => {
    if (canIncrease) {
      return nullable && value === null ? onUpdateValue(min) : onUpdateValue(value + 1);
    }
    return undefined;
  };
  const canDecrease = value > min || (nullable && value === min);
  const decrease = () => {
    if (canDecrease) {
      return nullable && value === min ? onUpdateValue(null) : onUpdateValue(value - 1);
    }
    return undefined;
  };

  return (
    <div
      className={`NumericPicker
      ${readonly ? 'NumericPicker--Readonly' : ''}
      ${disabled ? 'NumericPicker--Disabled' : ''}
      `}
    >
      {showLabel && (
        <div className='NumericPicker__Label'>
          <span>{title}</span>
          {tooltip && <Tooltip text={tooltip} />}
        </div>
      )}
      <div className={`NumericPicker__Value ${value === null ? 'NumericPicker__Value--Null' : ''}`}>
        {value === null ? resource('numericPicker.empty') : valueDisplay(value)}
      </div>
      {!readonly && (
        <div className='NumericPicker__Buttons'>
          <div className='NumericPicker__Button NumericPicker__Button--Minus'>
            <IconButton
              title={resource('numericPicker.decrease')}
              iconCircle={{ icon: <Minus size={16} fill={Colors.White} />, size: 28 }}
              disabled={!canDecrease || disabled}
              onClick={decrease}
            />
          </div>
          <div className='NumericPicker__Button NumericPicker__Button--Plus'>
            <IconButton
              title={resource('numericPicker.increase')}
              iconCircle={{ icon: <Plus size={16} fill={Colors.White} />, size: 28 }}
              disabled={!canIncrease || disabled}
              onClick={increase}
            />
          </div>
        </div>
      )}
      <div
        className={`NumericPicker__Validation ${
          validation !== true ? 'NumericPicker__Validation--Visible' : ''
        }`}
      >
        {validation}
      </div>
      {readonly && (
        <div className='NumericPicker__Readonly'>
          <Tooltip text={readonly} size={28} icon={NoEdit} iconSize={14} />
        </div>
      )}
    </div>
  );
};

NumericPicker.propTypes = {
  disabled: PropTypes.bool,
  instantValidator: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number.isRequired,
  nullable: PropTypes.bool,
  onUpdateValue: PropTypes.func.isRequired,
  readonly: PropTypes.string,
  showLabel: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  value: PropTypes.number.isRequired,
  valueDisplay: PropTypes.func.isRequired
};

NumericPicker.defaultProps = {
  min: 0,
  nullable: false,
  showLabel: true,
  value: 0,
  valueDisplay: value => value
};

export default NumericPicker;
