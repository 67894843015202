import PropTypes from 'prop-types';
import React from 'react';
import Colors from '../../definitions/colors';
import { offerValidityInfo } from '../../helpers/formatters';
import useResources from '../../hooks/useResources';
import { Calendar, HandWithHeart } from '../../images/icons';
import IconHighlight from '../atoms/IconHighlight';
import OfferNotValid from '../atoms/OfferNotValid';
import PictureGallery from '../atoms/PictureGallery';
import useValid from '../../hooks/useValid';
import './OfferPanel.scss';
import RedeemForm from './RedeemForm';
import RedeemQrCode from './RedeemQrCode';
import RedeemShopify from './RedeemShopify';
import RedeemExternalMg from './RedeemExternalMg';
import { Box } from '../layout/Box';
import { useSelector } from 'react-redux';
import { selectTourAt, TourState } from '../../redux/reducers/tour';
import { TourOfferDetails } from './tour/TourOfferDetails';
import { TourValidity } from './tour/TourValidity';

const OfferPanel = ({ offer, isPreview }) => {
  const resource = useResources();
  const type =
    (offer?.definition?.qrCode && 'QrCode') ||
    (offer?.definition?.shopify && 'Shopify') ||
    (offer?.definition?.form && 'Form') ||
    (offer?.definition?.externalMG && 'ExternalMG') ||
    'Article';

  const at = useSelector(selectTourAt);
  const atOfferDetails = at === TourState.aboutOfferDetails;
  const atValidity = at === TourState.aboutValidity;
  const atRedeemGeneral = at === TourState.aboutRedeemGeneral;
  const atRedeemThis = at === TourState.aboutRedeemThis;
  const atQrCode = at === TourState.aboutQrCode;
  const atPdf = at === TourState.aboutPdf;
  const atBookmarking = at === TourState.aboutBookmarking;
  const atAny =
    atOfferDetails ||
    atValidity ||
    atRedeemGeneral ||
    atRedeemThis ||
    atQrCode ||
    atPdf ||
    atBookmarking;

  // Check validity
  // eslint-disable-next-line no-unused-vars
  const [validBusy, valid, invalidMessage, allowBookmark, , revalidate] = useValid(
    offer,
    'externalMg'
  );

  return (
    <Box active={atAny} disabled className={`OfferPanel OfferPanel--${type}`}>
      <div className='OfferPanel__MediaWrapper'>
        <PictureGallery
          altText={offer?.title}
          sources={offer?.images?.map(image => image.location)}
        />
      </div>
      {!offer.definition?.article && (
        <div className='OfferPanel__DataWrapper'>
          <div className='OfferPanel__Data'>
            <div className='OfferPanel__Highlight'>
              <IconHighlight
                title={offer.description}
                iconCircle={{
                  icon: <HandWithHeart fill={Colors.White} size={34} />,
                  size: 60
                }}
              />
            </div>
            <Box active={atValidity} className='OfferPanel__Highlight'>
              <IconHighlight
                title={
                  invalidMessage && invalidMessage.includes('Du hast diesen Gutschein bereits')
                    ? resource('offerValidity.externalRedeemed')
                    : offerValidityInfo(offer, resource)
                }
                iconCircle={{
                  icon: <Calendar fill={Colors.White} size={34} />,
                  offsetY: -1,
                  size: 60
                }}
              />
              <TourValidity />
            </Box>
          </div>
          <div className='OfferPanel__Redeem'>
            {isPreview && <OfferNotValid message={resource('preview.info.general')} />}
            {!isPreview && offer?.definition?.qrCode && <RedeemQrCode offer={offer} />}
            {!isPreview && offer?.definition?.shopify && <RedeemShopify offer={offer} />}
            {!isPreview && offer?.definition?.form && <RedeemForm offer={offer} />}
            {!isPreview && offer?.definition?.externalMG && <RedeemExternalMg offer={offer} />}
          </div>
        </div>
      )}
      <TourOfferDetails />
    </Box>
  );
};

OfferPanel.propTypes = {
  offer: PropTypes.any,
  isPreview: PropTypes.bool
};

export default OfferPanel;
