import './Flyout.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { classes } from '../../helpers/styles';

/**
 * Anchor name.
 * @type {Readonly<{CenterRight: string, BottomRight: string, BottomCenter: string, TopCenter: string, BottomLeft: string, CenterLeft: string, TopRight: string, TopLeft: string}>}
 */
export const Anchor = Object.freeze({
  TopLeft: 'top-left',
  TopCenter: 'top-center',
  TopRight: 'top-right',
  CenterLeft: 'center-left',
  CenterRight: 'center-right',
  BottomLeft: 'bottom-left',
  BottomCenter: 'bottom-center',
  BottomRight: 'bottom-right'
});

/**
 * Anchor align.
 * @type {Readonly<{Center: string, Start: string, End: string}>}
 */
export const FlyoutAlign = Object.freeze({
  Start: 'start',
  Center: 'center',
  End: 'end'
});

/**
 * Renders a flyout with absolut position inset zero, aligns the flyout based on the anchor points.
 * @param active True if active.
 * @param anchorFrom The position at the container on where to align from.
 * @param anchorAt The position at the child where to anchor at.
 * @param align The alignment of the flyout in combination with the anchor.
 * @param children The content.
 * @return {JSX.Element}
 * @constructor
 */
export const Flyout = ({
  active,
  anchorFrom = Anchor.TopCenter,
  anchorAt = Anchor.BottomCenter,
  align = FlyoutAlign.Center,
  children
}) => {
  // Determine anchoring rules.
  const isLeft =
    anchorAt === Anchor.TopLeft || anchorAt === Anchor.CenterLeft || anchorAt === Anchor.BottomLeft;

  const isRight =
    anchorAt === Anchor.TopRight ||
    anchorAt === Anchor.CenterRight ||
    anchorAt === Anchor.BottomRight;

  const isTop =
    anchorAt === Anchor.TopLeft || anchorAt === Anchor.TopCenter || anchorAt === Anchor.TopRight;

  const isBottom =
    anchorAt === Anchor.BottomLeft ||
    anchorAt === Anchor.BottomCenter ||
    anchorAt === Anchor.BottomRight;

  // Create flyout host and arranger. Set positioning according to anchoring rules.
  return (
    <div className={classes('Flyout', 'Flyout--' + anchorFrom)}>
      <div
        className={classes(
          'FlyoutContainer',
          'FlyoutContainer--' + align,
          active && 'FlyoutContainer--active'
        )}
        style={{
          left: isLeft ? 0 : undefined,
          right: isRight ? 0 : undefined,
          top: isTop ? 0 : undefined,
          bottom: isBottom ? 0 : undefined
        }}
      >
        {children}
      </div>
    </div>
  );
};

Flyout.propTypes = {
  active: PropTypes.bool,
  anchorFrom: PropTypes.oneOf(Object.values(Anchor)),
  anchorAt: PropTypes.oneOf(Object.values(Anchor)),
  align: PropTypes.oneOf(Object.values(FlyoutAlign)),
  children: PropTypes.node
};
