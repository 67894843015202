import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { AuthStatus, selectStatus as selectAuthStatus } from '../../redux/reducers/auth';
import {
  OnboardingStatus,
  selectStatus as selectOnboardingStatus
} from '../../redux/reducers/onboarding';
import './MenuItem.scss';

const MenuItem = ({ to, onClick, title, onlyAuthenticated, badge, noMatch, width }) => {
  // Auth status
  const authStatus = useSelector(selectAuthStatus);
  const onboardingStatus = useSelector(selectOnboardingStatus);
  const showItem = useMemo(
    () =>
      !onlyAuthenticated ||
      (authStatus === AuthStatus.Authenticated && onboardingStatus === OnboardingStatus.Completed),
    [authStatus, onboardingStatus, onlyAuthenticated]
  );
  const active =
    useRouteMatch(to) && !(noMatch?.map(route => useRouteMatch(route)).filter(Boolean).length > 0);

  return !showItem ? null : (
    <Link
      to={to}
      onClick={onClick}
      style={width ? { width } : undefined}
      title={title}
      className={['MenuItem', active && 'MenuItem--Active'].filter(Boolean).join(' ')}
    >
      {title}
      {badge >= 1 && <span className='MenuItem__Badge'>{badge}</span>}
    </Link>
  );
};

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  onlyAuthenticated: PropTypes.bool,
  badge: PropTypes.number,
  noMatch: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.number
};

export default MenuItem;
