const Routes = {
  Bookmarks: () => `/bookmarks`,
  Index: () => `/`,
  OfferPreview: alias => `/preview/${alias || ':alias'}`,
  Profile: () => `/profile`,
  Onboarding: () => `/onboarding`,
  SingleCampaign: guid => `/campaign/${guid || ':guid'}`,
  SingleOffer: guid => `/offer/${guid || ':guid'}`,
  StandaloneOffers: () => `/campaign/standalone`,
  UpcomingOffers: () => `/campaign/upcoming`
};

export default Routes;
