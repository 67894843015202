import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './Dropdown.scss';
import { select } from '../../helpers/handlers';
import Colors from '../../definitions/colors';
import { Close, Down } from '../../images/icons';
import { useClickOutside } from '../../hooks/useClickOutside';
import { randomUUID } from '../../helpers/randomUUID';

/**
 * Single entry in category select.
 * @param active True if available to select.
 * @param text The text of the entry.
 * @param icon The icon of the entry, element.
 * @param selected True if selected.
 * @param onSelect Invoked on selection change.
 * @return {JSX.Element}
 * @constructor
 */
const CategorySelectItem = ({ active, text, icon, selected, onSelect }) => {
  const id = useMemo(() => randomUUID(), []);
  return (
    <label
      htmlFor={id}
      tabIndex={active ? 0 : -1}
      className='Dropdown__Item'
      onKeyDown={select(onSelect)}
    >
      {icon}
      {text}
      <input type='checkbox' checked={selected} onChange={onSelect} id={id} name={id} />
      <span className='Dropdown__Item-checkmark' />
    </label>
  );
};

CategorySelectItem.propTypes = {
  active: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
};

/**
 * Returns true if there are categories set in the input.
 * @param categories The value to check.
 * @return {*}
 */
export const categoriesSet = categories => categories?.length;

export const CategorySelected = Object.freeze({
  fromToggle: Symbol('fromToggle'),
  fromClear: Symbol('fromClear')
});

/**
 * Category selection element.
 * @param className Main class element.
 * @param style Extra styles.
 * @param options Options that are selectable.
 * @param categories The categories that are selected.
 * @param setCategories Invoked on category selection change.
 * @return {JSX.Element}
 * @constructor
 */
export const CategorySelect = ({ className, style, options, categories, setCategories }) => {
  // Ref to root div.
  const ref = useRef(null);

  // State of true if dropdown is open.
  const [open, setOpen] = useState(false);

  // Close when clicked outside.
  useClickOutside(ref, () => setOpen(false));

  // Currently displayed text.
  const currentText = useMemo(() => {
    // Get dictionary to labels.
    const dict = options.reduce((object, option) => {
      object[option.value] = option.label;
      return object;
    }, {});

    // Return joined for all enabled values..
    return categories?.length
      ? categories.map(value => dict[value]).join(', ')
      : 'Kategorie auswählen';
  }, [options, categories]);

  // Toggles a value in the input by the option's key.
  const toggleValue = useCallback(
    value => {
      if (categories.includes(value))
        setCategories(
          options
            .filter(option => categories.includes(option.value) && value !== option.value)
            .map(option => option.value),
          CategorySelected.fromToggle
        );
      else
        setCategories(
          options
            .filter(option => categories.includes(option.value) || value === option.value)
            .map(option => option.value),
          CategorySelected.fromToggle
        );
    },
    [categories, setCategories, options]
  );

  // Deselect all values
  const deselectValues = useCallback(() => {
    setCategories([], CategorySelected.fromClear);
    setOpen(false);
  }, [setCategories]);

  // Toggle open.
  const toggleOpen = useCallback(() => setOpen(open => !open), [setOpen]);

  return (
    <div
      className={`Dropdown ${open ? 'Dropdown--Open' : ''} ${className}`}
      style={style}
      ref={ref}
    >
      <div
        className='Dropdown__Header'
        onClick={select(toggleOpen)}
        onKeyDown={select(toggleOpen)}
        tabIndex={0}
        role='button'
        aria-expanded={open}
      >
        <span style={{ paddingRight: '28px' }}>{currentText}</span>

        {categories.length > 0 ? (
          <Close
            role='button'
            tabIndex={0}
            className='Dropdown__Clear'
            size={12}
            fill={Colors.Black}
            onClick={select(deselectValues)}
            onKeyDown={select(deselectValues)}
          />
        ) : null}

        <Down className='Dropdown__Caret' size={12} fill={Colors.Black} />
      </div>
      <div className='Dropdown__Select' role='presentation' tabIndex={-1}>
        {options.map(option => (
          <CategorySelectItem
            key={option.value}
            active={open}
            text={option.label}
            icon={option.icon}
            selected={categories.includes(option.value)}
            onSelect={() => toggleValue(option.value)}
          />
        ))}
      </div>
    </div>
  );
};

CategorySelect.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node
    })
  ).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCategories: PropTypes.func.isRequired
};
