import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessage, selectTitle, setModal } from '../../redux/reducers/modal';
import Button from '../atoms/Button';
import './Modal.scss';
import Overlay from '../atoms/Overlay';
import useResources from '../../hooks/useResources';

const Modal = () => {
  const title = useSelector(selectTitle);
  const message = useSelector(selectMessage);
  const dispatch = useDispatch();
  const [closing, setClosing] = useState(false);
  const resource = useResources();

  const closeModal = () => {
    setClosing(true);
  };

  useEffect(() => {
    if (closing) {
      const to = setTimeout(() => dispatch(setModal(null)), 500);
      return () => clearTimeout(to);
    }
  }, [closing]);

  useEffect(() => {
    setClosing(false);
  }, [title, message]);

  const visible = (title || message) && !closing;

  return (
    <Overlay
      fixed={true}
      visible={visible}
      onClose={closeModal}
      showCloseButton={false}
      containerStyle={{ pointerEvents: 'all' }}
    >
      <h3 className='Modal__Title'>{title}</h3>
      <p className='Modal__Message'>{message}</p>
      <div className='Modal__Button'>
        <Button title={resource('overlay.close')} onClick={closeModal} />
      </div>
    </Overlay>
  );
};

export default Modal;
