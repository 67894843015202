import React from 'react';
import PropTypes from 'prop-types';
import './BigLabel.scss';

const BigLabel = ({ children }) => {
  return <div className='BigLabel'>{children}</div>;
};

BigLabel.propTypes = {
  children: PropTypes.string.isRequired
};

export default BigLabel;
