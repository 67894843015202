import React, { Fragment } from 'react';
import OfferSlider from './OfferSlider';
import TextLink from '../atoms/TextLink';
import Routes from '../../definitions/routes';
import useResources from '../../hooks/useResources';
import PropTypes from 'prop-types';
import { useTourTargetCampaign, useTourTargetEvmCampaign } from '../../hooks/useTourTargets';

export const IndexCampaigns = ({ items }) => {
  const resource = useResources();
  const tourCampaign = useTourTargetCampaign();
  const tourEvmCampaign = useTourTargetEvmCampaign();
  if (!items?.length) return null;
  return (
    <Fragment>
      {items.map(campaign => (
        <OfferSlider
          key={campaign.id}
          title={campaign.title}
          showAll={
            <TextLink title={resource('offers.showAll')} to={Routes.SingleCampaign(campaign.id)} />
          }
          tour={tourCampaign?.id === campaign.id}
          tourEvm={tourEvmCampaign?.id === campaign.id}
          offers={campaign.offers}
        />
      ))}
    </Fragment>
  );
};

IndexCampaigns.propTypes = {
  items: PropTypes.array
};
