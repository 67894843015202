import { useDispatch, useSelector } from 'react-redux';
import {
  selectTourAbortReason,
  selectTourAt,
  tourNext,
  TourState
} from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { Blinds } from '../../atoms/Blinds';
import { Anchor, Flyout } from '../../layout/Flyout';
import { TourCalloutAbort } from './TourCalloutAbort';
import { Scroller } from '../../atoms/Scroller';

const RestartingMessages = Object.freeze({
  aborted:
    'Kein Problem. Falls Du die Anleitung später doch noch starten möchtest, kannst Du das hier tun.',
  navigated:
    'Wir haben die Anleitung vorerst beendet. Falls Du die Anleitung später doch noch starten möchten, kannst Du das hier tun.',
  other: 'Falls Du die Anleitung später doch noch starten möchtest, kannst Du das hier tun.'
});

export function TourRestarting() {
  const at = useSelector(selectTourAt);
  const abortReason = useSelector(selectTourAbortReason);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutRestarting;
  const dispatch = useDispatch();
  const handleNext = useCallback(() => dispatch(tourNext()), [dispatch]);
  const packed = useBreakpoint('md-down');
  const message = RestartingMessages[abortReason] ?? RestartingMessages.other;

  if (skip) return null;
  return (
    <Fragment>
      <Blinds active={active} radius={0} inset={12} />
      <Flyout
        active={active}
        anchorFrom={packed ? Anchor.BottomRight : Anchor.BottomCenter}
        anchorAt={packed ? Anchor.TopRight : Anchor.TopCenter}
        align={packed ? 'end' : 'center'}
        offset={25}
      >
        <TourCalloutAbort button='Verstanden' onClick={handleNext} head={packed ? 'end' : 'center'}>
          <Scroller active={active} scroll='top' />
          {message}
        </TourCalloutAbort>
      </Flyout>
    </Fragment>
  );
}
