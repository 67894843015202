import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourAbort, tourNext, TourState } from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import { Blinds } from '../../atoms/Blinds';
import { Anchor, Flyout } from '../../layout/Flyout';
import { TourCallout } from './TourCallout';
import { Scroller } from '../../atoms/Scroller';

export function TourOfferDetails() {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutOfferDetails;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => dispatch(tourAbort()), [dispatch]);
  const handleNext = useCallback(() => dispatch(tourNext()), [dispatch]);
  const packed = useBreakpoint('md-down');
  useHistoryListener(() => {
    if (active) {
      dispatch(tourAbort('navigated'));
    }
  }, [active, dispatch]);
  if (skip) return null;
  return (
    <Fragment>
      <Blinds active={active} inset={0} radius={0} />
      <Flyout
        active={active}
        anchorFrom={packed ? Anchor.TopCenter : Anchor.TopRight}
        anchorAt={packed ? Anchor.BottomCenter : Anchor.BottomRight}
        align={packed ? 'center' : 'end'}
      >
        <TourCallout
          button='Weiter'
          stepAt={3}
          stepTotal={12}
          distance={16}
          onNext={handleNext}
          onClose={handleAbort}
        >
          <Scroller active={active} />
          Hier im Vorteil findest Du alles, was Du zu diesem Angebot wissen musst.
        </TourCallout>
      </Flyout>
    </Fragment>
  );
}
