import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import scrollToTop from '../../helpers/scrollToTop';
import useRedeem from '../../hooks/useRedeem';
import useResources from '../../hooks/useResources';
import useValid from '../../hooks/useValid';
import { setModal } from '../../redux/reducers/modal';
import Button from '../atoms/Button';
import CircleSpinner from '../atoms/CircleSpinner';
import OfferNotValid from '../atoms/OfferNotValid';
import Overlay from '../atoms/Overlay';
import BookmarkButton from './BookmarkButton';
import './RedeemExternalMg.scss';
import Tooltip from '../atoms/Tooltip';
import CircleIcon from '../atoms/CircleIcon';
import { Exclamation, Tick } from '../../images/icons';
import Colors from '../../definitions/colors';
import { loadSettings, selectCustomerId } from '../../redux/reducers/settings';
import { selectToken } from '../../redux/reducers/auth';
import { apiRequest } from '../../api/api';

const Step = ({ n }) => {
  return <div className='RedeemExternalMg__Step'>{n}</div>;
};

Step.propTypes = {
  n: PropTypes.number
};

const RedeemExternalMg = ({ offer }) => {
  const dispatch = useDispatch();
  const resource = useResources();
  const token = useSelector(selectToken);
  const customerId = useSelector(selectCustomerId);
  const [email, setEmail] = useState(null);

  // Needs to be invoked as we need the email for displaying it in the overlay notice.
  useEffect(() => {
    if (customerId) return;
    dispatch(loadSettings(token));
  }, [token, customerId, loadSettings, dispatch]);

  // Needs to be invoked as we need the email for displaying it in the overlay notice.
  useEffect(() => {
    if (!customerId) return;
    apiRequest(
      token,
      `/users/${customerId}/contact`,
      'GET',
      null,
      process.env.REACT_APP_API_ADMINISTER
    )
      .then(res => res.json())
      .then(res => setEmail(res.email));
  }, [token, customerId]);

  // Check validity
  const [validBusy, valid, invalidMessage, allowBookmark, , revalidate] = useValid(
    offer,
    'externalMg'
  );

  // For shopify redirect
  const [idToRedeem, setIdToRedeem] = useState(null);
  const [redeemBusy, redeemResponse, redeemError] = useRedeem(idToRedeem, 'externalMg');
  const [redeemModal, setRedeemModal] = useState(false);

  // Revalidate when redeem was performed successfully.
  useEffect(() => {
    if (redeemResponse === true) revalidate();
  }, [redeemResponse, revalidate]);

  // Show errors, if any occurred, and then reset
  useEffect(() => {
    if (redeemError) {
      scrollToTop();
      dispatch(setModal(resource('offer.redeemError.title'), redeemError));
      setRedeemModal(false);
      setIdToRedeem(null);
      revalidate();
    }
  }, [redeemError, revalidate]);

  if (!offer.definition?.externalMG) {
    return null;
  }

  const redeemButton =
    offer.definition?.externalMG?.buttonRedeemUsedName || resource('offer.externalMg.redeemButton');

  const overlayContent = redeemResponse ? (
    <div className='RedeemExternalMg__ModalSuccess'>
      <CircleIcon icon={<Tick fill={Colors.White} size={40} />} size={100} />

      <h3 className='RedeemExternalMg__ModalTitle RedeemExternalMg__ModalSuccess__Heading'>
        {resource('offer.externalMg.modalSuccess.title')}
      </h3>
      <div className='RedeemExternalMg__ModalSuccess__Segment'>
        <span
          dangerouslySetInnerHTML={{
            __html: resource('offer.externalMg.modalSuccess.text1', { email })
          }}
        />
      </div>
      <div className='RedeemExternalMg__ModalSuccess__Segment'>
        <span
          dangerouslySetInnerHTML={{
            __html: resource('offer.externalMg.modalSuccess.text2')
          }}
        />
      </div>
      <div className='RedeemExternalMg__ModalSuccess__End'>
        <Button
          fullWidth
          fullRound
          title={resource('offer.externalMg.modalSuccess.close')}
          onClick={() => setRedeemModal(false)}
        />
      </div>
    </div>
  ) : (
    <>
      <h3 className='RedeemExternalMg__ModalTitle'>
        {resource('offer.externalMg.modalHow.title')}
      </h3>
      <div className='RedeemExternalMg__ModalMessages'>
        <div className='RedeemExternalMg__ModalMessage'>
          <Step n={1} />
          <span
            dangerouslySetInnerHTML={{
              __html: resource('offer.externalMg.modalHow.text1', { email })
            }}
          />
          <Tooltip
            size={20}
            text={
              <div
                className='RedeemExternalMg__ModalMessage__TooltipText'
                dangerouslySetInnerHTML={{
                  __html: resource('offer.externalMg.modalHow.tooltip')
                }}
              />
            }
          />
        </div>
        <div className='RedeemExternalMg__ModalMessage'>
          <Step n={2} />
          <span dangerouslySetInnerHTML={{ __html: resource('offer.externalMg.modalHow.text2') }} />
        </div>
        <div className='RedeemExternalMg__ModalMessage'>
          <Step n={3} />
          <span
            dangerouslySetInnerHTML={{
              __html: resource('offer.externalMg.modalHow.text3', {
                offer: offer.title
              })
            }}
          />
        </div>
      </div>
      <div className='RedeemExternalMg__Notice'>
        <CircleIcon icon={<Exclamation fill={Colors.White} size={32} />} size={32} />
        <div className='RedeemExternalMg__Notice__Text'>
          <span
            dangerouslySetInnerHTML={{
              __html: resource('offer.externalMg.modalHow.duration', {
                duration: '30 Tage',
                date: (() => {
                  // TODO: Pull this from the offer data.
                  const now = new Date();
                  now.setDate(now.getDate() + 30);
                  return now.getDate() + '.' + (1 + now.getMonth()) + '.' + now.getFullYear();
                })(),
                blockedDays: offer.definition?.externalMG?.blockedDays
              })
            }}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: resource('offer.externalMg.modalHow.whenready')
            }}
          />
        </div>
      </div>
      <div className='RedeemExternalMg__ModalButton'>
        <div style={{ flex: 1 }}>
          <BookmarkButton bookmarkable={true} offerId={offer.id} atom />
        </div>
        <div style={{ flex: 1 }}>
          <div
            className={`RedeemExternalMg__Busy ${
              validBusy || redeemBusy ? 'RedeemExternalMg__Busy--Visible' : ''
            }`}
          >
            <CircleSpinner size={40} />
          </div>
          <Button
            title={resource('offer.externalMg.modalHow.button')}
            disabled={redeemBusy}
            onClick={() => {
              setIdToRedeem(offer.id);
            }}
          />
        </div>
      </div>
    </>
  );

  return (
    <div className='RedeemExternalMg'>
      {!valid ? null : (
        <div className='RedeemExternalMg__Submit'>
          <Button
            title={redeemButton}
            disabled={redeemBusy}
            fullWidth
            onClick={() => {
              setRedeemModal(true);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
        </div>
      )}
      {valid || validBusy ? null : <OfferNotValid message={invalidMessage} />}
      <BookmarkButton bookmarkable={allowBookmark} offerId={offer.id} />
      <Overlay
        windowStyle={{ width: 570 }}
        visible={redeemModal}
        onClose={() => setRedeemModal(false)}
        showCloseButton
      >
        {overlayContent}
      </Overlay>
    </div>
  );
};

RedeemExternalMg.propTypes = {
  offer: PropTypes.any
};

export default RedeemExternalMg;
