import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Routes from '../../definitions/routes';
import pageTitle from '../../helpers/pageTitle';
import useResources from '../../hooks/useResources';
import { selectToken } from '../../redux/reducers/auth';
import { fetchBookmarks, selectBookmarks } from '../../redux/reducers/bookmarks';
import Breadcrumbs from '../atoms/Breadcrumbs';
import Button from '../atoms/Button';
import Container from '../layout/Container';
import WysiwygStyling from '../layout/WysiwygStyling';
import AppTeaser from '../modules/AppTeaser';
import CampaignHero from '../modules/CampaignHero';
import FAQs from '../modules/FAQs';
import OfferGrid from '../modules/OfferGrid';
import RecommendationProgram from '../modules/RecommendationProgram';
import withAuthentication from '../wrappers/withAuthentication';
import './Bookmarks.scss';
import { Box } from '../layout/Box';
import { selectTourAt, TourState } from '../../redux/reducers/tour';
import { TourCurrentBookmarks } from '../modules/tour/TourCurrentBookmarks';

const Bookmarks = () => {
  const dispatch = useDispatch();
  const resource = useResources();
  const offersGrid = useRef(null);
  const bookmarks = useSelector(selectBookmarks);
  const token = useSelector(selectToken);

  const at = useSelector(selectTourAt);
  const atCurrentBookmarks = at === TourState.aboutCurrentBookmarks;
  // Refresh bookmarks list when opening
  useEffect(() => {
    dispatch(fetchBookmarks(token));
  }, []);
  return (
    <div className='Bookmarks'>
      {pageTitle(resource('bookmarks.headline'))}
      <div className='Bookmarks__Main'>
        <Breadcrumbs
          nodes={[{ to: Routes.Bookmarks(), label: resource('breadcrumbs.bookmarks') }]}
        />
        <CampaignHero
          campaign={{
            title: resource('bookmarks.headline'),
            excerpt: resource('bookmarks.intro')
          }}
          slim
        />
        {bookmarks.length > 0 && (
          <div className='Bookmarks__Offers' ref={offersGrid}>
            <Container>
              <Box active={atCurrentBookmarks} disabled>
                <TourCurrentBookmarks />
                <OfferGrid bookmarks={bookmarks} />
              </Box>
            </Container>
          </div>
        )}
        {bookmarks.length < 1 && (
          <Container>
            <Box active={atCurrentBookmarks} disabled>
              <TourCurrentBookmarks empty />
              <div className='Bookmarks__Empty'>
                <h2 className='Bookmarks__EmptyHeadline'>{resource('bookmarks.empty.headline')}</h2>
                <WysiwygStyling html={resource('bookmarks.empty.text')} />
                <div className='Bookmarks__Back'>
                  <Button variant='ORANGE_FILL' title='Zurück zur Übersicht' to={Routes.Index()} />
                </div>
              </div>
            </Box>
          </Container>
        )}
      </div>
      <AppTeaser />
      <RecommendationProgram />
      <FAQs />
    </div>
  );
};

export default withAuthentication(Bookmarks);
