import React from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import './RecommendationProgram.scss';
import evmTippgeber from '../../images/evm-tippgeber.svg';
import useResources from '../../hooks/useResources';

const RecommendationProgram = () => {
  const resource = useResources();
  return (
    <div className='RecommendationProgram'>
      <Container>
        <div className='RecommendationProgram__Wrapper'>
          <div className='RecommendationProgram__Content'>
            <h2 className='RecommendationProgram__Title'>{resource('recommend.headline')}</h2>
            <p className='RecommendationProgram__Text'>{resource('recommend.text')}</p>
            <Button
              title={resource('recommend.button.text')}
              href={resource('recommend.button.url')}
              target='_blank'
              variant='WHITE_OUTLINE'
            />
          </div>
          <img
            src={evmTippgeber}
            alt={resource('recommend.headline')}
            className='RecommendationProgram__Logo'
          />
        </div>
      </Container>
    </div>
  );
};

export default RecommendationProgram;
