import moment from 'moment';
moment.locale('de');

export const validateEmail = (value, resource, emptyIsValid = false) => {
  return (
    // eslint-disable-next-line no-empty-character-class
    /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/.test(
      value
    ) ||
    (!value && emptyIsValid) ||
    resource('invalid.email')
  );
};

export const validateDate = (value, resource, emptyIsValid = false) => {
  return (
    moment(value, 'DD.MM.YYYY').format('DD.MM.YYYY') === value ||
    (!value && emptyIsValid) ||
    resource('invalid.date')
  );
};

export const validatePhone = (value, resource, emptyIsValid = false) => {
  return (
    /^\+?([0-9]|\/|-|\s)*$/.test(value) || (!value && emptyIsValid) || resource('invalid.phone')
  );
};
