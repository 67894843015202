import React, { useEffect } from 'react';
import OnboardingQuestion from '../OnboardingQuestion';
import './OnboardingPersonalData.scss';
import Textbox from '../../../atoms/inputs/Textbox';
import { validateDate, validatePhone } from '../../../../helpers/validators';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDateOfBirth,
  selectDateOfBirthEditable,
  selectPhone,
  selectPhoneEditable,
  setDateOfBirth,
  setPhone
} from '../../../../redux/reducers/onboarding';
import PropTypes from 'prop-types';
import useResources from '../../../../hooks/useResources';

const OnboardingPersonalData = ({ onValidate }) => {
  const dateOfBirth = useSelector(selectDateOfBirth);
  const dateOfBirthEditable = useSelector(selectDateOfBirthEditable);
  const phone = useSelector(selectPhone);
  const phoneEditable = useSelector(selectPhoneEditable);
  const dispatch = useDispatch();
  const resource = useResources();

  useEffect(() => {
    const dateOfBirthValid =
      !dateOfBirthEditable || validateDate(dateOfBirth, resource, true) === true;
    const phoneValid = !phoneEditable || validatePhone(phone, resource, true) === true;
    onValidate(dateOfBirthValid && phoneValid);
  }, [
    dateOfBirth,
    dateOfBirthEditable,
    phone,
    phoneEditable,
    validateDate,
    validatePhone,
    onValidate
  ]);

  return (
    <OnboardingQuestion question={resource('onboarding.personalData.headline')}>
      <div className='OnboardingPersonalData'>
        <p className='OnboardingPersonalData__Intro'>
          {resource('onboarding.personalData.birthday.text')}
        </p>
        <Textbox
          title={resource('onboarding.personalData.birthday.label')}
          name='dateOfBirth'
          value={dateOfBirth}
          onUpdateValue={value => dispatch(setDateOfBirth(value))}
          readonly={dateOfBirthEditable ? null : resource('readonlyTooltip')}
          instantValidator={(value, required) => validateDate(value, resource, required)}
          placeholder='TT.MM.JJJJ'
        />
        <Textbox
          title={resource('onboarding.personalData.phone.label')}
          name='phone'
          value={phone}
          onUpdateValue={value => dispatch(setPhone(value))}
          readonly={phoneEditable ? null : resource('readonlyTooltip')}
          instantValidator={(value, required) => validatePhone(value, resource, required)}
        />
      </div>
    </OnboardingQuestion>
  );
};

OnboardingPersonalData.propTypes = {
  onValidate: PropTypes.func.isRequired
};

export default OnboardingPersonalData;
