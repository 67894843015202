const initialState = {
  message: null
};

export const MODAL_SET = '/modal/SET';
export const setModal = (title, message) => ({
  type: MODAL_SET,
  payload: {
    title,
    message
  }
});

export const selectTitle = state => state.modal.title;
export const selectMessage = state => state.modal.message;

export default function modal(state = initialState, action) {
  switch (action.type) {
    case MODAL_SET: {
      const { title, message } = action.payload;
      return {
        ...state,
        title,
        message
      };
    }
    default:
      return state;
  }
}
