import React, { useEffect } from 'react';
import OnboardingQuestion from '../OnboardingQuestion';
import { validateEmail } from '../../../../helpers/validators';
import Textbox from '../../../atoms/inputs/Textbox';
import './OnboardingEmail.scss';
import Checkbox from '../../../atoms/inputs/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConsentEmails,
  selectConsentEmailsOriginal,
  selectConsentNewsletter,
  selectConsentNewsletterOriginal,
  selectEmail,
  selectEmailEditable,
  setConsentEmails,
  setConsentNewsletter,
  setEmail
} from '../../../../redux/reducers/onboarding';
import TextLink from '../../../atoms/TextLink';
import Tooltip from '../../../atoms/Tooltip';
import PropTypes from 'prop-types';
import useResources from '../../../../hooks/useResources';
import reactStringReplace from 'react-string-replace';

const OnboardingEmail = ({ onValidate }) => {
  const email = useSelector(selectEmail);
  const emailEditable = useSelector(selectEmailEditable);
  const consentNewsletter = useSelector(selectConsentNewsletter);
  const consentNewsletterOriginal = useSelector(selectConsentNewsletterOriginal);
  const consentEmails = useSelector(selectConsentEmails);
  const consentEmailsOriginal = useSelector(selectConsentEmailsOriginal);
  const dispatch = useDispatch();
  const resource = useResources();

  useEffect(() => {
    onValidate(!emailEditable || validateEmail(email, false) === true);
  }, [email, emailEditable, validateEmail, onValidate]);
  return (
    <OnboardingQuestion question={resource('onboarding.email.headline')}>
      <div className='OnboardingEmail'>
        <Textbox
          title={`${resource('onboarding.email.label')} *`}
          name='email'
          value={email}
          required
          onUpdateValue={value => dispatch(setEmail(value))}
          instantValidator={value => validateEmail(value, resource, true)}
          readonly={emailEditable ? null : resource('readonlyTooltip')}
        />
        {consentNewsletterOriginal ? null : (
          <Checkbox
            value={consentNewsletter}
            onUpdateValue={value => dispatch(setConsentNewsletter(value))}
            noMargin
          >
            {reactStringReplace(
              resource('onboarding.newsletter.checkbox'),
              '{privacyLink}',
              (_, index) => (
                <TextLink
                  key={index}
                  title={resource('onboarding.newsletter.privacyLink.text')}
                  target='_blank'
                  href={resource('onboarding.newsletter.privacyLink.url')}
                />
              )
            )}
          </Checkbox>
        )}
        {consentEmailsOriginal ? null : (
          <Checkbox
            value={consentEmails}
            onUpdateValue={value => dispatch(setConsentEmails(value))}
            noMargin
          >
            {resource('onboarding.optIn.checkbox')}
            <div className='OnboardingEmail__Tooltip'>
              <Tooltip text={resource('onboarding.optIn.tooltip')} size={20} />
            </div>
          </Checkbox>
        )}
      </div>
    </OnboardingQuestion>
  );
};

OnboardingEmail.propTypes = {
  onValidate: PropTypes.func.isRequired
};

export default OnboardingEmail;
