import React from 'react';
import CircleIcon from './CircleIcon';
import { Info } from '../../images/icons';
import Colors from '../../definitions/colors';
import './OfferNotValid.scss';
import PropTypes from 'prop-types';
import useResources from '../../hooks/useResources';

const OfferNotValid = ({ message }) => {
  const resource = useResources();
  return (
    <div className='OfferNotValid'>
      <CircleIcon icon={<Info size={4} fill={Colors.White} />} size={20} />
      <span className='OfferNotValid__Label'>
        {message || resource('offer.validityCheck.fallback')}
      </span>
    </div>
  );
};

OfferNotValid.propTypes = {
  message: PropTypes.any
};

export default OfferNotValid;
