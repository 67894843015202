import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

const Button = ({
  autoWidth,
  disabled,
  fullWidth,
  fullRound,
  href,
  icon,
  iconOnly,
  onClick,
  onKeyPress,
  slimOnMobile,
  target,
  title,
  to,
  variant,
  row
}) => {
  const commonProps = {
    className: `Button
    Button--Style-${variant}
    ${fullWidth && !autoWidth ? 'Button--FullWidth' : ''}
    ${fullRound ? 'Button--FullRound' : ''}
    ${autoWidth && !fullWidth ? 'Button--AutoWidth' : ''}
    ${slimOnMobile && !fullWidth ? 'Button--SlimOnMobile' : ''}
    ${icon && !iconOnly ? 'Button--HasIcon' : ''}
    ${row === 'start' ? 'Button--RowStart' : ''}
    ${row === 'center' ? 'Button--RowCenter' : ''}
    ${row === 'end' ? 'Button--RowEnd' : ''}
    `,
    disabled,
    title
  };

  const props = {
    ...(href && { href, target }),
    ...(onClick && { onClick }),
    ...(onKeyPress && { onKeyPress }),
    ...(to && { to }),
    ...commonProps
  };

  const element = (to && Link) || (href && 'a') || 'button';
  const children = (
    <>
      {!iconOnly ? <span>{title}</span> : null}
      {icon ? <div className='Button__Icon'>{icon}</div> : null}
    </>
  );
  return React.createElement(element, props, children);
};

Button.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.element,
  iconOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  target: PropTypes.oneOf(['_self', '_blank']),
  title: PropTypes.string.isRequired,
  to: PropTypes.any,
  fullWidth: PropTypes.bool,
  fullRound: PropTypes.bool,
  autoWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['ORANGE_FILL', 'ORANGE_OUTLINE', 'WHITE_FILL', 'WHITE_OUTLINE'])
    .isRequired,
  row: PropTypes.oneOf(['start', 'center', 'end']),
  slimOnMobile: PropTypes.bool
};

Button.defaultProps = {
  variant: 'ORANGE_FILL'
};

export default Button;
