import TextLink from '../atoms/TextLink';
import React from 'react';
import Colors from '../../definitions/colors';
import { Facebook, Up, Twitter, Instagram, YouTube, WhatsApp, Xing } from '../../images/icons';
import IconButton from '../atoms/IconButton';
import Container from '../layout/Container';
import './Footer.scss';
import useResources from '../../hooks/useResources';

const Footer = () => {
  const resource = useResources();
  return (
    <div className='Footer'>
      <Container>
        <div className='Footer__Inner'>
          <div className='Footer__ToTop'>
            <IconButton
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              iconCircle={{ icon: <Up fill={Colors.White} size={20} /> }}
              title={resource('footer.scrollToTop')}
            />
          </div>
          <div className='Footer__Menu'>
            <TextLink
              bold
              href={resource('footer.menu.evm.url')}
              title={resource('footer.menu.evm.title')}
              target='_blank'
            />
            <TextLink
              bold
              href={resource('footer.menu.contact.url')}
              title={resource('footer.menu.contact.title')}
              target='_blank'
            />
            <TextLink
              bold
              href={resource('footer.menu.privacy.url')}
              title={resource('footer.menu.privacy.title')}
              target='_blank'
            />
            <TextLink
              bold
              href={resource('footer.menu.legal.url')}
              title={resource('footer.menu.legal.title')}
              target='_blank'
            />
          </div>
          <div className='Footer__SocialMedia'>
            <IconButton
              href={resource('footer.social.facebook.url')}
              title={resource('footer.social.facebook.title')}
              target='_blank'
              iconCircle={{ color: '#3c5a99', icon: <Facebook fill={Colors.White} size={22} /> }}
            />
            <IconButton
              href={resource('footer.social.twitter.url')}
              title={resource('footer.social.twitter.title')}
              target='_blank'
              iconCircle={{ color: '#00aced', icon: <Twitter fill={Colors.White} size={22} /> }}
            />
            <IconButton
              href={resource('footer.social.instagram.url')}
              title={resource('footer.social.instagram.title')}
              target='_blank'
              iconCircle={{ color: '#e1306c', icon: <Instagram fill={Colors.White} size={22} /> }}
            />
            <IconButton
              href={resource('footer.social.youTube.url')}
              title={resource('footer.social.youTube.title')}
              target='_blank'
              iconCircle={{ color: '#ff0000', icon: <YouTube fill={Colors.White} size={20} /> }}
            />
            <IconButton
              href={resource('footer.social.whatsApp.url')}
              title={resource('footer.social.whatsApp.title')}
              target='_blank'
              iconCircle={{ color: '#25d366', icon: <WhatsApp fill={Colors.White} size={20} /> }}
            />
            <IconButton
              href={resource('footer.social.xing.url')}
              title={resource('footer.social.xing.title')}
              target='_blank'
              iconCircle={{ color: '#026466', icon: <Xing fill={Colors.White} size={20} /> }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
