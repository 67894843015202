import { useCallback, useEffect, useState } from 'react';

export default function useLocalStorage(key) {
  const [value, setValue] = useState(() => localStorage.getItem(key));
  useEffect(() => {
    setValue(localStorage.getItem(key));
  }, [key]);

  const updateValue = useCallback(
    value => {
      localStorage.setItem(key, value);
      setValue(value);
    },
    [key]
  );

  return [value, updateValue];
}
