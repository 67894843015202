import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import deeplinkRedirectable from '../helpers/deeplinkRedirectable';
import useLocalStorage from '../hooks/useLocalStorage';
import useQueryVar from '../hooks/useQueryVars';
import { selectToken, setAuthenticationError, setToken } from '../redux/reducers/auth';
import { fetchInterests } from '../redux/reducers/interests';
import {
  determineStatus,
  OnboardingStatus,
  setDeeplinkAfterSubmission,
  setStatus
} from '../redux/reducers/onboarding';
import { fetchResources } from '../redux/reducers/resources';
import { selectAppReady } from '../redux/reducers/rootReducer';
import './App.scss';
import Footer from './modules/Footer';
import Header from './modules/Header';
import Modal from './modules/Modal';
import ViewSwitch from './structures/ViewSwitch';
import withBrowserRouter from './wrappers/withBrowserRouter';
import withStore from './wrappers/withStore';
import ExternalLinkWarning from './modules/ExternalLinkWarning';
import { TourController } from './modules/tour/TourController';

/**
 *
 *
 * @type {React.Context<HTMLDivElement|null>}
 */
export const AppDivContext = createContext(null);

export function App() {
  const [div, setDiv] = useState(null);
  const dispatch = useDispatch();
  const tokenFromUrl = useQueryVar('token');
  const [tokenFromStorage, saveTokenToStorage] = useLocalStorage('token');
  const tokenFromState = useSelector(selectToken);
  const deeplink = useQueryVar('deeplink');
  const appReady = useSelector(selectAppReady);

  /* Hide global loading spinner */
  useEffect(() => {
    if (appReady) {
      const overlay = document.getElementsByClassName('ROOT__Spinner')[0];
      // @ts-ignore
      overlay.style.opacity = 0;
      // @ts-ignore
      overlay.style.visibility = 'hidden';
    }
  }, [appReady]);

  /* Set token to the store */
  if (!tokenFromState) {
    if (tokenFromUrl) {
      dispatch(setToken(tokenFromUrl));
      // URL token takes precedence over storage token
      // @ts-ignore
      saveTokenToStorage(tokenFromUrl);
    } else if (tokenFromStorage) {
      dispatch(setToken(tokenFromStorage));
    }
  }

  if (!tokenFromUrl && !tokenFromState && !tokenFromStorage) {
    // Token is missing
    dispatch(setStatus(OnboardingStatus.Error));
    dispatch(setAuthenticationError());
  }

  /* Load data when token changes */
  useEffect(() => {
    // Get static text resources and interests
    dispatch(fetchResources());
    dispatch(fetchInterests());
    if (tokenFromState) {
      // Get onboarding status and continue from there
      dispatch(determineStatus(tokenFromState));
    }
  }, [tokenFromState]);

  /* Forward to initial deeplink, if allowed */
  if (deeplinkRedirectable(deeplink)) {
    // If onboarding needs to be completed, store deeplink for redirection after submitting
    dispatch(setDeeplinkAfterSubmission(deeplink));
    return <Redirect to={deeplink} />;
  }
  return (
    <div ref={setDiv} className='App'>
      <AppDivContext.Provider value={div}>
        <Header />
        <ViewSwitch />
        <Footer />
        <Modal />
        <ExternalLinkWarning />
        <TourController />
      </AppDivContext.Provider>
    </div>
  );
}

export default withStore(withBrowserRouter(App));
