import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourAbort, tourNext, TourState } from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import { Blinds } from '../../atoms/Blinds';
import { Flyout } from '../../layout/Flyout';
import { TourCallout } from './TourCallout';
import { Scroller } from '../../atoms/Scroller';

export function TourRedeem() {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutRedeemGeneral || at === TourState.aboutRedeemThis;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => dispatch(tourAbort()), [dispatch]);
  const handleNext = useCallback(() => dispatch(tourNext()), [dispatch]);
  useHistoryListener(() => {
    if (active) dispatch(tourAbort('navigated'));
  }, [active, dispatch]);
  if (skip) return null;
  return (
    <Fragment>
      <Blinds
        active={active}
        insetLeft={30}
        insetRight={30}
        insetTop={15}
        insetBottom={15}
        radius={0}
      />
      <Flyout active={active}>
        <TourCallout
          button='Weiter'
          stepAt={5}
          stepTotal={12}
          distance={30}
          onNext={at === TourState.aboutRedeemGeneral ? handleNext : undefined}
          onClose={handleAbort}
        >
          <Scroller active={active} />
          {at === TourState.aboutRedeemGeneral ? (
            <>
              Es gibt verschiedene Arten von Vorteilen. Manche leiten Dich in einen Onlineshop oder
              zu einem Formular weiter, wo Du einen exklusiven Rabatt erhältst. Andere musst Du beim
              Partner vor Ort vorzeigen.
            </>
          ) : (
            <>
              Dieser Vorteil muss zum Beispiel vor Ort vorgezeigt werden. Probiere mal aus, was
              passiert, wenn Du auf diesen Button klickst!
            </>
          )}
        </TourCallout>
      </Flyout>
    </Fragment>
  );
}
