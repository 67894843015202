import React from 'react';
import PropTypes from 'prop-types';
import Container from '../layout/Container';
import './OfferBody.scss';
import TextRenderer from '../layout/TextRenderer';
import { formatSiteAddress, sanitizePhoneUrl, sanitizeWebUrl } from '../../helpers/formatters';
import TextLink from '../atoms/TextLink';
import ArticleContent from './ArticleContent';
import useResources from '../../hooks/useResources';

const OfferBody = ({ offer, isPreview }) => {
  const resource = useResources();
  const conditions =
    offer?.definition?.qrCode?.condition ||
    offer?.definition?.shopify?.condition ||
    offer?.definition?.form?.condition;

  // Pick address to display.
  const address =
    !offer?.geoUnrestricted && offer?.siteClosest
      ? formatSiteAddress(offer?.siteClosest)
      : offer?.address;

  // Check if offer is external MG.
  const isExternalMG = offer?.definition.isExternalMG;

  return !offer ? null : (
    <div className='OfferBody'>
      <Container>
        <div className='OfferBody__Wrapper'>
          <div
            className={`OfferBody__Main
              ${offer?.definition?.article ? 'OfferBody__Main--Wider' : ''}
              `}
          >
            {offer?.descriptionLong && (
              <div className='OfferBody__DescriptionLong'>
                <TextRenderer
                  content={offer.descriptionLong}
                  plainTextClassName='OfferBody__DescriptionPlainText'
                />
              </div>
            )}
            {offer?.definition?.article?.content && (
              <div className='OfferBody__ArticleContent'>
                <ArticleContent offer={offer} isPreview={isPreview} />
              </div>
            )}
            {offer?.partner && offer?.partnerDescription && (
              <div className='OfferBody__Partner'>
                <h2 className='OfferBody__PartnerHeadline'>
                  {resource('offer.partner.headline', {
                    partner: isExternalMG ? offer.title : offer.partner
                  })}
                </h2>
                <div className='OfferBody__PartnerDescription'>
                  <TextRenderer content={offer.partnerDescription} />
                </div>
              </div>
            )}
            <div className='OfferBody__Data'>
              {address && (
                <div className='OfferBody__DataItem'>
                  <div className='OfferBody__DataItemHeadline'>
                    {resource('offer.address.headline')}
                  </div>
                  <div className='OfferBody__DataItemContent'>
                    <TextRenderer content={{ type: 'text/plain', value: address }} />
                  </div>
                </div>
              )}
              {offer.infoWebsite && (
                <div className='OfferBody__DataItem'>
                  <div className='OfferBody__DataItemHeadline'>
                    {resource('offer.website.label')}
                  </div>
                  <div className='OfferBody__DataItemContent'>
                    <TextLink
                      title={offer.infoWebsite}
                      target='_blank'
                      href={sanitizeWebUrl(offer.infoWebsite)}
                    />
                  </div>
                </div>
              )}
              {offer.infoPhone && (
                <div className='OfferBody__DataItem'>
                  <div className='OfferBody__DataItemHeadline'>{resource('offer.phone.label')}</div>
                  <div className='OfferBody__DataItemContent'>
                    <TextLink
                      title={offer.infoPhone}
                      target='_blank'
                      href={sanitizePhoneUrl(offer.infoPhone)}
                    />
                  </div>
                </div>
              )}
            </div>
            {offer?.partnerLogo && (
              <img
                className='OfferBody__PartnerLogo'
                alt={offer.partner || offer.title}
                src={offer.partnerLogo.location}
              />
            )}
          </div>
          {conditions && (
            <div className='OfferBody__Sidebar'>
              <div className='OfferBody__Conditions'>
                <h4 className='OfferBody__ConditionsHeadline'>
                  {resource('offer.conditions.headline')}
                </h4>
                <div className='OfferBody__ConditionsContent'>
                  <TextRenderer content={conditions} />
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

OfferBody.propTypes = {
  offer: PropTypes.any,
  isPreview: PropTypes.bool
};

export default OfferBody;
