import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import scrollToTop from '../../helpers/scrollToTop';
import useRedeem from '../../hooks/useRedeem';
import useResources from '../../hooks/useResources';
import useValid from '../../hooks/useValid';
import { setModal } from '../../redux/reducers/modal';
import Button from '../atoms/Button';
import CircleSpinner from '../atoms/CircleSpinner';
import OfferNotValid from '../atoms/OfferNotValid';
import Overlay from '../atoms/Overlay';
import BookmarkButton from './BookmarkButton';
import './RedeemForm.scss';

const RedeemForm = ({ offer }) => {
  const dispatch = useDispatch();
  const resource = useResources();

  // Check validity
  const [validBusy, valid, invalidMessage, allowBookmark] = useValid(offer, 'form');

  // For shopify redirect
  const [idToRedeem, setIdToRedeem] = useState(null);
  const [redeemBusy, redeemResponse, redeemError] = useRedeem(idToRedeem, 'form');
  const [redirectModalUrl, setRedirectModalUrl] = useState(null);

  if (!offer.definition?.form) {
    return null;
  }
  const redeemButton =
    offer.definition?.form?.buttonRedeemUsedName || resource('offer.form.redeemButton');

  // Open shop deeplink in new tab and then reset
  useEffect(() => {
    if (redeemResponse?.target) {
      scrollToTop();
      setRedirectModalUrl(redeemResponse?.target);
      setIdToRedeem(null);
    }
  }, [redeemResponse?.target]);

  const redeem = () => setIdToRedeem(offer?.id);

  // Show errors, if any occurred, and then reset
  useEffect(() => {
    if (redeemError) {
      scrollToTop();
      dispatch(setModal(resource('offer.redeemError.title'), redeemError));
      setIdToRedeem(null);
    }
  }, [redeemError]);
  return (
    <div className='RedeemForm'>
      <div
        className={`RedeemForm__Busy ${validBusy || redeemBusy ? 'RedeemForm__Busy--Visible' : ''}`}
      >
        <CircleSpinner size={40} />
      </div>
      {!valid ? null : (
        <div className='RedeemForm__Submit'>
          <Button title={redeemButton} disabled={redeemBusy} fullWidth onClick={redeem} />
        </div>
      )}
      {valid || validBusy ? null : <OfferNotValid message={invalidMessage} />}
      <BookmarkButton bookmarkable={allowBookmark} offerId={offer.id} />
      <Overlay
        visible={!!redirectModalUrl}
        onClose={() => setRedirectModalUrl(null)}
        showCloseButton
      >
        <h3 className='RedeemForm__ModalTitle'>{resource('offer.form.redirect.title')}</h3>
        <p className='RedeemForm__ModalMessage'>{resource('offer.form.redirect.text')}</p>
        <div className='RedeemForm__ModalButton'>
          <Button
            title={resource('offer.form.redirect.button')}
            onClick={() => {
              window.open(redirectModalUrl);
              setRedirectModalUrl(null);
            }}
          />
        </div>
      </Overlay>
    </div>
  );
};

RedeemForm.propTypes = {
  offer: PropTypes.any
};

export default RedeemForm;
