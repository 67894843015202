import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from '../../redux/store';
import { PersistGate } from 'redux-persist/es/integration/react';

const withStore = BaseComponent => {
  const WithStoreComponent = props => {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BaseComponent persistenceReady={true} {...props} />
        </PersistGate>
      </Provider>
    );
  };
  WithStoreComponent.displayName = 'withStore';
  return WithStoreComponent;
};

export default withStore;
