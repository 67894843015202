import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourNext, TourState } from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { Blinds } from '../../atoms/Blinds';
import { Anchor, Flyout } from '../../layout/Flyout';
import { TourCalloutAbort } from './TourCalloutAbort';
import { Scroller } from '../../atoms/Scroller';

export function TourCompletion() {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutCompletion;
  const dispatch = useDispatch();
  const handleNext = useCallback(() => dispatch(tourNext()), [dispatch]);
  const packed = useBreakpoint('md-down');

  if (skip) return null;
  return (
    <Fragment>
      <Blinds active={active} radius={0} inset={12} />
      <Flyout
        active={active}
        anchorFrom={packed ? Anchor.BottomRight : Anchor.BottomCenter}
        anchorAt={packed ? Anchor.TopRight : Anchor.TopCenter}
        align={packed ? 'end' : 'center'}
        offset={25}
      >
        <TourCalloutAbort
          button='Anleitung abschließen'
          onClick={handleNext}
          head={packed ? 'end' : 'center'}
        >
          <Scroller active={active} scroll='top' />
          Wir wünschen Dir viel Freude mit unseren Angeboten und Events! Falls Du diese Anleitung
          später noch einmal starten möchtest, kannst Du das hier tun.
        </TourCalloutAbort>
      </Flyout>
    </Fragment>
  );
}
