import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Colors from '../../definitions/colors';
import CircleIcon, { CircleIconDefaultProps, CircleIconPropTypes } from './CircleIcon';
import './IconButton.scss';

const IconButton = ({
  color,
  disabled,
  href,
  iconCircle,
  labelPosition,
  onClick,
  showLabel,
  target,
  title,
  to
}) => {
  const commonProps = {
    className: `IconButton IconButton--LabelPosition-${labelPosition}`,
    style: { color },
    disabled,
    title
  };

  const content = (
    <>
      <CircleIcon {...iconCircle} />
      {showLabel && <div className='IconButton__Label'>{title}</div>}
    </>
  );

  const props = {
    ...(href && { href, target }),
    ...(onClick && { onClick }),
    ...(to && { to }),
    ...commonProps
  };

  const element = (to && Link) || (href && 'a') || 'button';
  return React.createElement(element, props, content);
};

IconButton.propTypes = {
  color: PropTypes.string,
  iconCircle: PropTypes.shape(CircleIconPropTypes).isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  labelPosition: PropTypes.oneOf(['LEFT', 'RIGHT']),
  onClick: PropTypes.func,
  showLabel: PropTypes.bool,
  target: PropTypes.oneOf(['_self', '_blank']),
  title: PropTypes.string.isRequired,
  to: PropTypes.any
};

IconButton.defaultProps = {
  color: Colors.Orange,
  labelPosition: 'RIGHT',
  iconCircle: CircleIconDefaultProps
};

export default IconButton;
