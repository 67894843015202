import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourAbort, tourNext, TourState } from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import { Blinds } from '../../atoms/Blinds';
import { Flyout } from '../../layout/Flyout';
import { TourCallout } from './TourCallout';
import { Scroller } from '../../atoms/Scroller';

export function TourPdf() {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutPdf;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => dispatch(tourAbort()), [dispatch]);
  const handleNext = useCallback(() => dispatch(tourNext()), [dispatch]);
  useHistoryListener(() => {
    if (active) dispatch(tourAbort('navigated'));
  }, [active, dispatch]);
  if (skip) return null;
  return (
    <Fragment>
      <Blinds
        active={active}
        insetLeft={30}
        insetRight={30}
        insetTop={15}
        insetBottom={15}
        radius={0}
      />
      <Flyout active={active}>
        <TourCallout
          button='Verstanden'
          stepAt={7}
          stepTotal={12}
          distance={30}
          onNext={handleNext}
          onClose={handleAbort}
        >
          <Scroller active={active} />
          Wenn Du den Code lieber ausdrucken und mitnehmen möchtest, kannst Du hier eine PDF-Datei
          mit Deinem Code herunterladen.
        </TourCallout>
      </Flyout>
    </Fragment>
  );
}
