import MenuItem from '../atoms/MenuItem';
import React, { useEffect, useState } from 'react';
import Routes from '../../definitions/routes';
import LogoEnBo from '../../images/logo-eb.svg';
import LogoEvm from '../../images/logo-evm.svg';
import Container from '../layout/Container';
import './Header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectBookmarksCount } from '../../redux/reducers/bookmarks';
import useResources from '../../hooks/useResources';
import { Link, useHistory } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';
import { SignOut } from '../../images/icons';
import Colors from '../../definitions/colors';
import { selectTourAt, tourNext, tourStart, TourState } from '../../redux/reducers/tour';
import MenuButton from '../atoms/MenuButton';
import { Box } from '../layout/Box';
import { TourRestarting } from './tour/TourRestarting';
import { TourBookmarks } from './tour/TourBookmarks';
import { TourCompletion } from './tour/TourCompletion';

const Header = () => {
  // Use for navigation with tour.
  const history = useHistory();

  // Use dispatch for tour action.
  const dispatch = useDispatch();

  const at = useSelector(selectTourAt);
  const atBookmarks = at === TourState.aboutBookmarks;
  const atRestarting = at === TourState.aboutRestarting;
  const atCompletion = at === TourState.aboutCompletion;

  // Scroll logic
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handler = () => setScrolled(window.scrollY > 100);
    window.addEventListener('scroll', handler, { passive: true });
    return () => window.removeEventListener('scroll', handler);
  }, []);

  // Translations
  const resource = useResources();

  // Bookmark count
  const bookmarkCount = useSelector(selectBookmarksCount);

  // Token in local storage
  const [, updateTokenInStorage] = useLocalStorage('token');

  return (
    <div className={`Header ${scrolled ? 'Header--Scrolled' : ''}`}>
      <div className='Header__Meta'>
        <Container>
          <div className='Header__MetaMenu'>
            <Link
              title={resource('header.enBo.title')}
              to='/'
              className='Header__MetaLink Header__MetaLink--Active'
            >
              {resource('header.enBo.title')}
            </Link>
            <a
              href={process.env.REACT_APP_EVM_SSP_DASHBOARD}
              className='Header__MetaLink'
              rel='noreferrer'
            >
              {resource('header.customerPortal.title')}
            </a>
            <a
              href={resource('header.evm.url')}
              title={resource('header.evm.title')}
              target='_blank'
              className='Header__MetaLink'
              rel='noreferrer'
            >
              {resource('header.evm.title')}
            </a>
            <div className='Header__MetaSpacer' />
            <a
              href={process.env.REACT_APP_EVM_SSP_LOGOUT}
              title={'Abmelden'}
              className='Header__MetaLink Header__MetaLink--SignOut'
              onClick={() => updateTokenInStorage(null)}
            >
              <span className='Header__MetaSignOutText'>{resource('header.signOut')}</span>
              <SignOut fill={Colors.Gray} size={15} />
            </a>
          </div>
        </Container>
      </div>
      <div className='Header__Main'>
        <Container>
          <div className='Header__MainWrapper'>
            <Link
              to={Routes.Index()}
              title={resource('header.enBo.title')}
              className='Header__EvmLogoLink'
            >
              <img
                src={LogoEvm}
                alt={resource('header.enBo.title')}
                className='Header__EvmLogoImage'
              />
            </Link>
            <div className='Header__Menu'>
              <MenuItem
                to={Routes.Index()}
                onlyAuthenticated
                title={resource('header.menu.offers')}
                noMatch={[Routes.Bookmarks(), Routes.Profile()]}
                width={64}
              />
              <Box row active={atBookmarks}>
                <TourBookmarks />
                <MenuItem
                  to={Routes.Bookmarks()}
                  onClick={() => {
                    if (atBookmarks) dispatch(tourNext());
                  }}
                  onlyAuthenticated
                  title={resource('header.menu.bookmarks')}
                  badge={bookmarkCount}
                  width={100}
                />
              </Box>
              <MenuItem
                to={Routes.Profile()}
                onlyAuthenticated
                title={resource('header.menu.profile')}
                width={70}
              />
              <Box row active={atRestarting || atCompletion}>
                <TourRestarting />
                <TourCompletion />
                <MenuButton
                  onClick={() => {
                    history.push('/');
                    dispatch(tourStart());
                  }}
                  title={resource('header.menu.tour')}
                  width={126}
                />
              </Box>
            </div>
            <Link
              to={Routes.Index()}
              title={resource('header.enBo.title')}
              className='Header__EnBoLogoLink'
            >
              <img
                src={LogoEnBo}
                alt={resource('header.enBo.title')}
                className='Header__EnBoLogoImage'
              />
            </Link>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Header;
