import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useHistoryListener = (cb, deps) => {
  const history = useHistory();
  useEffect(
    () => {
      // Create handler for pop-state.
      const popStateHandler = () => cb(history.location);

      // Add pop-state handler for back navigation.
      window.addEventListener('popstate', popStateHandler);

      // Register history listener.
      const unmountHistoryListener = history.listen(cb);

      // Return cleanup.
      return () => {
        // Unmount history listener.
        unmountHistoryListener();

        // Remove handler.
        window.removeEventListener('popstate', popStateHandler);
      };
    },
    deps ? [history, ...deps] : [history]
  );
};
