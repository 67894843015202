import React from 'react';
import { Helmet } from 'react-helmet';

const pageTitle = (title = null) => {
  return (
    <Helmet defaultTitle={'evm Entdecker-Bonus'} titleTemplate='%s - evm Entdecker-Bonus'>
      <title>{title}</title>
    </Helmet>
  );
};
export default pageTitle;
