import React from 'react';
import PropTypes from 'prop-types';
import TextRenderer from '../layout/TextRenderer';
import useValid from '../../hooks/useValid';
import CircleSpinner from '../atoms/CircleSpinner';
import OfferNotValid from '../atoms/OfferNotValid';
import './ArticleContent.scss';
import useResources from '../../hooks/useResources';

const ArticleContent = ({ offer, isPreview }) => {
  const resource = useResources();
  // Check validity
  const [validBusy, valid, invalidMessage] = useValid(offer, 'article');

  // Shortcut for preview mode
  if (isPreview) {
    return (
      <div className='ArticleContent'>
        <div className='ArticleContent__PreviewMessage'>
          <OfferNotValid message={resource('preview.info.article')} />
        </div>
        <TextRenderer content={offer.definition.article.content} />
      </div>
    );
  }

  return (
    <div className='ArticleContent'>
      <div className={`RedeemQrCode__Busy ${validBusy ? 'RedeemQrCode__Busy--Visible' : ''}`}>
        <CircleSpinner size={40} />
      </div>
      {validBusy || valid ? null : <OfferNotValid message={invalidMessage} />}
      {!valid ? null : <TextRenderer content={offer.definition.article.content} />}
    </div>
  );
};

ArticleContent.propTypes = {
  offer: PropTypes.any,
  isPreview: PropTypes.bool
};

export default ArticleContent;
