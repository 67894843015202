import { Adventure, Culinary, Culture, Energy, Myhome, Recreation } from '../images/icons';

/**
 * Icons associated to interests by name.
 * @type {Readonly<{adventure: ((function({size: *, fill: *, [p: string]: *}): *)|*), myhome: ((function({size: *, fill: *, [p: string]: *}): *)|*), culture: ((function({size: *, fill: *, [p: string]: *}): *)|*), recreation: ((function({size: *, fill: *, [p: string]: *}): *)|*), culinary: ((function({size: *, fill: *, [p: string]: *}): *)|*), energy: ((function({size: *, fill: *, [p: string]: *}): *)|*)}>}
 */
export const interestIcons = Object.freeze({
  energy: Energy,
  culinary: Culinary,
  culture: Culture,
  recreation: Recreation,
  adventure: Adventure,
  myhome: Myhome
});
