import { useCallback, useState } from 'react';

const serialize = value => (value === undefined ? 'undefined' : JSON.stringify(value));
const deserialize = value => (value === 'undefined' ? undefined : JSON.parse(value));

/**
 * Uses a session storage persisted state. This state is not shared for different calls of same key.
 * @template T
 * @param key The key of the state in the storage.
 * @param init The initial value or initializer function.
 * @return {[T,(function(T|function(T):T): void)]}
 */
export const useSessionState = (key, init) => {
  // State with initializer.
  const [state, setState] = useState(() => {
    // Init by getting from session storage.
    const stored = sessionStorage.getItem(key);

    // Check if it was set in storage.
    if (stored === null) {
      // Not set, create, serialize and write.
      const value = typeof init === 'function' ? init() : init;
      sessionStorage.setItem(key, serialize(value));
      return value;
    } else {
      // Stored, return deserialized.
      return deserialize(stored);
    }
  });

  const setSessionState = useCallback(
    valueOrUpdate => {
      setState(current => {
        const value = typeof valueOrUpdate === 'function' ? valueOrUpdate(current) : valueOrUpdate;
        sessionStorage.setItem(key, serialize(value));
        return value;
      });
    },
    [key]
  );

  return [state, setSessionState];
};
