import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './Scroller.scss';

/**
 * On active scrolls into view after a delay. Uses absolute inset zero positioning to container.
 * @param active True if scroll to.
 * @param delay The delay before scrolling.
 * @param scroll The scroll destination.
 * @return {JSX.Element}
 * @constructor
 */
export const Scroller = ({ active, delay = 500, scroll = 'start' }) => {
  /** @type {React.MutableRefObject<HTMLDivElement|null>} */
  const ref = useRef(null);

  const scrollToTarget = active && scroll;

  useLayoutEffect(() => {
    if (!scrollToTarget) return;
    if (!ref.current) return;

    const handle = setTimeout(() => {
      if (scrollToTarget === 'top') {
        window.scroll({ behavior: 'smooth', top: 0 });
      } else {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: scrollToTarget,
          inline: 'nearest'
        });
      }
    }, delay);
    return () => {
      clearTimeout(handle);
    };
  }, [ref, scrollToTarget, delay]);

  return <div ref={ref} className='Scroller' />;
};

Scroller.propTypes = {
  active: PropTypes.bool,
  delay: PropTypes.number,
  scroll: PropTypes.oneOf(['center', 'end', 'nearest', 'start', 'top'])
};
