import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTileDimensions from '../../hooks/useTileDimensions';
import { selectToken } from '../../redux/reducers/auth';
import { toggleBookmark } from '../../redux/reducers/bookmarks';
import './OfferGrid.scss';
import OfferTile from './OfferTile';

const OfferGrid = ({ className, bookmarks, offers }) => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const { gap, width, perPage } = useTileDimensions();

  return (
    <div className={`OfferGrid ${className}`}>
      <div
        className='OfferGrid__Viewport'
        style={{ margin: `0 ${gap / -2}px`, width: `${(width + gap) * perPage}px` }}
      >
        {bookmarks &&
          bookmarks.map(bookmark => (
            <OfferTile
              key={bookmark.offer.id}
              offer={bookmark.offer}
              isExpired={bookmark.expired}
              onRemoveBookmark={() => dispatch(toggleBookmark(bookmark.offer.id, false, token))}
              context='GRID'
            />
          ))}
        {offers && offers.map(offer => <OfferTile key={offer.id} offer={offer} context='GRID' />)}
      </div>
    </div>
  );
};

OfferGrid.propTypes = {
  className: PropTypes.string,
  bookmarks: PropTypes.arrayOf(PropTypes.object),
  offers: PropTypes.arrayOf(PropTypes.object)
};

export default OfferGrid;
