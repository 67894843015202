import React, { useEffect, useState } from 'react';
import OnboardingQuestion from '../OnboardingQuestion';
import Button from '../../../atoms/Button';
import './OnboardingInterests.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectPickedInterests, togglePickedInterest } from '../../../../redux/reducers/onboarding';
import { selectInterests } from '../../../../redux/reducers/interests';
import PropTypes from 'prop-types';
import TextLink from '../../../atoms/TextLink';
import Checkbox from '../../../atoms/inputs/Checkbox';
import useResources from '../../../../hooks/useResources';
import reactStringReplace from 'react-string-replace';

const OnboardingInterests = ({ onValidate }) => {
  const interests = useSelector(selectInterests);
  const pickedInterests = useSelector(selectPickedInterests);
  const dispatch = useDispatch();
  const resource = useResources();
  const [acceptTerms, setAcceptTerms] = useState(false);

  useEffect(() => {
    onValidate(pickedInterests.length > 0 && acceptTerms);
  }, [acceptTerms, pickedInterests, onValidate]);

  const interestIsPicked = key => pickedInterests.indexOf(key) >= 0;
  return (
    <OnboardingQuestion question={`${resource('onboarding.interests.headline')} *`}>
      <p className='OnboardingInterests__Intro'>{resource('onboarding.interests.text')}</p>
      <div className='OnboardingInterests'>
        <div className='OnboardingInterests__Buttons'>
          {interests.map(interest => (
            <div key={interest.name} className='OnboardingInterests__Button'>
              <Button
                variant={interestIsPicked(interest.name) ? 'ORANGE_FILL' : 'ORANGE_OUTLINE'}
                onClick={() => dispatch(togglePickedInterest(interest.name))}
                title={interest.label}
                autoWidth
                slimOnMobile
              />
            </div>
          ))}
        </div>
      </div>
      <Checkbox value={acceptTerms} onUpdateValue={setAcceptTerms}>
        {reactStringReplace(
          resource('onboarding.acceptTerms.checkbox'),
          '{termsLink}',
          (_, index) => (
            <TextLink
              key={index}
              href={resource('onboarding.acceptTerms.termsLink.url')}
              target='_blank'
              title={resource('onboarding.acceptTerms.termsLink.text')}
            />
          )
        )}
      </Checkbox>
    </OnboardingQuestion>
  );
};

OnboardingInterests.propTypes = {
  onValidate: PropTypes.func.isRequired
};

export default OnboardingInterests;
