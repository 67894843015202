import { selectAllOffers, selectCampaigns } from './offers';
import { preloadImages } from '../../helpers/preloadImages';

const initialState = {
  loadingImages: false
};

export const PRELOAD_IMAGES_REQUEST = 'preload/images/REQUEST';
const requestPreloadImages = () => ({
  type: PRELOAD_IMAGES_REQUEST
});

export const PRELOAD_IMAGES_DONE = 'preload/images/DONE';
export const donePreloadImages = () => ({
  type: PRELOAD_IMAGES_DONE
});

export const requestPreload = () => {
  return (dispatch, getState) => {
    dispatch(requestPreloadImages());

    // Get data from state
    const campaigns = selectCampaigns(getState());
    const allOffers = selectAllOffers(getState());

    // Collect all images that need to be preloaded
    const imageSources = [];
    // For campaigns, load the header image, if defined
    campaigns.forEach(campaign => {
      if (campaign.header?.location) {
        if (imageSources.indexOf(campaign.header.location) < 0) {
          imageSources.push(campaign.header.location);
        }
      }
    });
    // For offers, only preload the first image
    allOffers.forEach(offer => {
      if (offer.images?.[0]?.location) {
        if (imageSources.indexOf(offer.images[0].location) < 0) {
          imageSources.push(offer.images[0].location);
        }
      }
    });

    // Now load all images
    preloadImages(imageSources).then(() => dispatch(donePreloadImages()));
  };
};

export const selectPreloadImagesBusy = state => state.preload.loadingImages;

export default function preload(state = initialState, action) {
  switch (action.type) {
    case PRELOAD_IMAGES_REQUEST: {
      return {
        ...state,
        loadingImages: true
      };
    }
    case PRELOAD_IMAGES_DONE: {
      return {
        ...state,
        loadingImages: false
      };
    }
    default:
      return state;
  }
}
