import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Routes from '../../definitions/routes';
import useResources from '../../hooks/useResources';
import useTileDimensions from '../../hooks/useTileDimensions';
import TextLink from '../atoms/TextLink';
import './OfferTile.scss';
import { formatSiteLocality } from '../../helpers/formatters';
import { Box } from '../layout/Box';
import { OptLink } from '../atoms/OptLink';
import { selectTourAt, tourNext, TourState } from '../../redux/reducers/tour';
import { useDispatch, useSelector } from 'react-redux';
import { TourOneOffer } from './tour/TourOneOffer';

const OfferTile = ({ offer, context, isExpired, onRemoveBookmark, tour }) => {
  const dispatch = useDispatch();
  const at = useSelector(selectTourAt);
  const atOneOffer = at === TourState.aboutOneOffer;

  const { width, gap } = useTileDimensions();
  const [removing, setRemoving] = useState(false);
  const resource = useResources();

  const className = isExpired
    ? `OfferTile OfferTile--Expired OfferTile--${context}`
    : `OfferTile OfferTile--${context}`;
  const style = {
    width: `${width}px`,
    margin: `${context === 'GRID' ? gap : 0}px ${gap / 2}px`
  };

  // External MG offers always have the same partner name and title, use a more appropriate
  // caption so it's not duplicated.
  const offerCaption = offer.definition?.isExternalMG ? offer.addressObject?.place : offer.partner;

  const handleRemoveBookmark = () => {
    setRemoving(true);
    onRemoveBookmark();
  };

  return (
    <Box row active={atOneOffer && tour}>
      <OptLink
        className={className}
        style={style}
        link={!isExpired}
        to={Routes.SingleOffer(offer.id)}
        onClick={() => {
          if (atOneOffer) dispatch(tourNext());
        }}
      >
        <div className='OfferTile__ImageWrapper'>
          {offer.images?.[0]?.location && (
            <img src={offer.images?.[0]?.location} alt={offer.title} className='OfferTile__Image' />
          )}
        </div>
        <div className='OfferTile__Content'>
          {!offer.siteClosest ? null : (
            <div className='OfferTile__Site'>
              {offer.geoUnrestricted ? (
                <span>online einlösbar</span>
              ) : (
                <span>{formatSiteLocality(offer.siteClosest)}</span>
              )}
            </div>
          )}
          <div className='OfferTile__Caption'>{offerCaption}</div>
          <h3 className='OfferTile__Title'>{offer.title}</h3>
          <p className='OfferTile__Excerpt'>{offer.excerpt}</p>
          {isExpired ? (
            <div className='OfferTile__RemoveBookmark'>
              {removing ? (
                resource('bookmarks.expired.removing')
              ) : (
                <TextLink
                  onClick={handleRemoveBookmark}
                  title={resource('bookmarks.expired.remove')}
                />
              )}
            </div>
          ) : (
            <div className='OfferTile__LearnMore'>{resource('offer.learnMore')}</div>
          )}
        </div>
        {!offer.badge ? null : <div className='OfferTile__Badge'>{offer.badge}</div>}
      </OptLink>
      {!tour ? null : (
        <div className='OfferTile__TourInsets'>
          <TourOneOffer />
        </div>
      )}
    </Box>
  );
};

OfferTile.propTypes = {
  offer: PropTypes.object.isRequired,
  context: PropTypes.oneOf(['SLIDER', 'GRID']).isRequired,
  isExpired: PropTypes.bool,
  onRemoveBookmark: PropTypes.func,
  tour: PropTypes.bool
};

export default OfferTile;
