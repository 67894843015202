import React from 'react';
import PropTypes from 'prop-types';
import './OnboardingNavigation.scss';
import ProgressBar from '../../atoms/ProgressBar';
import IconButton from '../../atoms/IconButton';
import Colors from '../../../definitions/colors';
import { Left, Right } from '../../../images/icons';
import useResources from '../../../hooks/useResources';

const OnboardingNavigation = ({
  currentStep,
  maxSteps,
  canGoNext,
  onGoNext,
  canGoBack,
  onGoBack
}) => {
  const resource = useResources();
  return (
    <div className='OnboardingNavigation'>
      <div className='OnboardingNavigation__Orientation'>
        <div className='OnboardingNavigation__Index'>
          {resource('onboarding.navigation.steps', { current: currentStep, max: maxSteps })}
        </div>
        <div className='OnboardingNavigation__ProgressBar'>
          <ProgressBar value={currentStep} max={maxSteps} />
        </div>
      </div>
      <div className='OnboardingNavigation__Button'>
        <IconButton
          onClick={onGoBack}
          disabled={!canGoBack}
          title={resource('onboarding.navigation.back')}
          iconCircle={{ icon: <Left fill={Colors.White} size={11} />, offsetX: -1 }}
        />
      </div>
      <div className='OnboardingNavigation__Button'>
        <IconButton
          onClick={onGoNext}
          disabled={!canGoNext}
          title={resource('onboarding.navigation.next')}
          iconCircle={{ icon: <Right fill={Colors.White} size={11} />, offsetX: 1 }}
        />
      </div>
    </div>
  );
};

OnboardingNavigation.propTypes = {
  currentStep: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  canGoNext: PropTypes.bool,
  onGoNext: PropTypes.func.isRequired,
  canGoBack: PropTypes.bool,
  onGoBack: PropTypes.func.isRequired
};

export default OnboardingNavigation;
