import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../definitions/colors';
import { StarFilled, StarUnfilled } from '../../images/icons';
import { selectToken } from '../../redux/reducers/auth';
import {
  selectLoading,
  selectOfferIsBookmarked,
  selectWorking,
  toggleBookmark
} from '../../redux/reducers/bookmarks';
import Button from '../atoms/Button';
import CircleSpinner from '../atoms/CircleSpinner';
import './BookmarkButton.scss';

const BookmarkButton = ({ bookmarkable, offerId, onToggle, atom }) => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const working = useSelector(selectWorking);
  const loading = useSelector(selectLoading);
  const bookmarkStatus = useSelector(selectOfferIsBookmarked(offerId));

  const handleToggle = useCallback(() => {
    if (onToggle) onToggle();
    dispatch(toggleBookmark(offerId, !bookmarkStatus, token));
  }, [offerId, bookmarkStatus, token, onToggle]);

  return !bookmarkable && !bookmarkStatus ? null : (
    <div className={atom ? 'BookmarkButton' : 'BookmarkButton BookmarkButton--nofix'}>
      <div
        className={`BookmarkButton__Busy ${
          working || loading ? 'BookmarkButton__Busy--Visible' : ''
        }`}
      >
        <CircleSpinner size={40} />
      </div>
      <Button
        fullWidth={!atom}
        variant='ORANGE_OUTLINE'
        onClick={handleToggle}
        title={bookmarkStatus ? 'Vorteil gemerkt' : 'Für später merken'}
        icon={
          bookmarkStatus ? (
            <StarFilled fill={Colors.Orange} size={24} />
          ) : (
            <StarUnfilled fill={Colors.Orange} size={24} />
          )
        }
      />
    </div>
  );
};

BookmarkButton.propTypes = {
  bookmarkable: PropTypes.bool,
  offerId: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
  atom: PropTypes.bool
};

export default BookmarkButton;
