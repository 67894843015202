import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { classes } from '../../helpers/styles';
import './Box.scss';

const disableBodyClass = 'Box__DisableBody';
/**
 * Input isolator, Z-elevator, input disabler box.
 * @param className Standard CSS class names.
 * @param row True if row flex child.
 * @param col True if column flex child.
 * @param active True if isolation/elevation/input disabling should be applied.
 * @param elevate True if elevation should be applied on active.
 * @param isolate True if isolation should be applied on active, will limit input to this box unless disabled.
 * @param disabled True if input should not be enabled for this box on active.
 * @param children The children to wrap.
 * @param rest Extra properties to the root div.
 * @return {JSX.Element}
 * @constructor
 */
export const Box = ({
  className,
  row,
  col,
  active,
  elevate = true,
  isolate = true,
  disabled = false,
  children,
  ...rest
}) => {
  const ref = useRef();

  // Semantics of activation.
  const disableBody = active && isolate;
  const elevateBox = active && elevate;
  const enableSelf = active && !disabled;

  // Connect isolation.
  useLayoutEffect(() => {
    // If not disabling or already disabled, stop.
    if (!disableBody) return;
    if (document.body.classList.contains(disableBodyClass)) return;

    // Add disabling body class and return removing it on unhook.
    document.body.classList.add(disableBodyClass);
    return () => {
      document.body.classList.remove(disableBodyClass);
    };
  }, [disableBody]);

  // Concat classes.
  const appliedClassName = classes(
    className,
    'Box',
    row && 'Box--row',
    col && 'Box--col',
    elevateBox && 'Box--elevate',
    enableSelf && 'Box--enable'
  );

  return (
    <div ref={ref} className={appliedClassName} {...rest}>
      {children}
    </div>
  );
};

Box.propTypes = {
  className: PropTypes.string,
  row: PropTypes.bool,
  col: PropTypes.bool,
  active: PropTypes.bool,
  elevate: PropTypes.bool,
  isolate: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node
};
