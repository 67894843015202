import React from 'react';
import Container from '../layout/Container';
import imageApp from '../../images/app.png';
import imageBadgeGoogle from '../../images/badge_playstore.svg';
import imageBadgeApple from '../../images/badge_appstore.svg';
import './AppTeaser.scss';
import useResources from '../../hooks/useResources';

const AppTeaser = () => {
  const resource = useResources();
  return (
    <div className='AppTeaser'>
      <Container>
        <div className='AppTeaser__Container'>
          <div className='AppTeaser__ContentWrapper'>
            <h1 className='AppTeaser__Title'>{resource('appTeaser.headline')}</h1>
            <p className='AppTeaser__Text'>{resource('appTeaser.text')}</p>
            <h3 className='AppTeaser__Download'>{resource('appTeaser.downloadNow')}</h3>
            <div className='AppTeaser__Badges'>
              <a
                href={resource('appTeaser.googlePlay.url')}
                title={resource('appTeaser.googlePlay.title')}
                rel='noopener noreferrer'
                target='_blank'
                className='AppTeaser__Badge AppTeaser__Badge--GooglePlay'
              >
                <img
                  src={imageBadgeGoogle}
                  alt={resource('appTeaser.googlePlay.title')}
                  className='AppTeaser__BadgeImage'
                />
              </a>
              <a
                href={resource('appTeaser.appStore.url')}
                title={resource('appTeaser.appStore.title')}
                rel='noopener noreferrer'
                target='_blank'
                className='AppTeaser__Badge AppTeaser__Badge--AppStore'
              >
                <img
                  src={imageBadgeApple}
                  alt={resource('appTeaser.appStore.title')}
                  className='AppTeaser__BadgeImage'
                />
              </a>
            </div>
          </div>
          <div className='AppTeaser__ImageWrapper'>
            <img src={imageApp} alt={resource('appTeaser.headline')} className='AppTeaser__Image' />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AppTeaser;
