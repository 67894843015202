import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './Dropdown.scss';
import './LocationSelect.scss';
import { select } from '../../helpers/handlers';
import Colors from '../../definitions/colors';
import { Close, Down, MapPin } from '../../images/icons';
import { useClickOutside } from '../../hooks/useClickOutside';
import CircleSpinner from './CircleSpinner';
import { useDebounce } from '../../hooks/useDebounce';

/**
 * Returns true if ZIP.
 * @param text The text to check.
 * @return {boolean}
 */
const isZip = text => /^[0-9]{5}$/.test(text);

/**
 * Returns true if are location filter values assigned in the input.
 * @param location The value to check.
 * @return {*}
 */
export const locationSet = location =>
  location?.zip ||
  location?.name ||
  location?.latitude ||
  location?.longitude ||
  location?.distance;

export const LocationSelected = Object.freeze({
  fromText: Symbol('fromText'),
  fromDistance: Symbol('fromDistance'),
  fromCoordinate: Symbol('fromCoordinate'),
  fromClear: Symbol('fromClear')
});

/**
 * Location selection element,
 * @param className Main class element.
 * @param style Extra styles.
 * @param location The location filter object or empty.
 * @param setLocation Invoked on location selection change.
 * @param onConfirm Invoked when the input is confirmed with enter in the find text box.
 * @return {JSX.Element}
 * @constructor
 */
export const LocationSelect = ({ className, style, location, setLocation, onConfirm }) => {
  // Reference for click outside handling.
  const ref = useRef(null);

  // State of dropdown open.
  const [open, setOpen] = useState(false);
  const [acquiring, setAcquiring] = useState(false);

  // Use debounce for quick acquisition.
  const acquiringDebounce = useDebounce(acquiring);

  // On click outside, close.
  useClickOutside(ref, () => setOpen(false));

  // Flags from input.
  const hasLocation = locationSet(location);
  const hasGeo = location?.longitude && location?.latitude;

  // Update the name or ZIP input with the event target element's value.
  const updateInput = useCallback(
    event => {
      const text = event.target.value;
      setOpen(false);
      setLocation(
        {
          zip: isZip(text) ? Number(text) : undefined,
          name: isZip(text) ? undefined : text,
          latitude: undefined,
          longitude: undefined,
          distance: location?.distance
        },
        LocationSelected.fromText
      );
    },
    [location, setLocation]
  );

  // Updates the distance.
  const updateDistance = useCallback(
    event => {
      const value = Number(event.target.dataset.value) || null;
      setOpen(false);
      setLocation(
        {
          zip: location?.zip,
          name: location?.name,
          latitude: value ? location?.latitude : undefined,
          longitude: value ? location?.longitude : undefined,
          distance: value ? value : undefined
        },
        LocationSelected.fromDistance
      );
    },
    [location, setLocation]
  );

  // Updates the coordinates from the navigator.
  const updatePickCoordinate = useCallback(() => {
    setOpen(false);
    setAcquiring(true);
    navigator.geolocation.getCurrentPosition(
      result => {
        setAcquiring(false);
        setLocation(
          {
            zip: undefined,
            name: undefined,
            latitude: result.coords.latitude,
            longitude: result.coords.longitude,
            distance: location?.distance ? location?.distance : 5
          },
          LocationSelected.fromCoordinate
        );
      },
      () => {
        setAcquiring(false);
      }
    );
  }, [location, setLocation]);

  // Clears the location.
  const clearLocation = useCallback(() => {
    setLocation(undefined, LocationSelected.fromClear);
    setOpen(false);
  }, [setLocation]);

  // Configure confirm capturing key handler.
  const captureConfirm = useCallback(
    event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        if (onConfirm) onConfirm();
      }
    },
    [onConfirm]
  );

  // Toggles the dropdown open state.
  const toggleOpen = useCallback(() => setOpen(open => !open), []);

  return (
    <div className={`LocationSelect ${className}`} style={style} ref={ref}>
      <label htmlFor='location'>
        <input
          type='text'
          name='location'
          data-has-geo={hasGeo}
          placeholder={hasGeo ? 'Dein Standort' : 'PLZ oder Ort'}
          value={location?.zip?.toString() ?? location?.name ?? ''}
          onChange={updateInput}
          onKeyDown={captureConfirm}
        />
        {acquiringDebounce ? (
          <CircleSpinner className='LocationSelect__Acquiring' size={20} />
        ) : (
          <MapPin
            className='LocationSelect__Pin'
            size={20}
            fill={hasGeo ? Colors.Orange : Colors.Gray}
            onClick={select(updatePickCoordinate)}
            onKeyDown={select(updatePickCoordinate)}
          />
        )}
        {!hasLocation ? null : (
          <Close
            className='LocationSelect__Clear'
            size={12}
            fill={Colors.Black}
            onClick={select(clearLocation)}
            onKeyDown={select(clearLocation)}
          />
        )}
      </label>
      <div className={`Dropdown Dropdown--Gray ${open ? 'Dropdown--Open' : ''}`}>
        <div
          className='Dropdown__Header'
          onClick={select(toggleOpen)}
          onKeyDown={select(toggleOpen)}
          tabIndex={0}
          role='button'
          aria-expanded={open}
        >
          <span>{location?.distance ? `+ ${location?.distance} km` : 'Ganzer Ort'}</span>
          <Down className='Dropdown__Caret' size={12} fill={Colors.Black} />
        </div>
        <div className='Dropdown__Select'>
          <div className='Dropdown__Item' data-value={0} onClick={select(updateDistance)}>
            Ganzer Ort
          </div>
          <div className='Dropdown__Item' data-value={5} onClick={select(updateDistance)}>
            + 5 km
          </div>
          <div className='Dropdown__Item' data-value={10} onClick={select(updateDistance)}>
            + 10 km
          </div>
          <div className='Dropdown__Item' data-value={20} onClick={select(updateDistance)}>
            + 20 km
          </div>
          <div className='Dropdown__Item' data-value={50} onClick={select(updateDistance)}>
            + 50 km
          </div>
          <div className='Dropdown__Item' data-value={100} onClick={select(updateDistance)}>
            + 100 km
          </div>
        </div>
      </div>
    </div>
  );
};

LocationSelect.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  location: PropTypes.shape({
    zip: PropTypes.number,
    name: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    distance: PropTypes.number
  }),
  setLocation: PropTypes.func.isRequired,
  onConfirm: PropTypes.func
};
