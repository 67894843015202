import React from 'react';
import PropTypes from 'prop-types';
import './IconHighlight.scss';
import CircleIcon, { CircleIconDefaultProps, CircleIconPropTypes } from './CircleIcon';

const IconHighlight = ({ iconCircle, title }) => {
  return (
    <div className='IconHighlight'>
      <CircleIcon {...iconCircle} />
      <div className='IconHighlight__Label'>{title}</div>
    </div>
  );
};

IconHighlight.propTypes = {
  iconCircle: PropTypes.shape(CircleIconPropTypes),
  title: PropTypes.string.isRequired
};

IconHighlight.defaultProps = {
  iconCircle: CircleIconDefaultProps
};

export default IconHighlight;
