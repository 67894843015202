import { useCallback, useState } from 'react';
import useOnResize from './useOnResize';

const applyDef = (def, width) => {
  switch (def) {
    case 'lg':
      return width >= 1024;

    case 'md-up':
      return width >= 768;
    case 'md':
      return 1024 > width && width >= 768;
    case 'md-down':
      return 1024 > width;

    case 'sm-up':
      return width >= 575;
    case 'sm':
      return 768 > width && width >= 575;
    case 'sm-down':
      return 768 > width;

    case 'xs':
      return 575 > width;
    default:
      return false;
  }
};
export const useBreakpoint = def => {
  const [result, setResult] = useState(() => applyDef(document.body.clientWidth));
  const callback = useCallback(() => {
    setResult(applyDef(def, document.body.clientWidth));
  }, [def]);
  useOnResize(callback);

  return result;
};
