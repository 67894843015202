import { apiRequest } from '../../api/api';

const initialState = {
  loading: false,
  data: []
};

export const INTERESTS_REQUEST = 'interests/REQUEST';
const requestInterests = () => ({
  type: INTERESTS_REQUEST
});

export const INTERESTS_RECEIVE = 'interests/RECEIVE';
const receiveInterests = data => ({
  type: INTERESTS_RECEIVE,
  payload: data
});

export const INTERESTS_FAILURE = 'interests/FAILURE';
const failureInterests = message => ({
  type: INTERESTS_FAILURE,
  payload: {
    message
  }
});

export const fetchInterests = () => {
  return dispatch => {
    dispatch(requestInterests());
    return apiRequest(null, '/interests')
      .then(response => {
        if (response.status >= 400) {
          throw new Error('Interests could not be loaded.');
        }
        return response.json();
      })
      .then(data => {
        dispatch(receiveInterests(data));
      })
      .catch(error => dispatch(failureInterests(error)));
  };
};

export const selectLoading = state => state.interests.loading;
export const selectInterests = state => state.interests.data;

export default function resources(state = initialState, action) {
  switch (action.type) {
    case INTERESTS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case INTERESTS_RECEIVE: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    case INTERESTS_FAILURE: {
      return {
        ...state,
        loading: false,
        data: []
      };
    }
    default:
      return state;
  }
}
