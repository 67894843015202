import { apiRequest } from '../../api/api';

const initialState = {
  loading: false,
  bookmarks: [],
  toggleWorking: false
};

export const BOOKMARKS_REQUEST = 'bookmarks/REQUEST';
const requestBookmarks = () => ({
  type: BOOKMARKS_REQUEST
});

export const BOOKMARKS_RECEIVE = 'bookmarks/RECEIVE';
const receiveBookmarks = data => ({
  type: BOOKMARKS_RECEIVE,
  payload: data
});

export const BOOKMARKS_FAILURE = 'bookmarks/FAILURE';
const failureBookmarks = message => ({
  type: BOOKMARKS_FAILURE,
  payload: {
    message
  }
});

export const BOOKMARKS_WORKING = 'bookmarks/WORKING';
const workingBookmarks = status => ({
  type: BOOKMARKS_WORKING,
  payload: {
    status
  }
});

export const fetchBookmarks = token => {
  return dispatch => {
    dispatch(requestBookmarks());
    return apiRequest(token, '/bookmarks/full')
      .then(response => {
        if (response.status >= 400) {
          throw new Error('Bookmarked offers could not be loaded.');
        }
        return response.json();
      })
      .then(data => {
        dispatch(receiveBookmarks(data));
      })
      .catch(error => dispatch(failureBookmarks(error)));
  };
};

export const toggleBookmark = (id, setBookmark, token) => {
  return dispatch => {
    dispatch(workingBookmarks(true));
    return apiRequest(token, `/bookmarks/${id}`, setBookmark ? 'POST' : 'DELETE')
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          // Operation successful
          dispatch(fetchBookmarks(token));
        } else {
          dispatch(workingBookmarks(false));
        }
      })
      .catch(() => dispatch(workingBookmarks(false)));
  };
};

export const selectLoading = state => state.bookmarks.loading;
export const selectWorking = state => state.bookmarks.toggleWorking;
export const selectBookmarks = state => state.bookmarks.bookmarks;
export const selectBookmarksCount = state => selectBookmarks(state).length;
export const selectOfferIsBookmarked = id => state =>
  !!state.bookmarks.bookmarks.find(bookmark => bookmark.offer.id === id);

export default function bookmarks(state = initialState, action) {
  switch (action.type) {
    case BOOKMARKS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case BOOKMARKS_WORKING: {
      return {
        ...state,
        toggleWorking: true
      };
    }
    case BOOKMARKS_RECEIVE: {
      return {
        ...state,
        loading: false,
        toggleWorking: false,
        bookmarks: action.payload
      };
    }
    case BOOKMARKS_FAILURE: {
      return {
        ...state,
        loading: false,
        bookmarks: []
      };
    }
    default:
      return state;
  }
}
