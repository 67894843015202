import React from 'react';
import PropTypes from 'prop-types';
import { Tick } from '../../../images/icons';
import Colors from '../../../definitions/colors';
import './FullwidthCheckbox.scss';
import CircleIcon from '../CircleIcon';

const FullwidthCheckbox = ({ disabled, label, onUpdateValue, value }) => {
  return (
    <div
      className={`FullwidthCheckbox
      ${value ? 'FullwidthCheckbox--Ticked' : ''}
      ${disabled ? 'FullwidthCheckbox--Disabled' : ''}
      `}
      onClick={() => onUpdateValue(!value)}
    >
      <div className='FullwidthCheckbox__Label'>{label}</div>
      <div className='FullwidthCheckbox__Indicator'>
        <div className='FullwidthCheckbox__Tick'>
          <CircleIcon
            offsetX={1}
            offsetY={1}
            icon={<Tick fill={Colors.White} size={16} />}
            size={28}
          />
        </div>
      </div>
    </div>
  );
};

FullwidthCheckbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onUpdateValue: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired
};

export default FullwidthCheckbox;
