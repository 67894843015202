import { useDispatch, useSelector } from 'react-redux';
import { selectTourAt, tourAbort, tourNext, TourState } from '../../../redux/reducers/tour';
import React, { Fragment, useCallback } from 'react';
import { useHistoryListener } from '../../../hooks/useHistoryListener';
import { Blinds } from '../../atoms/Blinds';
import { Flyout } from '../../layout/Flyout';
import { TourCallout } from './TourCallout';
import { Scroller } from '../../atoms/Scroller';
import { matchPath } from 'react-router-dom';
import Routes from '../../../definitions/routes';
import PropTypes from 'prop-types';

const BookmarkingMessages = Object.freeze({
  alreadyBookmarked:
    'Wenn Du einen Vorteil interessant findest, aber jetzt noch nicht einlösen möchtest, kannst Du ihn für später auf die Merkliste setzen. Diesen Vorteil hast Du zum Beispiel bereits vorgemerkt.',
  normal:
    'Wenn Du einen Vorteil interessant findest, aber jetzt noch nicht einlösen möchtest, kannst Du ihn für später auf die Merkliste setzen. Probiere es mal aus!'
});

export function TourBookmarking({ alreadyBookmarked }) {
  const at = useSelector(selectTourAt);
  const skip = at === TourState.viewed || at === TourState.aborted;
  const active = at === TourState.aboutBookmarking;
  const dispatch = useDispatch();
  const handleAbort = useCallback(() => dispatch(tourAbort()), [dispatch]);
  const handleNext = useCallback(
    () => dispatch(tourNext({ alreadyBookmarked })),
    [dispatch, alreadyBookmarked]
  );
  const message = alreadyBookmarked
    ? BookmarkingMessages.alreadyBookmarked
    : BookmarkingMessages.normal;
  useHistoryListener(
    location => {
      if (active && !matchPath(location.pathname, Routes.Bookmarks()))
        dispatch(tourAbort('navigated'));
    },
    [active, dispatch]
  );
  if (skip) return null;
  return (
    <Fragment>
      <Blinds
        active={active}
        insetLeft={30}
        insetRight={30}
        insetTop={15}
        insetBottom={15}
        radius={0}
      />
      <Flyout active={active}>
        <TourCallout
          button={alreadyBookmarked ? 'Weiter' : undefined}
          onNext={alreadyBookmarked ? handleNext : undefined}
          stepAt={8}
          stepTotal={12}
          distance={30}
          onClose={handleAbort}
        >
          <Scroller active={active} />
          {message}
        </TourCallout>
      </Flyout>
    </Fragment>
  );
}

TourBookmarking.propTypes = {
  alreadyBookmarked: PropTypes.bool
};
