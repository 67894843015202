import React from 'react';
import useResources from '../../hooks/useResources';
import AccordionItem from '../atoms/AccordionItem';
import Container from '../layout/Container';
import WysiwygStyling from '../layout/WysiwygStyling';
import './FAQs.scss';

const FAQs = () => {
  const resource = useResources();

  const faqItems = [];
  for (let i = 1; i <= 8; i++) {
    faqItems.push(
      <AccordionItem key={i} question={resource(`faq.${i}.question`)}>
        <WysiwygStyling html={resource(`faq.${i}.answer`)} />
      </AccordionItem>
    );
  }
  return (
    <div className='FAQs'>
      <Container>
        <h1 className='FAQs__Title'>{resource('faq.headline')}</h1>
        <div className='FAQs__Entries'>
          {faqItems}
          <div className='FAQs__Outro'>
            <WysiwygStyling html={resource('faq.notAnswered')} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FAQs;
