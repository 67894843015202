import React, { useEffect, useState } from 'react';
import Button from '../atoms/Button';
import './ExternalLinkWarning.scss';
import Overlay from '../atoms/Overlay';
import useResources from '../../hooks/useResources';

const ExternalLinkWarning = () => {
  const resource = useResources();
  const [url, setUrl] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleGlobalClick = event => {
    const path = event.composedPath() || event.path;
    if (!path) return;

    const linkElement = path.find(
      element => element.href && element.href.startsWith('http') && !element.href.includes('evm.de')
    );
    const offerBodyWrapper = path.find(element => element.classList?.contains('OfferBody'));
    if (linkElement && offerBodyWrapper) {
      event.stopPropagation();
      event.preventDefault();
      setUrl(linkElement.href);
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleGlobalClick);
    return () => window.removeEventListener('click', handleGlobalClick);
  }, []);

  return (
    <Overlay
      fixed={true}
      visible={visible}
      onClose={() => setVisible(false)}
      showCloseButton
      containerStyle={{ pointerEvents: 'all' }}
    >
      <h3 className='Modal__Title'>{resource('externalLink.title')}</h3>
      <p className='Modal__Message'>{resource('externalLink.text')}</p>
      <div className='Modal__Button'>
        <Button
          title={resource('externalLink.button')}
          target='_blank'
          href={url}
          onClick={() => setVisible(false)}
        />
      </div>
    </Overlay>
  );
};

export default ExternalLinkWarning;
